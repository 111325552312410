import * as React from "react";
import * as XLSX from 'xlsx';

import {
  BuildingStatistic,
  BuildingStatisticBuilding,
} from "../../api/generated";
import {
  StatisticByBuildingForm,
  StatisticByBuildingsSidebarItem,
} from "./index";
import {useAppDispatch, useAppSelector} from '../../redux/hooks';
import {Loader} from '../../components/loader/Loader';
import {getWorkstationStatistics} from '../../redux/actions/statisticsActions';

interface StatisticByBuildingsSidebarProps {
  statistic: BuildingStatistic[];
}
interface City {
  item: BuildingStatistic;
  toggle: boolean;
}

export const StatisticByBuildingsSidebar: React.FC<
  StatisticByBuildingsSidebarProps
> = ({ statistic }) => {
  const dispatch = useAppDispatch();

  const [cities, setCities] = React.useState<City[]>([]);
  const [form, setForm] = React.useState<BuildingStatisticBuilding | null>(
    null
  );
  const { workstationStatistics, isLoading } = useAppSelector((state) => state.workstationStatistics);

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  const handleToogle = (city: number) => {
    setCities(
      cities?.filter((item) => {
        if (item.item.id === city) {
          item.toggle = !item.toggle;
        }

        return item;
      })
    );
  };

  const handleForm = (item: BuildingStatisticBuilding) => {
    setForm(item);
  };

  const handleFormView = () => {
    if (form !== null) {
      return <StatisticByBuildingForm form={form} />;
    }

    return null;
  };

  React.useEffect(() => {
    statistic.forEach((item) => {
      setCities((prev) => [...prev!, { item: item, toggle: false }]);
    });

    return () => {
      setCities([]);
    };
  }, [statistic]);

  React.useEffect(() => {
    dispatch(getWorkstationStatistics());
  }, [dispatch]);

  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(workstationStatistics);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Workstations");
    XLSX.writeFile(workbook, "workspace-export.xlsx");
  }

  return (
    <>
      <aside className="stats-sidebar">
        <div className="stats-sidebar__list">
          {cities?.map((item) => (
            <StatisticByBuildingsSidebarItem
              item={item}
              key={item.item.id}
              handleToggle={handleToogle}
              handleForm={handleForm}
            />
          ))}
          <div className="stats-sidebar__item">
            <button
                onClick={downloadExcel}
                type="button"
                className="btn btn--orange"
                style={{marginTop: '20px'}}
            >
              Export
            </button>
          </div>
        </div>
      </aside>

      {handleFormView()}
    </>
  );
};
