export const Employee = () => {
  return (
    <svg
      className="dashboard__image"
      width="60"
      height="60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)" fill="#F05A28">
        <path d="M42.924 39.684v-12c0-4.056-3.3-7.356-7.356-7.356h-11.16c-4.056 0-7.356 3.3-7.356 7.356v12c-.084 2.244 1.656 4.164 3.9 4.284l.636.036V55.2a4.768 4.768 0 0 0 4.764 4.764h7.26a4.738 4.738 0 0 0 3.372-1.392c.9-.9 1.392-2.1 1.392-3.372V44.004l.636-.036c2.256-.132 4.008-2.052 3.912-4.284Zm-3.18-12v13.092h-4.536v14.328c0 .876-.708 1.584-1.584 1.584h-7.26a1.582 1.582 0 0 1-1.584-1.584V40.776h-4.536V27.684a4.069 4.069 0 0 1 1.164-2.916A4.074 4.074 0 0 1 24.3 23.52h11.316c2.244 0 4.08 1.812 4.116 4.068a.298.298 0 0 1 .012.096ZM30 16.764c4.632 0 8.388-3.768 8.388-8.388S34.632-.024 30-.024s-8.388 3.768-8.388 8.388 3.756 8.4 8.388 8.4Zm0-13.608a5.219 5.219 0 0 1 5.208 5.208A5.219 5.219 0 0 1 30 13.572a5.219 5.219 0 0 1-5.208-5.208A5.219 5.219 0 0 1 30 3.156Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h60v60H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
