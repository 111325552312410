import * as React from "react";

import { Navigate, useLocation } from "react-router-dom";
import moment from "moment";

import { useAppSelector } from "../../redux/hooks";

interface IAuthenticatedRouteProps {
  children: JSX.Element;
}

export const AuthenticatedRoute: React.FC<IAuthenticatedRouteProps> = ({
  children,
}) => {
  const location = useLocation();
  const { isAuthenticated, exp } = useAppSelector((state) => state.auth);
  if (!isAuthenticated || moment.unix(exp) < moment(new Date())) {
    localStorage.clear();
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return children;
};
