import * as React from "react";

import { AccessControlCardTable } from "./index";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { Loader } from "../../components/loader/Loader";
import { EmptyListPlaceholder } from "components/emptyListPlaceholder";
import { ManageWrapper } from "layouts/manageLayout";
import { getCards } from "../../redux/actions/accessControlCardActions";

export const AccessControlCard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { cards, allCardsLoadingState } = useAppSelector((state) => state.accessControlCard);
  const isLoading = allCardsLoadingState === "NotLoaded" || allCardsLoadingState === "Loading";

  const handleView = () => {
    if (cards.length !== 0) {
      return <AccessControlCardTable cards={cards} />;
    }

    return <EmptyListPlaceholder />;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    if (allCardsLoadingState === "NotLoaded") {
      dispatch(getCards());
    }
  }, [dispatch, allCardsLoadingState]);

  return (
    <ManageWrapper buttonText="Add new token" manageText="a token" navigateTo="/token/new" isButtonVisible>
      {handleLoader()}
      {handleView()}
    </ManageWrapper>
  );
};
