import * as React from "react";

import { ManageWrapper } from "layouts/manageLayout";
import { EmptyListPlaceholder } from "components/emptyListPlaceholder";
import { getCities } from "../../redux/actions/cityActions";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { CityTable } from "../city/index";
import { Loader } from "../../components/loader/Loader";

export const City: React.FC = () => {
  const { cities, isLoading } = useAppSelector((state) => state.city);
  const dispatch = useAppDispatch();

  const handleView = () => {
    if (cities.length !== 0) {
      return <CityTable cities={cities} />;
    }

    return <EmptyListPlaceholder />;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  return (
    <ManageWrapper
      buttonText="Add a new city"
      manageText="a city"
      navigateTo="/city/new"
      isButtonVisible
    >
      {handleLoader()}
      {handleView()}
    </ManageWrapper>
  );
};
