import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  BuildingRequest,
  BuildingUpdateRequest,
  BuildingWithFloorInfoResponse,
  FloorUpdateInBuildingRequest,
} from "../../api/generated/index";
import { Api } from "../../api/index";
import { PostBuildingType, PutBuildingType } from "../../types/BuildingTypes";

export const getBuildings = createAsyncThunk(
  "getBuildings",
  async (): Promise<BuildingWithFloorInfoResponse[]> => {
    return Api.Building.getAllBuildings().then(({ data }) => {
      return data;
    });
  }
);

export const getBuilding = createAsyncThunk(
  "getBuilding",
  async (id: number): Promise<BuildingWithFloorInfoResponse> => {
    return Api.Building.getBuildingById(id).then(({ data }) => {
      return data;
    });
  }
);

export const postBuilding = createAsyncThunk(
  "postBuilding",
  async (
    postBuildingType: PostBuildingType
  ): Promise<BuildingWithFloorInfoResponse> => {
    const buildingRequest: BuildingRequest = {
      name: postBuildingType.name,
      address: postBuildingType.address,
      cityId: postBuildingType.cityId,
      latitude: postBuildingType.latitude,
      longitude: postBuildingType.longitude,
    };
    return Api.Building.saveBuilding(buildingRequest).then(({ data }) => {
      return data;
    });
  }
);

export const deleteBuilding = createAsyncThunk(
  "deleteBuilding",
  async (id: number): Promise<BuildingWithFloorInfoResponse> => {
    return Api.Building.deleteBuilding(id).then(({ data }) => {
      return data;
    });
  }
);

export const putBuilding = createAsyncThunk(
  "putBuilding",
  async (
    putBuildingType: PutBuildingType
  ): Promise<BuildingWithFloorInfoResponse> => {
    const buildingRequest: BuildingUpdateRequest = {
      name: putBuildingType.name,
      cityId: putBuildingType.cityId,
      address: putBuildingType.address,
      latitude: putBuildingType.latitude,
      longitude: putBuildingType.longitude,
      floorList: putBuildingType.floorList,
    };

    return Api.Building.updateBuilding(
      putBuildingType.buildingId,
      buildingRequest
    ).then(({ data }) => {
      return data;
    });
  }
);

export const stopEditing = createAsyncThunk("stopEditing", async () => {});

export const updateBuildingFloor = createAsyncThunk(
  "updateBuildingFloor",
  async (floor: FloorUpdateInBuildingRequest) => {
    return floor;
  }
);

export const deleteBuildingFloor = createAsyncThunk(
  "deleteBuildingFloor",
  async (id: number) => {
    return id;
  }
);
