import * as React from "react";

import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Modal } from "../../components/modal/Modal";
import { AdminResponse } from "../../api/generated/index";
import { getAdmins, deleteAdmin } from "../../redux/actions/adminActions";
import { useAppDispatch } from "../../redux/hooks";
import { handleErrors, handleToast } from "../../utils/helpers";
import { SUCCESS_DELETE } from "../../constants/messageConstants";

interface AdminTableProps {
  admins: AdminResponse[];
}

export const AdminTable: React.FC<AdminTableProps> = ({ admins }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentItem = React.useRef<AdminResponse | null>(null);
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);

  const handleOpen = (item: AdminResponse) => {
    setShowDeletePopup(true);
    currentItem.current = item;
  };
  const handleClose = () => {
    setShowDeletePopup(false);
  };

  const onDelete = (id: number) => {
    dispatch(deleteAdmin(id))
      .unwrap()
      .then(() => {
        dispatch(getAdmins());
        handleToast(SUCCESS_DELETE);
      })
      .catch((res) => {
        handleErrors(res);
      });
    handleClose();
  };

  const onCancel = () => {
    handleClose();
  };

  const onEdit = (id: number) => {
    navigate(`/employee/new/${id}`);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={true} />
      <h2 className="manager__title">List of added admins</h2>
      <div className="manager__content">
        <table className="table">
          <thead>
            <tr className="table__row">
              <th className="table__head">Name</th>
              <th className="table__head">Role</th>
              <th className="table__head">Email</th>
              <th className="table__head table__head--action">Edit</th>
            </tr>
          </thead>
          <tbody>
            {admins.map((item) => {
              return (
                <tr className="table__row" key={item.id}>
                  <td className="table__cell">{item.username}</td>
                  <td className="table__cell">{item.role}</td>
                  <td className="table__cell">{item.email}</td>
                  <td className="table__cell">
                    <button
                      onClick={() => onEdit(item.id!)}
                      className="btn btn--green"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal show={showDeletePopup} handleClose={onCancel}>
        <h2 className="popup__heading-plain">
          Are you sure you want to delete this admin?
        </h2>
        <div className="popup__actions">
          <button
            onClick={() => onDelete(currentItem.current?.id!)}
            type="button"
            className="btn btn--green popup__btn"
          >
            Delete
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="btn btn--red popup__btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
