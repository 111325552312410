import * as React from "react";

import {
  Controller,
  SubmitHandler,
  useForm,
  ControllerRenderProps,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { TextInput } from "ui-components";
import { ManageWrapper } from "layouts/manageLayout";
import { MainWrapper } from "layouts/mainWrapper/MainWrapper";
import { Dropdown } from "ui-components";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getRoom, postRoom, putRoom } from "../../redux/actions/roomActions";
import { getFloors } from "../../redux/actions/floorActions";
import { SUCCESS_POST, SUCCESS_PUT } from "../../constants/messageConstants";
import { handleErrors, handleToast } from "../../utils/helpers";
import { getFloorId } from "../../utils/helpers";

type Inputs = {
  name: string;
  floor: string;
  type: string;
};

export const NewRoom: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { floors } = useAppSelector((state) => state.floor);
  const { cities } = useAppSelector((state) => state.city);
  const options: string[] = floors.map(
    (item) => item.nameForAdmins
  ) as string[];
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (id !== undefined) {
      dispatch(
        putRoom({
          roomId: Number(id),
          name: data.name,
          floorId: getFloorId(data.floor, floors),
          type: data.type,
        })
      )
        .unwrap()
        .then(() => {
          handleToast(SUCCESS_PUT);
          navigate(-1);
        })
        .catch((res) => {
          handleErrors(res);
        });
      return;
    }
    dispatch(
      postRoom({
        name: data.name,
        floorId: getFloorId(data.floor, floors),
        type: data.type,
      })
    )
      .unwrap()
      .then(() => {
        handleToast(SUCCESS_POST);
        navigate(-1);
      })
      .catch((res) => {
        handleErrors(res);
      });
  };

  const onCancel = () => {
    navigate(-1);
  };

  const errorName =
    errors.name?.type === "required" ? "Name name is required" : null;

  const errorFloor =
    errors.floor?.type === "required" ? "Floor is required" : null;

  const errorType =
    errors.floor?.type === "required" ? "Floor is required" : null;

  const rulesName = {
    required: true,
  };

  const rulesFloor = {
    required: true,
  };
  const rulesType = {
    required: true,
  };

  const renderName = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "name">;
  }) => {
    return (
      <TextInput
        error={errorName}
        label="Room name"
        placeholder="Enter a room name..."
        {...field}
      />
    );
  };

  const renderFloors = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "floor">;
  }) => {
    return (
      <Dropdown
        error={errorFloor!}
        onChangeSelection={field.onChange}
        label="Select a floor"
        placeholder={field.value !== "" ? field.value : "Select a floor..."}
        options={options}
        disabled
        {...field}
      />
    );
  };

  const renderType = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "type">;
  }) => {
    if (id !== undefined) {
      return (
        <TextInput
          error={errorType}
          label="Room type"
          placeholder="Enter a room type..."
          disabled
          {...field}
        />
      );
    }
    return (
      <Dropdown
        error={errorType!}
        onChangeSelection={field.onChange}
        label="Select a type"
        placeholder={field.value !== "" ? field.value : "Select a type..."}
        options={["OfficeRoom", "ConferenceRoom"]}
        {...field}
      />
    );
  };

  React.useEffect(() => {
    dispatch(getFloors());

    if (id !== undefined) {
      dispatch(getRoom(Number(id)))
        .unwrap()
        .then((res) => {
          setValue("name", res?.name!);
          setValue("floor", res?.floor?.nameForAdmins!);
          setValue("type", res?.type!);
        })
        .catch((res) => {
          handleErrors(res);
        });
    }
  }, [cities, dispatch, id, setValue]);

  return (
    <ManageWrapper
      buttonText="Add a new room"
      manageText="a room"
      navigateTo=""
      isButtonVisible={false}
    >
      <MainWrapper title="Add a new room">
        <Toaster position="top-center" reverseOrder={true} />
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="form__row ">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={rulesName}
              render={renderName}
            />
            <Controller
              name="floor"
              control={control}
              defaultValue=""
              rules={rulesFloor}
              render={renderFloors}
            />
            <Controller
              name="type"
              control={control}
              defaultValue=""
              rules={rulesType}
              render={renderType}
            />
          </div>
          <div className="form__actions">
            <button type="submit" className="form__btn btn btn--green">
              Save
            </button>
            <button
              onClick={onCancel}
              type="button"
              className="form__btn btn btn--red"
            >
              Cancel
            </button>
          </div>
        </form>
      </MainWrapper>
    </ManageWrapper>
  );
};
