import * as React from "react";

import * as d3 from "d3";
import { v4 as uuidv4 } from "uuid";
import { saveAs } from "file-saver";

import { ReactComponent as Close } from "images/ico-close.svg";
import { Modal } from "../../components/modal/Modal";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getEmployees } from "../../redux/actions/employeeActions";
import { getRoom } from "../../redux/actions/roomActions";
import { handleToast } from "../../utils/helpers";
import { EmployeeResponse, WorkstationRequest } from "../../api/generated";
import InfoIcon from "../../images/icon-info.svg";
import { Dropdown, RadioButtons } from "ui-components";
import { getCompanies } from "redux/actions/companyActions";
import laptopIcon from "../../images/content/ico-laptop.svg";
import pcIcon from "../../images/content/ico-pc.svg";
import monitorIcon from "../../images/content/ico-monitor.svg";
import dualMonitorIcon from "../../images/content/ico-dual-monitors.svg";
import { Autocomplete, Box, styled, TextField } from "@mui/material";
// kld-intersections does not have any types defined
const { ShapeInfo, Intersection, Point2D, Matrix2D } = require("kld-intersections");

interface Props {
  hasData: boolean;
  floorChanged: boolean;
  isEditable: boolean;
  floorId?: number;
}

enum SizeChangeEnum {
  INCREASE,
  DECREASE,
}
enum PositionChangeEnum {
  X,
  Y,
}

type ReservationType = "Intern" | "Reserved" | undefined;

type ReservedType = "Employee" | "Other";

export const createSvgFile = (name: string): File => {
  d3.select("svg g").attr("transform", "scale(1)");
  const svg_data = document.getElementById("Layer_1")?.innerHTML;

  const head =
    '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 600" style="enable-background:new 0 0 750 600;" xml:space="preserve">';

  const full_svg = head + svg_data + "</svg>";
  const blob = new Blob([full_svg], { type: "image/svg+xml" });
  return new File([blob], name);
};

export const injectSVGInEditor = async (file: File): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (event) {
      // @ts-ignore
      const fileData: string = event?.target?.result;

      d3.xml(fileData).then((data) => {
        // @ts-ignore
        d3.select("#svg_container").html("");
        // @ts-ignore
        d3.select("#svg_container").node()?.append(data.documentElement);
        resolve(true);
      });
    };
    reader.onerror = function () {
      reject(false);
    };
  });
};

const checkIfExist = (workstations: WorkstationRequest[], id: string) => {
  let retVal = false;
  for (let item of workstations) {
    if (item.id === id) {
      retVal = true;
      break;
    }
  }
  return retVal;
};

export const getWorkstations = (): WorkstationRequest[] => {
  const retList: WorkstationRequest[] = [];
  const offices = d3.selectAll("#tables g");

  offices.selectAll("g polygon").each(function () {
    if (
      d3.select(this).attr("id").split("_")[0] === "path" &&
      !checkIfExist(retList, d3.select(this).attr("id").split("_")[1])
    ) {
      const userId = `#${d3.select(this).attr("id").replace("path", "user")}`;
      const workstationId = d3.select(this).attr("id").split("_")[1];
      const roomId = Number(d3.select(this).attr("id").split("_")[3]);
      let username = "";
      if (!d3.select(userId).empty()) {
        username = d3.select(userId).attr("username");
      }
      retList.push({
        id: workstationId,
        roomId: roomId,
        username: username,
      });
    }
  });
  return retList;
};

export const SVGEditor: React.FC<Props> = ({ hasData, floorChanged, isEditable }) => {
  const { employees } = useAppSelector((state) => state.employee);
  const dispatch = useAppDispatch();
  const TABLE_WIDTH = 35;
  const TABLE_HEIGHT = 20;
  const CHAIR_WIDTH = 2;
  const CHAIR_HEIGHT = 4;
  const CHAIR_ANGLE = 5;
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [showModalForName, setShowModalForName] = React.useState<boolean>(false);
  const [showModalForConfName, setShowModalForConfName] = React.useState<boolean>(false);
  const [showModalForCompany, setShowModalForCompany] = React.useState<ReservationType>();
  const [reservingType, setReservingType] = React.useState<ReservedType>("Employee");
  let clickedTableId = React.useRef("");
  let currentAngle = React.useRef<number>(0);
  let startingAngleForTable = React.useRef<number>(0);
  const [angle, setAngle] = React.useState<number>(0);
  const [search, setSearch] = React.useState<string>("");
  const [officeName, setOfficeName] = React.useState<string>("");
  const [conf, setConf] = React.useState<{
    name: string;
    x: number;
    y: number;
  }>({ name: "", x: 0, y: 0 });
  const [searchList, setSearchList] = React.useState<EmployeeResponse[]>([]);
  const [companyList, setCompanyList] = React.useState<string[]>([]);
  const [selectedCompany, setSelectedCompany] = React.useState<string>("");
  const [selectedEmployee, setSelectedEmployee] = React.useState<EmployeeResponse | undefined>();
  const [additionalInfo, setAdditionalInfo] = React.useState<string>("");
  const startingPosition = React.useRef<
    {
      x: number;
      y: number;
    }[]
  >([]);
  const startingChairPosition = React.useRef<
    {
      x: number;
      y: number;
    }[]
  >([]);
  const startingUserPosition = React.useRef<{
    x: number;
    y: number;
    angle: number;
    angleTable: number;
  }>({ x: 0, y: 0, angle: 0, angleTable: 0 });
  const startingCompanyPosition = React.useRef<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const [dummyState, setDummyState] = React.useState(false);

  let rotateHandleStartPos: { angle: any; x: any; y: any; iniAngle: any },
    r = { angle: 0, cx: 0, cy: 0 };

  const handleZoom = (e: any) => {
    d3.select("svg g").attr("transform", e.transform);
  };

  const calcAngleDeg = (
    p1: { angle?: number; cx: any; cy: any; x?: any; y?: any },
    p2: { angle?: any; x: any; y: any; iniAngle?: any },
  ) => {
    var p1x = p1.x ? p1.x : p1.cx;
    var p1y = p1.y ? p1.y : p1.cy;
    return (Math.atan2(p2.y - p1y, p2.x - p1x) * 180) / Math.PI;
  };

  const dragPointRotate = (
    rotateHandleStartPos: {
      angle: any;
      x: any;
      y: any;
      iniAngle: any;
    },
    event: any,
    elementId: string,
    officeId: string,
  ) => {
    rotateHandleStartPos.x = event.x;
    rotateHandleStartPos.y = event.y;

    const updatedRotateCoordinates = r;
    // calculates the difference between the current mouse position and the center line
    var angleFinal = calcAngleDeg(updatedRotateCoordinates, rotateHandleStartPos);

    // gets the difference of the angles to get to the final angle
    var angle = rotateHandleStartPos.angle + angleFinal - rotateHandleStartPos.iniAngle;

    // converts the values to stay inside the 360 positive
    angle %= 360;
    if (angle < 0) {
      angle += 360;
    }

    // creates the new rotate position array
    var rotatePos = [angle, updatedRotateCoordinates.cx, updatedRotateCoordinates.cy];

    r.angle = angle;
    d3.select(`#path_${elementId}_office_${officeId}`).attr("transform", `rotate(${rotatePos})`);
    d3.select(`#chair_${elementId}_office_${officeId}`).attr("transform", `rotate(${rotatePos})`);

    let newAngle = (angle + currentAngle.current) % 360;

    d3.select(`#path_${elementId}_office_${officeId}`).attr("angle", (startingAngleForTable.current + angle) % 360);
    if (
      !d3.select(`#user_${elementId}_office_${officeId}`).empty() &&
      !d3.select(`#company_${elementId}_office_${officeId}`).empty()
    ) {
      if (newAngle >= 90 && newAngle <= 180) {
        newAngle += 180;
      } else if (newAngle >= 180 && newAngle <= 270) {
        newAngle -= 180;
      }

      d3.select(`#user_${elementId}_office_${officeId}`).attr("angle", newAngle);

      d3.select(`#user_${elementId}_office_${officeId}`).attr(
        "transform",
        `rotate(${[newAngle, updatedRotateCoordinates.cx, updatedRotateCoordinates.cy]})`,
      );

      d3.select(`#company_${elementId}_office_${officeId}`).attr(
        "transform",
        `rotate(${[newAngle, updatedRotateCoordinates.cx, updatedRotateCoordinates.cy]})`,
      );
    }
    d3.select(`#rotate_tool_${elementId}_office_${officeId}`).attr("transform", `rotate(${rotatePos})`);
    d3.select(`#resize_tool_${elementId}_office_${officeId}`).attr("transform", `rotate(${rotatePos})`);
  };

  const getElementCenter = (elementId: string) => {
    // @ts-ignore
    const box = document.querySelector(`#${elementId}`).getBBox();

    return {
      x: box.x + box.width / 2,
      y: box.y + box.height / 2,
    };
  };

  const getHandleRotatePosition = (
    handleStartPos: {
      angle: any;
      x: any;
      y: any;
      iniAngle?: any;
      cx?: any;
      cy?: any;
    },
    id: string,
  ) => {
    // its possible to use "cx/cy" for properties
    var originalX = handleStartPos.x ? handleStartPos.x : handleStartPos.cx;
    var originalY = handleStartPos.y ? handleStartPos.y : handleStartPos.cy;

    // gets the updated element center, without rotatio
    var center = getElementCenter(id);

    // calculates the rotated handle position considering the current center as
    // pivot for rotation
    var dx = originalX - center.x;
    var dy = originalY - center.y;
    var theta = (handleStartPos.angle * Math.PI) / 180;

    return {
      x: dx * Math.cos(theta) - dy * Math.sin(theta) + center.x,
      y: dx * Math.sin(theta) + dy * Math.cos(theta) + center.y,
    };
  };

  const getIdsFromCircle = (circle: Element, regex: RegExp) => {
    const idElements = d3.select(circle).attr("id").split(regex);

    const uniqId = idElements[1];
    const officeId = idElements[2];
    return {
      uniqId,
      officeId,
    };
  };

  const startRotation = (id: string) => {
    const charId = id.replace("path", "chair");
    const userId = id.replace("path", "user");
    const companyId = id.replace("path", "company");
    const svgId = id.replace("path", "svg");
    r.cx = getElementCenter(`${id}`).x;
    r.cy = getElementCenter(`${id}`).y;
    let updatedRotateCoordinates = r;

    //close context menu if open and select the rotating table
    closeMenu();
    clickedTableId.current = id;

    const polyPoints = getPolyPoints(`#${id}`);
    const polyChairPoints = getChairPoints(`#${charId}`);

    startingPosition.current = polyPoints;
    startingChairPosition.current = polyChairPoints;
    startingUserPosition.current.angleTable = Number(d3.select(`#${id}`).attr("angle"));
    startingAngleForTable.current = Number(d3.select(`#${id}`).attr("angle"));
    if (!d3.select(`#${userId}`).empty() && !d3.select(`#${companyId}`).empty()) {
      startingUserPosition.current.x = Number(d3.select(`#${userId}`).attr("x"));
      startingUserPosition.current.y = Number(d3.select(`#${userId}`).attr("y"));
      startingUserPosition.current.angle = Number(d3.select(`#${userId}`).attr("angle"));

      startingCompanyPosition.current.x = Number(d3.select(`#${companyId}`).attr("x"));
      startingCompanyPosition.current.y = Number(d3.select(`#${companyId}`).attr("y"));
    }
    if (!d3.select(`#${svgId}`).empty()) {
      d3.select(`#${svgId}`).style("visibility", "hidden");
    }

    // updates the rotate handle start posistion object with
    // basic information from the model and the handles
    // @ts-ignore
    rotateHandleStartPos = {
      angle: r.angle, // the current angle
      x: polyPoints[0].x, // the current cx value of the target handle
      y: polyPoints[0].y, // the current cy value of the target handle
    };

    // calc the rotated top & left corner
    if (rotateHandleStartPos.angle > 0) {
      var correctsRotateHandleStartPos = getHandleRotatePosition(rotateHandleStartPos, id);
      rotateHandleStartPos.x = correctsRotateHandleStartPos.x;
      rotateHandleStartPos.y = correctsRotateHandleStartPos.y;
    }

    // adds the initial angle in degrees
    rotateHandleStartPos.iniAngle = calcAngleDeg(updatedRotateCoordinates, rotateHandleStartPos);
  };

  const rotate = (event: any, elementId: string, officeId: string) => {
    dragPointRotate(rotateHandleStartPos, event, elementId, officeId);
  };

  const resetTablePosition = React.useCallback((table: Element, uniqId: string, officeId: string) => {
    const chairId = `#chair_${uniqId}_office_${officeId}`;
    const userId = `#user_${uniqId}_office_${officeId}`;
    const companyId = `#company_${uniqId}_office_${officeId}`;
    const svgId = `#svg_${uniqId}_office_${officeId}`;
    const resizeId = `#resize_tool_${uniqId}_office_${officeId}`;
    const rotateId = `#rotate_tool_${uniqId}_office_${officeId}`;

    d3.select(resizeId).attr("cx", startingPosition.current[2].x).attr("cy", startingPosition.current[2].y);

    if (!d3.select(svgId).empty()) {
      let svgAngle = startingUserPosition.current.angleTable;
      if (svgAngle > 180 && svgAngle <= 270) {
        svgAngle -= 180;
      } else if (svgAngle > 270 && svgAngle <= 360) {
        svgAngle -= 180;
      } else {
        svgAngle += 180;
      }

      d3.select(svgId).attr("x", startingPosition.current[2].x).attr("y", startingPosition.current[2].y);

      d3.select(svgId)
        .select("path")
        .attr("transform", `rotate(${[svgAngle]})`);
    }

    d3.select(rotateId).attr("cx", startingPosition.current[0].x).attr("cy", startingPosition.current[0].y);
    const startingPoints = getPointsString(startingPosition.current);
    const startingChairPoints = getPointsString(startingChairPosition.current);

    d3.select(table).attr("points", startingPoints);
    d3.select(table).attr("angle", startingUserPosition.current.angleTable);

    d3.select(chairId).attr("points", startingChairPoints);
    if (!d3.select(userId).empty()) {
      const center = getElementCenter(`path_${uniqId}_office_${officeId}`);
      const angle = startingUserPosition.current.angle;
      d3.select(userId).attr("x", startingUserPosition.current.x);
      d3.select(userId).attr("y", startingUserPosition.current.y);
      d3.select(userId).attr("transform", `rotate(${angle},${center.x},${center.y})`);
      d3.select(companyId).attr("x", startingCompanyPosition.current.x);
      d3.select(companyId).attr("y", startingCompanyPosition.current.y);
      d3.select(companyId).attr("transform", `rotate(${angle},${center.x},${center.y})`);
      d3.select(userId).attr("angle", angle);
    }
  }, []);

  const onDrag = (event: any, uniqId: string, officeId: string) => {
    const tableId = `#path_${uniqId}_office_${officeId}`;
    const chairId = `#chair_${uniqId}_office_${officeId}`;
    const userId = `#user_${uniqId}_office_${officeId}`;
    const companyId = `#company_${uniqId}_office_${officeId}`;
    const svgId = `#svg_${uniqId}_office_${officeId}`;

    const updatedPoints = getPolyPoints(tableId).map((point) => {
      const x = point.x + event.dx;
      const y = point.y + event.dy;
      return {
        x,
        y,
      };
    });

    const updatedChairPoints = getChairPoints(chairId).map((point) => {
      const x = point.x + event.dx;
      const y = point.y + event.dy;
      return {
        x,
        y,
      };
    });

    const pointsString = getPointsString(updatedPoints);
    const chairPointsString = getPointsString(updatedChairPoints);
    d3.select(tableId).attr("points", pointsString);
    d3.select(chairId).attr("points", chairPointsString);
    if (!d3.select(userId).empty() && !d3.select(companyId).empty()) {
      const x = Number(d3.select(userId).attr("x"));
      const y = Number(d3.select(userId).attr("y"));
      d3.select(userId).attr("x", x + event.dx);
      d3.select(userId).attr("y", y + event.dy);

      d3.select(companyId).attr("x", x + event.dx);
      d3.select(companyId).attr("y", y + event.dy);
      d3.select(userId).style("visibility", "hidden");
      d3.select(companyId).style("visibility", "hidden");
    }
    if (!d3.select(svgId).empty()) {
      const svgX = Number(d3.select(svgId).attr("x"));
      const svgY = Number(d3.select(svgId).attr("y"));
      d3.select(svgId).attr("x", svgX + event.dx);
      d3.select(svgId).attr("y", svgY + event.dy);
      d3.select(svgId).style("visibility", "hidden");
    }
    d3.select(`#resize_tool_${uniqId}_office_${officeId}`)
      .attr("cx", updatedPoints[2].x)
      .attr("cy", updatedPoints[2].y);
    d3.select(`#rotate_tool_${uniqId}_office_${officeId}`)
      .attr("cx", updatedPoints[0].x)
      .attr("cy", updatedPoints[0].y);
  };

  const checkIntersection = React.useCallback(
    (table: Element) => {
      const officeId = d3.select(table).attr("id").split("office_")[1];
      const chairId = d3.select(table).attr("id").replace("path", "chair");
      const tableId = d3.select(table).attr("id");
      const path = document!.getElementById(`${officeId}`)!.getAttribute("d");

      const officePath = ShapeInfo.path(path);

      const points = getPolyPoints(`#${tableId}`);
      const chairPoints = getChairPoints(`#${chairId}`);
      const poly = [
        new Point2D(points[0].x, points[0].y),
        new Point2D(points[1].x, points[1].y),
        new Point2D(points[2].x, points[2].y),
        new Point2D(points[3].x, points[3].y),
      ];
      const polyChair = [
        new Point2D(chairPoints[0].x, chairPoints[0].y),
        new Point2D(chairPoints[1].x, chairPoints[1].y),
        new Point2D(chairPoints[2].x, chairPoints[2].y),
        new Point2D(chairPoints[3].x, chairPoints[3].y),
        new Point2D(chairPoints[4].x, chairPoints[4].y),
        new Point2D(chairPoints[5].x, chairPoints[5].y),
      ];

      const tester = ShapeInfo.polygon(poly);

      const testerChair = ShapeInfo.polygon(polyChair);

      const resul2 = Intersection.intersect(officePath, tester);
      const resul1 = Intersection.intersect(officePath, testerChair);

      const idElements = d3
        .select(table)
        .attr("id")
        .split(/_office_|path_/);
      const uniqId = idElements[1];

      if (resul2.points.length > 0 || resul1.points.length > 0) {
        alert("Can't place it here.");
        resetTablePosition(table, uniqId, officeId);
      } else {
        const svgPoint = (document!.getElementById("Layer_1") as unknown as SVGSVGElement).createSVGPoint();
        svgPoint.x = points[0].x;
        svgPoint.y = points[0].y;
        if ((document.getElementById(officeId) as unknown as SVGGeometryElement).isPointInFill(svgPoint)) {
        } else {
          alert("Can't place it here.");
          resetTablePosition(table, uniqId, officeId);
        }
      }
    },
    [resetTablePosition],
  );

  const rotationEnd = (id: string) => {
    const polygon = document.getElementById(`path_${id}`);
    const points = getPolyPoints(`#path_${id}`);
    const chairPoints = getChairPoints(`#chair_${id}`);
    const poly = [
      new Point2D(points[0].x, points[0].y),
      new Point2D(points[1].x, points[1].y),
      new Point2D(points[2].x, points[2].y),
      new Point2D(points[3].x, points[3].y),
    ];

    const polyChair = [
      new Point2D(chairPoints[0].x, chairPoints[0].y),
      new Point2D(chairPoints[1].x, chairPoints[1].y),
      new Point2D(chairPoints[2].x, chairPoints[2].y),
      new Point2D(chairPoints[3].x, chairPoints[3].y),
      new Point2D(chairPoints[4].x, chairPoints[4].y),
      new Point2D(chairPoints[5].x, chairPoints[5].y),
    ];

    // find center point of rectangle
    const center = new Point2D(points[0].x, points[0].y).lerp(new Point2D(points[2].x, points[2].y), 0.5);

    const groupTransform = d3.select(`#path_${id}`).attr("transform");
    const angleExtracted = groupTransform.split(/rotate\(|,/)[1];

    // define rotation in radians
    const angle = (Number(angleExtracted) * Math.PI) / 180.0;

    // create matrix for rotating around center of rectangle
    const rotation = Matrix2D.rotationAt(angle, center);

    // create new rotated polygon
    const rotatedPoly = poly.map((p) => p.transform(rotation));
    const rotatedPolyChair = polyChair.map((p) => p.transform(rotation));

    const tester = ShapeInfo.polygon(rotatedPoly);
    const testerChair = ShapeInfo.polygon(rotatedPolyChair);

    const convertedPoints = getPointsString(tester.args[0]);
    const convertedChairPoints = getPointsString(testerChair.args[0]);
    d3.select(`#path_${id}`).attr("transform", null);
    d3.select(`#path_${id}`).attr("points", convertedPoints);
    d3.select(`#chair_${id}`).attr("transform", null);
    d3.select(`#chair_${id}`).attr("points", convertedChairPoints);

    d3.select(`#rotate_tool_${id}`).attr("cx", tester.args[0][0].x).attr("cy", tester.args[0][0].y);

    d3.select(`#resize_tool_${id}`).attr("cx", tester.args[0][2].x).attr("cy", tester.args[0][2].y);

    d3.select(`#resize_tool_${id}`).attr("transform", null);
    d3.select(`#rotate_tool_${id}`).attr("transform", null);

    d3.select(`#svg_${id}`).attr("x", tester.args[0][2].x).attr("y", tester.args[0][2].y);

    if (!d3.select(`#svg_${id}`).empty()) {
      let svgAngle = Number(d3.select(`#${clickedTableId.current}`).attr("angle"));
      if (svgAngle > 180 && svgAngle <= 270) {
        svgAngle -= 180;
      } else if (svgAngle > 270 && svgAngle <= 360) {
        svgAngle -= 180;
      } else {
        svgAngle += 180;
      }

      d3.select(`#svg_${id}`).select("path").attr("angle", svgAngle);

      d3.select(`#svg_${id}`)
        .select("path")
        .attr("transform", `rotate(${[svgAngle]})`);

      d3.select(`#svg_${id}`).style("visibility", "visible");
    }

    checkIntersection(polygon as Element);
  };

  const appendTable = (
    x: number,
    y: number,
    uniqId: string,
    officeId: string,
    group: d3.Selection<SVGGElement, unknown, HTMLElement, any>,
  ) => {
    const points = `${x},${y} ${x + TABLE_WIDTH},${y} ${x + TABLE_WIDTH},${y + TABLE_HEIGHT} ${x},${y + TABLE_HEIGHT}`;

    const chairPoints = `${x + TABLE_WIDTH / 2 - CHAIR_WIDTH},${y} ${x + TABLE_WIDTH / 2 - CHAIR_WIDTH + 1},${
      y - CHAIR_HEIGHT
    } ${x + TABLE_WIDTH / 2 - CHAIR_ANGLE},${y - 8} ${x + TABLE_WIDTH / 2 + CHAIR_ANGLE},${y - 8} ${
      x + TABLE_WIDTH / 2 + CHAIR_WIDTH - 1
    },${y - CHAIR_HEIGHT} ${x + TABLE_WIDTH / 2 + CHAIR_WIDTH},${y}`;

    group
      .append("polygon")
      .attr("id", `path_${uniqId}_office_${officeId}`)
      .attr("fill", "#dcdcc6")
      .attr("stroke-width", 0.5)
      .attr("stroke", "black")
      .attr("angle", 0)
      .attr("width", TABLE_WIDTH)
      .attr("heigth", TABLE_HEIGHT)
      .attr("points", points);

    group
      .append("polygon")
      .attr("id", `chair_${uniqId}_office_${officeId}`)
      .attr("fill", "#626258")
      .attr("stroke-width", 0.5)
      .attr("stroke", "black")
      .attr("points", chairPoints);

    group
      .append("circle")
      .attr("cx", x)
      .attr("cy", y)
      .attr("id", `rotate_tool_${uniqId}_office_${officeId}`)
      .attr("r", 3)
      .attr("opacity", 0)
      .attr("fill", "blue")
      .on("mouseover", (d: any) => {
        const id = d.srcElement.id;
        d3.select(`#${id}`).attr("opacity", 1);
      })
      .on("mouseout", (d: any) => {
        const id = d.srcElement.id;
        d3.select(`#${id}`).attr("opacity", 0);
      });

    group
      .append("circle")
      .attr("cx", x + TABLE_WIDTH)
      .attr("cy", y + TABLE_HEIGHT)
      .attr("id", `resize_tool_${uniqId}_office_${officeId}`)
      .attr("opacity", 0)
      .attr("r", 3)
      .attr("fill", "black")
      .on("mouseover", (d: any) => {
        const id = d.srcElement.id;
        d3.select(`#${id}`).attr("opacity", 1);
      })
      .on("mouseout", (d: any) => {
        const id = d.srcElement.id;
        d3.select(`#${id}`).attr("opacity", 0);
      });
    currentAngle.current = 0;
  };

  React.useEffect(() => {
    if (hasData && isEditable) {
      d3.selectAll("#offices path").on("dblclick", function (event) {
        const officeId = d3.select(getFirstPath(event)).attr("id");
        dispatch(getRoom(Number(officeId)))
          .unwrap()
          .then((res) => {
            const g = d3.select("#tables");
            const xy = d3.pointer(event, g.node());
            const x = xy[0];
            const y = xy[1];

            const office = d3.select(`#office_${officeId}`);
            const uniqId = uuidv4();
            if (office.empty()) {
              // There is no group of tables, add one for this office
              const group = g.append("g").attr("id", `office_${officeId}`).append("g").attr("id", `group_${uniqId}`);

              appendTable(x, y, uniqId, officeId, group);
            } else {
              // There is a group of tables, append a new table to it
              const group = office.append("g").attr("id", `group_${uniqId}`);

              appendTable(x, y, uniqId, officeId, group);
            }
            setDummyState(!dummyState);
          })
          .catch(() => {
            handleToast("Room doesn't exist", "error");
          });

        // TODO Post a new table on the backend
      });

      const sizes = d3.select("#Layer_1").attr("viewBox").split(" ");

      const width = sizes[2],
        height = sizes[3];
      const zoom = d3
        .zoom()
        .scaleExtent([1, 5])
        .translateExtent([
          [0, 0],
          [Number(width), Number(height)],
        ])
        .on("zoom", handleZoom);
      // @ts-ignore
      d3.select("#Layer_1").call(zoom).on("dblclick.zoom", null);
    }
  }, [hasData, dummyState, floorChanged, isEditable, dispatch]);

  const getPolyPoints = (id: string) => {
    const points = d3.select(id).attr("points").split(" ");
    const firstPoint = points[0].split(",");
    const secondPoint = points[1].split(",");
    const thirdPoint = points[2].split(",");
    const fourthPoint = points[3].split(",");

    return [
      { x: Number(firstPoint[0]), y: Number(firstPoint[1]) },
      { x: Number(secondPoint[0]), y: Number(secondPoint[1]) },
      { x: Number(thirdPoint[0]), y: Number(thirdPoint[1]) },
      { x: Number(fourthPoint[0]), y: Number(fourthPoint[1]) },
    ];
  };

  const getChairPoints = (id: string) => {
    const points = d3.select(id).attr("points").split(" ");
    const firstPoint = points[0].split(",");
    const secondPoint = points[1].split(",");
    const thirdPoint = points[2].split(",");
    const fourthPoint = points[3].split(",");
    const fifthPoint = points[4].split(",");
    const sixthPoint = points[5].split(",");

    return [
      { x: Number(firstPoint[0]), y: Number(firstPoint[1]) },
      { x: Number(secondPoint[0]), y: Number(secondPoint[1]) },
      { x: Number(thirdPoint[0]), y: Number(thirdPoint[1]) },
      { x: Number(fourthPoint[0]), y: Number(fourthPoint[1]) },
      { x: Number(fifthPoint[0]), y: Number(fifthPoint[1]) },
      { x: Number(sixthPoint[0]), y: Number(sixthPoint[1]) },
    ];
  };

  const getPointsString = (points: { x: number; y: number }[]) => {
    const pointsConverted = points.map((point) => `${point.x},${point.y}`).join(" ");
    return pointsConverted;
  };

  React.useEffect(() => {
    if (hasData && isEditable) {
      const offices = d3.selectAll("#tables g");

      offices.selectAll("g polygon").call(
        // @ts-ignore
        d3
          .drag()
          .on("start", function () {
            d3.select(this).attr("cursor", "grabbing");
            const points = getPolyPoints(`#${this.id}`);
            const chairPoints = getChairPoints(`#${this.id.replace("path", "chair")}`);
            const userId = `#${this.id.replace("path", "user")}`;
            const companyId = `#${this.id.replace("path", "company")}`;
            startingChairPosition.current = chairPoints;
            startingPosition.current = points;
            startingUserPosition.current.angleTable = Number(d3.select(`#${this.id}`).attr("angle"));
            if (!d3.select(userId).empty() && !d3.select(userId).empty()) {
              startingUserPosition.current.x = Number(d3.select(userId).attr("x"));
              startingUserPosition.current.y = Number(d3.select(userId).attr("y"));
              startingUserPosition.current.angle = Number(d3.select(userId).attr("angle"));
              startingCompanyPosition.current.x = Number(d3.select(companyId).attr("x"));
              startingCompanyPosition.current.y = Number(d3.select(companyId).attr("y"));
            }
            const currentTransoform = d3.select(`#${this.id}`).attr("transform");

            currentAngle.current = Number(currentTransoform?.split(",")[0].split("(")[1]);
          })
          .on("drag", function (event) {
            const idElements = d3
              .select(this)
              .attr("id")
              .split(/_office_|path_/);
            const uniqId = idElements[1];
            const officeId = idElements[2];
            onDrag(event, uniqId, officeId);
          })
          .on("end", function () {
            const userId = `#${this.id.replace("path", "user")}`;
            const companyId = `#${this.id.replace("path", "company")}`;
            const svgId = `#${this.id.replace("path", "svg")}`;
            if (!d3.select(userId).empty() && !d3.select(companyId).empty()) {
              const angle = Number(d3.select(userId).attr("angle"));
              const center = getElementCenter(this.id);

              d3.select(userId).attr("transform", `rotate(${angle},${center.x},${center.y})`);
              d3.select(companyId).attr("transform", `rotate(${angle},${center.x},${center.y})`);
              d3.select(userId).style("visibility", "visible");
              d3.select(companyId).style("visibility", "visible");
            }
            if (!d3.select(svgId).empty()) {
              d3.select(svgId).style("visibility", "visible");
            }
            d3.select(this).attr("stroke", "black").attr("cursor", "grab");
            checkIntersection(this);
          }),
      );

      offices.selectAll("*[id^=resize]").call(
        // @ts-ignore
        d3
          .drag()
          .on("start", function () {
            const { officeId, uniqId } = getIdsFromCircle(this, /_office_|resize_tool_/);
            const tableId = `#path_${uniqId}_office_${officeId}`;
            const chairId = `#chair_${uniqId}_office_${officeId}`;
            const userId = `#user_${uniqId}_office_${officeId}`;
            const companyId = `#company_${uniqId}_office_${officeId}`;

            const polyPoints = getPolyPoints(tableId);
            const polyChairPoints = getChairPoints(chairId);

            startingPosition.current = polyPoints;
            startingChairPosition.current = polyChairPoints;
            startingUserPosition.current.angleTable = Number(d3.select(tableId).attr("angle"));
            if (!d3.select(userId).empty() && !d3.select(companyId).empty()) {
              startingUserPosition.current.x = Number(d3.select(userId).attr("x"));
              startingUserPosition.current.y = Number(d3.select(userId).attr("y"));
              startingUserPosition.current.angle = Number(d3.select(userId).attr("angle"));
              startingCompanyPosition.current.x = Number(d3.select(companyId).attr("x"));
              startingCompanyPosition.current.y = Number(d3.select(companyId).attr("y"));
            }
          })
          .on("drag", function (event) {
            const { officeId, uniqId } = getIdsFromCircle(this, /_office_|resize_tool_/);
            const angle = Number(d3.select(`#path_${uniqId}_office_${officeId}`).attr("angle"));
            if (!(angle === 0 || angle === 180 || angle === 360)) {
              return;
            }
            const points = getPolyPoints(`#path_${uniqId}_office_${officeId}`);
            const userId = `#user_${uniqId}_office_${officeId}`;
            const companyId = `#company_${uniqId}_office_${officeId}`;

            points[1].x += event.dx;

            points[2].x += event.dx;
            points[2].y += event.dy;

            points[3].y += event.dy;
            const chairPoints = getChairPoints(`#chair_${uniqId}_office_${officeId}`);
            const widthWithRotation = points[0].x - points[1].x;

            chairPoints[0].x = points[0].x - widthWithRotation / 2 - CHAIR_WIDTH;
            chairPoints[1].x = points[0].x - widthWithRotation / 2 - CHAIR_WIDTH + 1;
            chairPoints[2].x = points[0].x - widthWithRotation / 2 - CHAIR_ANGLE;
            chairPoints[3].x = points[0].x - widthWithRotation / 2 + CHAIR_ANGLE;
            chairPoints[4].x = points[0].x - widthWithRotation / 2 + CHAIR_WIDTH - 1;
            chairPoints[5].x = points[0].x - widthWithRotation / 2 + CHAIR_WIDTH;

            if (!d3.select(userId).empty() && !d3.select(companyId).empty()) {
              d3.select(userId).style("visibility", "hidden");
              d3.select(companyId).style("visibility", "hidden");
            }

            const convertedPoints = getPointsString(points);
            const convertedChairPoints = getPointsString(chairPoints);

            const width = Math.sqrt(Math.pow(points[0].x - points[1].x, 2) + Math.pow(points[0].y - points[1].y, 2));
            const height = Math.sqrt(Math.pow(points[0].x - points[3].x, 2) + Math.pow(points[0].y - points[3].y, 2));

            if (width < TABLE_WIDTH - 10 || width > 100 || height < TABLE_HEIGHT || height > 100) {
              return;
            }
            d3.select(this).attr("cx", points[2].x).attr("cy", points[2].y);
            d3.select(`#chair_${uniqId}_office_${officeId}`).attr("points", convertedChairPoints);
            d3.select(`#path_${uniqId}_office_${officeId}`).attr("points", convertedPoints);
          })
          .on("end", function () {
            const { officeId, uniqId } = getIdsFromCircle(this, /_office_|resize_tool_/);
            const tableId = `path_${uniqId}_office_${officeId}`;
            const userId = `#user_${uniqId}_office_${officeId}`;
            const companyId = `#company_${uniqId}_office_${officeId}`;

            const polygon = document.getElementById(`path_${uniqId}_office_${officeId}`);
            if (!d3.select(userId).empty() && !d3.select(companyId).empty()) {
              const center = getElementCenter(tableId);
              d3.select(userId).attr("x", center.x);
              d3.select(userId).attr("y", center.y);
              d3.select(userId).style("visibility", "visible");
              d3.select(companyId).attr("x", center.x);
              d3.select(companyId).attr("y", center.y);
              d3.select(companyId).style("visibility", "visible");
            }
            checkIntersection(polygon as Element);
          }),
      );

      offices.selectAll("*[id^=rotate]").call(
        d3
          .drag()
          .on("start", function () {
            const { officeId, uniqId } = getIdsFromCircle(this, /_office_|rotate_tool_/);
            startRotation(`path_${uniqId}_office_${officeId}`);
            if (!d3.select(`#user_${uniqId}_office_${officeId}`).empty()) {
              const currentTransoform = d3.select(`#user_${uniqId}_office_${officeId}`).attr("transform");

              currentAngle.current = Number(currentTransoform?.split(",")[0].split("(")[1]);
            }

            if (isNaN(currentAngle.current)) currentAngle.current = 0;
          })
          .on("drag", function (event) {
            const { officeId, uniqId } = getIdsFromCircle(this, /_office_|rotate_tool_/);
            rotate(event, uniqId, officeId);
          })
          .on("end", function () {
            const { officeId, uniqId } = getIdsFromCircle(this, /_office_|rotate_tool_/);
            rotationEnd(`${uniqId}_office_${officeId}`);
            currentAngle.current = 0;
          })
          // @ts-ignore
          .container(function () {
            return this.parentNode!.parentNode;
          }),
      );

      offices.selectAll("*[id^=office_name]").call(
        d3
          .drag()
          .on("start", function () {
            d3.select(this).attr("cursor", "grabbing");
          })
          .on("drag", function (event) {
            const currentX = Number(d3.select(this).attr("x"));
            const currentY = Number(d3.select(this).attr("y"));
            d3.select(this).attr("x", currentX + event.dx);
            d3.select(this).attr("y", currentY + event.dy);
          })
          .on("end", function () {})
          // @ts-ignore
          .container(function () {
            return this.parentNode!.parentNode;
          }),
      );
    }
  }, [hasData, dummyState, checkIntersection, floorChanged, isEditable]);

  React.useEffect(() => {
    if (hasData && isEditable) {
      const offices = d3.selectAll("#tables g");
      offices.selectAll("g polygon").on("contextmenu", function (event) {
        clickedTableId.current = getCurrentTableId(event);
        if (clickedTableId.current.split("_")[0] === "chair") {
          return;
        }
        const angle = Number(d3.select(`#${clickedTableId.current}`).attr("angle"));
        setAngle(Math.round(angle));
        d3.select("#my_custom_menu")
          .style("position", "absolute")
          .style("left", event.pageX + "px")
          .style("top", event.pageY + 50 + "px")
          .style("display", "block");
        const id = d3.select(this).attr("id").replace("path", "resize_tool");
        d3.select(`#${id}`)
          .on("mouseover", () => {
            d3.select(`#${id}`).attr("opacity", 1);
          })
          .on("mouseout", () => {
            d3.select(`#${id}`).attr("opacity", 0);
          });
        const userId = d3.select(this).attr("id").replace("path", "user");
        if (!d3.select(`#${userId}`).empty()) {
          d3.select(`#delete_button`).style("Display", "block");
          d3.select("#font_actions").style("Display", "block");
          d3.select("#font_display").style("Display", "block");
          const curentFont = d3.select(`#${userId}`).style("font-size");
          d3.select("#current_font").text(curentFont);
          const username = d3.select(`#${userId}`).attr("username") as ReservationType;
          if (username === "Intern") {
            d3.select("#delete_intern_button").style("Display", "block");
          } else if (username === "Reserved") {
            d3.select("#delete_reservation_button").style("Display", "block");
          } else {
            d3.select("#delete_intern_button").style("Display", "none");
            d3.select("#delete_reservation_button").style("Display", "none");
          }
        } else {
          d3.select(`#delete_button`).style("Display", "none");
          d3.select("#font_actions").style("Display", "none");
          d3.select("#font_display").style("Display", "none");
          d3.select("#delete_intern_button").style("Display", "none");
          d3.select("#delete_reservation_button").style("Display", "none");
        }
        const svgId = d3.select(this).attr("id").replace("path", "svg");
        if (!d3.select(`#${svgId}`).empty()) {
          d3.select(`#delete_device_button`).style("Display", "block");
        } else {
          d3.select(`#delete_device_button`).style("Display", "none");
        }
        const officeNameId: string = `#office_name_${clickedTableId.current.split("_office_")[1]}`;
        if (!d3.select(officeNameId).empty()) {
          setOfficeName(d3.select(officeNameId).text());
        }

        const tableId = `#${clickedTableId.current}`;
        const points = getPolyPoints(tableId);
        const width = Math.round(
          Math.sqrt(Math.pow(points[0].x - points[1].x, 2) + Math.pow(points[0].y - points[1].y, 2)),
        );
        d3.select(tableId).attr("width", width);
        const heigth = Math.round(
          Math.sqrt(Math.pow(points[0].x - points[3].x, 2) + Math.pow(points[0].y - points[3].y, 2)),
        );
        d3.select(tableId).attr("heigth", heigth);
        d3.select("#current_width").text(width + "px");

        d3.select("#current_heigth").text(heigth + "px");

        const startPoint = getPolyPoints(tableId)[0];
        d3.select("#current_x").text(Math.round(startPoint.x));
        d3.select("#current_y").text(Math.round(startPoint.y));

        event.preventDefault();
      });
    }
  }, [hasData, dummyState, floorChanged, isEditable]);

  React.useEffect(() => {
    const conferences = d3.selectAll("#conferences");
    conferences.selectAll("path").on("contextmenu", function (event) {
      event.preventDefault();
      clickedTableId.current = getCurrentTableId(event);
      const xy = d3.pointer(event, conferences.node());
      const x = xy[0];
      const y = xy[1];
      setConf({ ...conf, x: x, y: y });
      setShowModalForConfName(true);
    });
  }, [hasData, isEditable]);

  React.useEffect(() => {
    if (hasData && isEditable) {
      const offices = d3.selectAll("#tables g");
      offices.selectAll("g circle").each(function () {
        const id = d3.select(this).attr("id").replace("path", "resize_tool");
        d3.select(`#${id}`)
          .on("mouseover", () => {
            d3.select(`#${id}`).attr("opacity", 1);
          })
          .on("mouseout", () => {
            d3.select(`#${id}`).attr("opacity", 0);
          });
        const id1 = d3.select(this).attr("id").replace("path", "rotate_tool");

        d3.select(`#${id1}`)
          .on("mouseover", () => {
            d3.select(`#${id1}`).attr("opacity", 1);
          })
          .on("mouseout", () => {
            d3.select(`#${id1}`).attr("opacity", 0);
          });
      });
    }
  }, [hasData, floorChanged, isEditable]);

  // @ts-ignore
  const getCurrentTableId = (event) => {
    try {
      return getFirstPath(event).getAttribute("id");
    } catch {
      return event.target.id;
    }
  };

  // @ts-ignore
  const getFirstPath = (event) => {
    const path = event.composedPath ? event.composedPath() : event.path;
    return path[0];
  };

  const closeMenu = () => {
    d3.select("#my_custom_menu").style("display", "none");
  };

  const handleDownload = () => {
    d3.select("svg g").attr("transform", "scale(1)");
    const svg_data = document.getElementById("Layer_1")?.innerHTML;

    const head =
      '<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 750 600" style="enable-background:new 0 0 750 600;" xml:space="preserve">';

    const full_svg = head + svg_data + "</svg>";
    const blob = new Blob([full_svg], { type: "image/svg+xml" });
    saveAs(blob, "graph.svg");
  };

  const handleDeleteTable = () => {
    const uniqId = clickedTableId.current.split(/_office_|path_/)[1];
    d3.select(`#group_${uniqId}`).remove();
    closeMenu();
  };

  const handleWarningMessage = (user: EmployeeResponse) => {
    handleToast(
      `Already assigned, location: 
        ${user.workstation?.room?.floor?.building?.name}, 
        ${user.workstation?.room?.floor?.nameForAdmins}, 
        ${user.workstation?.room?.name}`,
      "error",
      3000,
    );
  };

  const handleAddDevice = (deviceType?: "Computer" | "Laptop" | "Monitor" | "DualMonitor") => {
    const uniqId = clickedTableId.current.split(/_office_|path_/)[1];
    const officeId = clickedTableId.current.split(/_office_/)[1];
    const group = d3.select(`#group_${uniqId}`);
    const center = getElementCenter(clickedTableId.current);

    group.select(`#svg_${uniqId}_office_${officeId}`).remove();

    if (deviceType === undefined) {
      d3.select("#delete_device_button").style("Display", "none");
      return;
    }
    d3.select("#delete_device_button").style("Display", "block");

    let newAngle = Number(d3.select(`#${clickedTableId.current}`).attr("angle"));

    const correctedWidth = Number(group.select("circle").attr("cx")) - center.x;
    const correctedHeigth = Number(group.select("circle").attr("cy")) - center.y;

    let correctedX = Number(group.select(`#resize_tool_${uniqId}_office_${officeId}`).attr("cx"));
    let correctedY = Number(group.select(`#resize_tool_${uniqId}_office_${officeId}`).attr("cy"));

    //add margins
    if (correctedHeigth >= 0 && correctedWidth >= 0) {
      correctedX += 1;
      correctedY += 1;
    }
    if (correctedHeigth < 0 && correctedWidth < 0) {
      correctedX -= 1;
      correctedY -= 1;
    }
    if (correctedHeigth < 0 && correctedWidth >= 0) {
      correctedX += 1;
      correctedY -= 1;
    }
    if (correctedHeigth >= 0 && correctedWidth < 0) {
      correctedX -= 1;
      correctedY += 1;
    }

    if (newAngle > 180 && newAngle <= 270) {
      newAngle -= 180;
    } else if (newAngle > 270 && newAngle <= 360) {
      newAngle -= 180;
    } else {
      newAngle += 180;
    }

    const iconToUse = (function () {
      switch (deviceType) {
        case "Computer":
          return pcIcon;
        case "Laptop":
          return laptopIcon;
        case "Monitor":
          return monitorIcon;
        case "DualMonitor":
          return dualMonitorIcon;
        default:
          return undefined;
      }
    })();

    //append device svg to table
    iconToUse?.length &&
      d3.svg(iconToUse).then((xml) => {
        const nodeList = group.node() as HTMLElement | null;
        nodeList?.appendChild(xml.documentElement);
        group
          .select("svg")
          .style("overflow", "auto")
          .attr("id", `svg_${uniqId}_office_${officeId}`)
          .attr("x", correctedX)
          .attr("y", correctedY)
          .attr("height", 7)
          .attr("width", 7);

        group
          .select("svg")
          .selectChildren("path")
          .attr("angle", newAngle)
          .attr("transform", `rotate(${[newAngle]})`);
      });
  };

  const handleTableReservation = () => {
    const uniqId = clickedTableId.current.split(/_office_|path_/)[1];
    const officeId = clickedTableId.current.split(/_office_/)[1];
    const group = d3.select(`#group_${uniqId}`);
    const center = getElementCenter(clickedTableId.current);
    const userName = d3.select(`#user_${uniqId}_office_${officeId}`);
    const companyName = d3.select(`#company_${uniqId}_office_${officeId}`);

    if (!userName.empty()) {
      d3.select(`#user_${uniqId}_office_${officeId}`).remove();
    }
    if (!companyName.empty()) {
      d3.select(`#company_${uniqId}_office_${officeId}`).remove();
    }
    if (showModalForCompany) {
      const employeeNameOrAdditionalInfo = selectedEmployee?.name ?? additionalInfo;

      const displayText = employeeNameOrAdditionalInfo
        ? `${employeeNameOrAdditionalInfo}\n(${showModalForCompany})`
        : showModalForCompany;

      const companyName = selectedEmployee?.company?.name ?? selectedCompany;

      group
        .append("text")
        .attr("id", `user_${uniqId}_office_${officeId}`)
        .style("fill", "#f05a28")
        .style("font-size", additionalInfo ? (displayText.length > 30 ? "1.5px" : "2px") : "3px")
        .attr("text-anchor", "middle")
        .style("font-weight", "bold")
        .attr("x", center.x)
        .attr("y", center.y)
        .attr("dy", "0em")
        .attr("transform", null)
        .attr("username", showModalForCompany)
        .attr("reservation-employee-id", selectedEmployee?.id ?? null)
        .text(displayText);
      group
        .append("text")
        .attr("id", `company_${uniqId}_office_${officeId}`)
        .style("fill", "black")
        .style("font-size", "2.5px")
        .style("word-wrap", "break-word")
        .attr("text-anchor", "middle")
        .attr("x", center.x)
        .attr("y", center.y)
        .attr("dy", "1.5em")
        .text(companyName);
      d3.select("#font_actions").style("Display", "block");
      d3.select("#font_display").style("Display", "block");
      d3.select("#current_font").text("3px");
      if (showModalForCompany === "Intern") {
        d3.select("#delete_intern_button").style("Display", "block");
      } else {
        d3.select("#delete_reservation_button").style("Display", "block");
      }
      changeAngle();
    }
    setSelectedCompany("");
    setAdditionalInfo("");
    setSelectedEmployee(undefined);
    setShowModalForCompany(undefined);
    setReservingType("Employee");
  };

  const handleAddUser = (user: EmployeeResponse) => {
    if (user.workstation) {
      handleWarningMessage(user);
      return;
    }
    const uniqId = clickedTableId.current.split(/_office_|path_/)[1];
    const officeId = clickedTableId.current.split(/_office_/)[1];
    const group = d3.select(`#group_${uniqId}`);
    const center = getElementCenter(clickedTableId.current);
    const userId = `#user_${uniqId}_office_${officeId}`;
    const companyId = `#company_${uniqId}_office_${officeId}`;

    const userName = d3.select(`#user_${uniqId}_office_${officeId}`);
    const companyName = d3.select(`#company_${uniqId}_office_${officeId}`);
    if (!userName.empty()) {
      d3.select(`#user_${uniqId}_office_${officeId}`).remove();
    }
    if (!companyName.empty()) {
      d3.select(`#company_${uniqId}_office_${officeId}`).remove();
    }
    group
      .append("text")
      .attr("id", `user_${uniqId}_office_${officeId}`)
      .style("fill", "#f05a28")
      .style("font-size", "3px")
      .attr("text-anchor", "middle")
      .style("font-weight", "bold")
      .attr("x", center.x)
      .attr("y", center.y)
      .attr("dy", "0em")
      .attr("transform", null)
      .attr("username", user.username!)
      .text(user.name!);
    group
      .append("text")
      .attr("id", `company_${uniqId}_office_${officeId}`)
      .style("fill", "black")
      .style("font-size", "2.5px")
      .style("word-wrap", "break-word")
      .attr("text-anchor", "middle")
      .attr("x", center.x)
      .attr("y", center.y)
      .attr("dy", "1.5em")
      .text(user.company?.name!);

    let newAngle = Number(d3.select(`#${clickedTableId.current}`).attr("angle"));
    if (newAngle >= 90 && newAngle <= 180) {
      newAngle += 180;
    } else if (newAngle >= 180 && newAngle <= 270) {
      newAngle -= 180;
    }

    d3.select(userId).attr("angle", newAngle);

    d3.select(userId).attr("transform", `rotate(${[newAngle, center.x, center.y]})`);

    d3.select(companyId).attr("transform", `rotate(${[newAngle, center.x, center.y]})`);
    d3.select("#delete_button").style("Display", "block");
    d3.select("#font_actions").style("Display", "block");
    d3.select("#font_display").style("Display", "block");
    d3.select("#current_font").text("3px");

    setSearch("");
    setShowModal(false);
  };

  const handleDeleteUser = () => {
    const userId = `#${clickedTableId.current.replace("path", "user")}`;
    const companyId = `#${clickedTableId.current.replace("path", "company")}`;
    d3.select(userId).remove();
    d3.select(companyId).remove();
    d3.select(`#delete_button`).style("Display", "none");
    d3.select(`#delete_intern_button`).style("Display", "none");
    d3.select(`#delete_reservation_button`).style("Display", "none");
    d3.select("#font_actions").style("Display", "none");
    d3.select("#font_display").style("Display", "none");
  };

  const changeAngle = () => {
    const tableId = `#${clickedTableId.current}`;
    const chairId = `#${clickedTableId.current.replace("path", "chair")}`;
    const userId = `#${clickedTableId.current.replace("path", "user")}`;
    const svgId = `#${clickedTableId.current.replace("path", "svg")}`;
    const companyId = `#${clickedTableId.current.replace("path", "company")}`;
    const rotateToolId = `#${clickedTableId.current.replace("path", "rotate_tool")}`;
    const center = getElementCenter(clickedTableId.current);
    const oldAngle = Number(d3.select(tableId).attr("angle"));
    var rotatePos = [angle - oldAngle, center.x, center.y];

    d3.select(tableId).attr("transform", `rotate(${rotatePos})`);
    d3.select(chairId).attr("transform", `rotate(${rotatePos})`);

    let newAngle = angle;
    d3.select(tableId).attr("angle", newAngle);

    if (!d3.select(userId).empty() && !d3.select(companyId).empty()) {
      if (newAngle >= 90 && newAngle <= 180) {
        newAngle += 180;
      } else if (newAngle >= 180 && newAngle <= 270) {
        newAngle -= 180;
      }

      d3.select(userId).attr("angle", newAngle);

      d3.select(userId).attr("transform", `rotate(${[newAngle, center.x, center.y]})`);

      d3.select(companyId).attr("transform", `rotate(${[newAngle, center.x, center.y]})`);
      d3.select(rotateToolId).attr("transform", `rotate(${[newAngle, center.x, center.y]})`);
    }
    if (!d3.select(svgId).empty()) {
      let svgAngle = angle;
      if (svgAngle > 180 && svgAngle <= 270) {
        svgAngle -= 180;
      } else if (svgAngle > 270 && svgAngle <= 360) {
        svgAngle -= 180;
      } else {
        svgAngle += 180;
      }

      d3.select(svgId).select("path").attr("angle", svgAngle);

      d3.select(svgId)
        .select("path")
        .attr("transform", `rotate(${[svgAngle]})`);
    }
    rotationEnd(clickedTableId.current.split("path_")[1]);
  };

  const handleSearch = (e: any) => {
    const value: string = e.target.value;
    setSearch(value);
    if (value === "") {
      setSearchList(employees);
      return;
    }
    setSearchList(employees.filter((item) => item.name?.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
  };

  React.useEffect(() => {
    dispatch(getEmployees())
      .unwrap()
      .then((res) => {
        setSearchList(res);
      });
    dispatch(getCompanies())
      .unwrap()
      .then((res) => {
        setCompanyList(res.map((el) => el.name ?? ""));
      });
  }, [dispatch]);

  const handleOfficeName = () => {
    const id = clickedTableId.current.split("_office_")[1];
    if (!d3.select(`#office_name_${id}`).empty()) {
      d3.select(`#office_name_${id}`).remove();
    }
    const center = getElementCenter(`office_${id}`);
    d3.select(`#office_${id}`)
      .append("text")
      .attr("id", `office_name_${id}`)
      .style("fill", "#f05a28")
      .style("font-size", "5px")
      .attr("text-anchor", "middle")
      .style("font-weight", "bold")
      .attr("x", center.x)
      .attr("y", center.y)
      .attr("dy", "0em")
      .attr("transform", null)
      .text(officeName);
    setDummyState(!dummyState);
    setShowModalForName(false);
    d3.select("#my_custom_menu").style("display", "none");
  };

  const handleConfName = () => {
    const id = clickedTableId.current;
    if (!d3.select(`#conf_name_${id}`).empty()) {
      d3.select(`#conf_name_${id}`).remove();
    }

    d3.select(`#conferences`)
      .append("text")
      .attr("id", `conf_name_${id}`)
      .style("fill", "#f05a28")
      .style("font-size", "5px")
      .attr("text-anchor", "middle")
      .style("font-weight", "bold")
      .attr("x", conf.x)
      .attr("y", conf.y)
      .attr("dy", "0em")
      .attr("transform", null)
      .text(conf.name);
    setShowModalForConfName(false);
  };

  const handleIncreaseFont = () => {
    const userId = clickedTableId.current.replace("path", "user");
    const companyId = clickedTableId.current.replace("path", "company");
    const userFont = Number(d3.select(`#${userId}`).style("font-size").split("px")[0]);
    const companyFont = Number(d3.select(`#${companyId}`).style("font-size").split("px")[0]);
    d3.select(`#${userId}`).style("font-size", userFont + 0.1);
    d3.select(`#${companyId}`).style("font-size", companyFont + 0.1);
    d3.select("#current_font").text(`${Math.round((userFont + 0.1) * 100) / 100}px`);
  };

  const handleDecreaseFont = () => {
    const userId = clickedTableId.current.replace("path", "user");
    const companyId = clickedTableId.current.replace("path", "company");
    const userFont = Number(d3.select(`#${userId}`).style("font-size").split("px")[0]);
    const companyFont = Number(d3.select(`#${companyId}`).style("font-size").split("px")[0]);
    d3.select(`#${userId}`).style("font-size", userFont - 0.1);
    d3.select(`#${companyId}`).style("font-size", companyFont - 0.1);
    d3.select("#current_font").text(`${Math.round((userFont - 0.1) * 100) / 100}px`);
  };

  const handleWidthChange = (type: SizeChangeEnum) => {
    const tableId = clickedTableId.current;
    const angle = Number(d3.select(`#${tableId}`).attr("angle"));
    if (angle !== 0) {
      handleToast("Angle must be 0 for resize", "error");
      return;
    }
    const userId = clickedTableId.current.replace("path", "user");
    const companyId = clickedTableId.current.replace("path", "company");
    const chairId = clickedTableId.current.replace("path", "chair");
    const resizeToolId = clickedTableId.current.replace("path", "resize_tool");
    const points = getPolyPoints(`#${tableId}`);
    const currentWidth: number = Number(d3.select(`#${tableId}`).attr("width"));
    if (type === SizeChangeEnum.INCREASE) {
      d3.select(`#current_width`).text(currentWidth + 1 + "px");
      d3.select(`#${tableId}`).attr("width", currentWidth + 1);
      points[1].x += 1;
      points[2].x += 1;
    } else if (type === SizeChangeEnum.DECREASE) {
      d3.select(`#current_width`).text(currentWidth - 1 + "px");
      d3.select(`#${tableId}`).attr("width", currentWidth - 1);
      points[1].x -= 1;
      points[2].x -= 1;
    }
    const chairPoints = getChairPoints(`#${chairId}`);
    const widthWithRotation = points[0].x - points[1].x;

    chairPoints[0].x = points[0].x - widthWithRotation / 2 - CHAIR_WIDTH;
    chairPoints[1].x = points[0].x - widthWithRotation / 2 - CHAIR_WIDTH + 1;
    chairPoints[2].x = points[0].x - widthWithRotation / 2 - CHAIR_ANGLE;
    chairPoints[3].x = points[0].x - widthWithRotation / 2 + CHAIR_ANGLE;
    chairPoints[4].x = points[0].x - widthWithRotation / 2 + CHAIR_WIDTH - 1;
    chairPoints[5].x = points[0].x - widthWithRotation / 2 + CHAIR_WIDTH;

    const convertedPoints = getPointsString(points);
    const convertedChairPoints = getPointsString(chairPoints);

    d3.select(`#${resizeToolId}`).attr("cx", points[2].x).attr("cy", points[2].y);
    d3.select(`#${chairId}`).attr("points", convertedChairPoints);
    d3.select(`#${tableId}`).attr("points", convertedPoints);

    if (!d3.select(`#${userId}`).empty() && !d3.select(`#${companyId}`).empty()) {
      const center = getElementCenter(tableId);
      d3.select(`#${userId}`).attr("x", center.x);
      d3.select(`#${userId}`).attr("y", center.y);
      d3.select(`#${companyId}`).attr("x", center.x);
      d3.select(`#${companyId}`).attr("y", center.y);
    }
  };

  const handleHeigthChange = (type: SizeChangeEnum) => {
    const tableId = clickedTableId.current;
    if (angle !== 0) {
      handleToast("Angle must be 0 for resize", "error");
      return;
    }
    const userId = clickedTableId.current.replace("path", "user");
    const companyId = clickedTableId.current.replace("path", "company");
    const chairId = clickedTableId.current.replace("path", "chair");
    const resizeToolId = clickedTableId.current.replace("path", "resize_tool");
    const points = getPolyPoints(`#${tableId}`);
    const currentheigth: number = Number(d3.select(`#${tableId}`).attr("heigth"));
    if (type === SizeChangeEnum.INCREASE) {
      d3.select(`#current_heigth`).text(currentheigth + 1);
      d3.select(`#${tableId}`).attr("heigth", currentheigth + 1);
      points[2].y += 1;
      points[3].y += 1;
    } else if (type === SizeChangeEnum.DECREASE) {
      d3.select(`#current_heigth`).text(currentheigth - 1);
      d3.select(`#${tableId}`).attr("heigth", currentheigth - 1);
      points[2].y -= 1;
      points[3].y -= 1;
    }
    const chairPoints = getChairPoints(`#${chairId}`);
    const widthWithRotation = points[0].x - points[1].x;

    chairPoints[0].x = points[0].x - widthWithRotation / 2 - CHAIR_WIDTH;
    chairPoints[1].x = points[0].x - widthWithRotation / 2 - CHAIR_WIDTH + 1;
    chairPoints[2].x = points[0].x - widthWithRotation / 2 - CHAIR_ANGLE;
    chairPoints[3].x = points[0].x - widthWithRotation / 2 + CHAIR_ANGLE;
    chairPoints[4].x = points[0].x - widthWithRotation / 2 + CHAIR_WIDTH - 1;
    chairPoints[5].x = points[0].x - widthWithRotation / 2 + CHAIR_WIDTH;

    const convertedPoints = getPointsString(points);
    const convertedChairPoints = getPointsString(chairPoints);

    d3.select(`#${resizeToolId}`).attr("cx", points[2].x).attr("cy", points[2].y);
    d3.select(`#${chairId}`).attr("points", convertedChairPoints);
    d3.select(`#${tableId}`).attr("points", convertedPoints);

    if (!d3.select(`#${userId}`).empty() && !d3.select(`#${companyId}`).empty()) {
      const center = getElementCenter(tableId);
      d3.select(`#${userId}`).attr("x", center.x);
      d3.select(`#${userId}`).attr("y", center.y);
      d3.select(`#${companyId}`).attr("x", center.x);
      d3.select(`#${companyId}`).attr("y", center.y);
    }
  };

  const handlePositionChange = (type: PositionChangeEnum, value: number) => {
    const tableId = clickedTableId.current;
    const userId = clickedTableId.current.replace("path", "user");
    const companyId = clickedTableId.current.replace("path", "company");
    const chairId = clickedTableId.current.replace("path", "chair");
    const resizeToolId = clickedTableId.current.replace("path", "resize_tool");
    const rotateToolId = clickedTableId.current.replace("path", "rotate_tool");

    let points: any[] = [];
    let chairPoints: any[] = [];

    if (type === PositionChangeEnum.X) {
      points = getPolyPoints(`#${tableId}`).filter((item) => {
        item.x += value;
        return item;
      });

      chairPoints = getChairPoints(`#${chairId}`).filter((item) => {
        item.x += value;
        return item;
      });
    } else if (type === PositionChangeEnum.Y) {
      points = getPolyPoints(`#${tableId}`).filter((item) => {
        item.y += value;
        return item;
      });

      chairPoints = getChairPoints(`#${chairId}`).filter((item) => {
        item.y += value;
        return item;
      });
    }
    d3.select("#current_x").text(Math.round(points[0].x));
    d3.select("#current_y").text(Math.round(points[0].y));

    const convertedPoints = getPointsString(points);
    const convertedChairPoints = getPointsString(chairPoints);

    d3.select(`#${resizeToolId}`).attr("cx", points[2].x).attr("cy", points[2].y);
    d3.select(`#${rotateToolId}`).attr("cx", points[0].x).attr("cy", points[0].y);
    d3.select(`#${chairId}`).attr("points", convertedChairPoints);
    d3.select(`#${tableId}`).attr("points", convertedPoints);

    if (!d3.select(`#${userId}`).empty() && !d3.select(`#${companyId}`).empty()) {
      const center = getElementCenter(tableId);
      d3.select(`#${userId}`).attr("x", center.x);
      d3.select(`#${userId}`).attr("y", center.y);
      d3.select(`#${companyId}`).attr("x", center.x);
      d3.select(`#${companyId}`).attr("y", center.y);
    }
  };

  const handlePrint = () => {
    window.print();
  };

  const onReservationModalClose = () => {
    setShowModalForCompany(undefined);
    setReservingType("Employee");
    setSelectedEmployee(undefined);
  };

  React.useEffect(() => {
    if (reservingType === "Employee") {
      setSelectedCompany("");
      setAdditionalInfo("");
    }

    if (reservingType === "Other") {
      setSelectedEmployee(undefined);
    }
  }, [reservingType]);

  return (
    <div className="svg">
      <div className="svg__btns">
        <button type="button" onClick={handlePrint} className="svg__btn btn btn--orange">
          Print SVG
        </button>

        <button className="svg__btn btn btn--orange" type="button" onClick={handleDownload}>
          Download SVG
        </button>
      </div>
      <Modal size="large" show={showModal} handleClose={() => setShowModal(false)}>
        <h2
          className="manager__title"
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: 0,
          }}>
          <div style={{ padding: "10px" }}>List of added employees</div>

          <input
            type="text"
            placeholder="Search..."
            value={search}
            autoFocus
            onChange={(e) => handleSearch(e)}
            style={{ width: "30%", margin: 0 }}
          />
        </h2>
        <div className="manager__content-modal">
          <table className="table">
            <thead>
              <tr className="table__row">
                <th className="table__head">Name</th>
                <th className="table__head">Company</th>
                <th className="table__head">Email</th>
                <th className="table__head">Assigned</th>
                <th className="table__head table__head--action"></th>
              </tr>
            </thead>
            <tbody>
              {searchList.map((item) => {
                return (
                  <tr className="table__row" key={item.id}>
                    <td className="table__cell">{item.name}</td>
                    <td className="table__cell">{item.company?.name}</td>
                    <td className="table__cell">{item.email}</td>
                    <td className="table__cell">{item.workstation !== null ? "Yes" : "No"}</td>
                    <td className="table__cell">
                      <button
                        type="button"
                        onClick={() => handleAddUser(item)}
                        className="btn btn--green"
                        disabled={item.workstation !== null}>
                        {item.workstation ? (
                          <img
                            style={{
                              padding: "0px 9px 0px 9px",
                              cursor: "pointer",
                            }}
                            src={InfoIcon}
                            alt=""
                            onClick={() => handleWarningMessage(item)}
                          />
                        ) : (
                          "Assign"
                        )}
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Modal>
      <Modal show={showModalForName} handleClose={() => setShowModalForName(false)}>
        <input
          type="text"
          value={officeName}
          onChange={(e) => setOfficeName(e.target.value)}
          style={{ fontSize: "25px" }}
        />
        <button
          type="button"
          onClick={handleOfficeName}
          className="form__btn btn btn--green"
          style={{ width: "100%", margin: "5px 0 5px 0" }}>
          SUBMIT
        </button>
      </Modal>

      <Modal show={!!showModalForCompany} size="small" handleClose={onReservationModalClose} disableOverflow>
        {showModalForCompany === "Reserved" && (
          <>
            <RadioButtons
              error={null}
              onChangeSelection={(e) => {
                setReservingType(e as ReservedType);
              }}
              setValue={() => {}}
              label="Reservation for:"
              statuses={["Employee", "Other"]}
              valueForEdit={reservingType}
            />
            <br />
          </>
        )}

        {showModalForCompany === "Reserved" && reservingType === "Employee" && (
          <div id="reservation-employee-input">
            <AutoComplete
              onChange={(_, value) => {
                setSelectedEmployee((value as EmployeeResponse | null) ?? undefined);
              }}
              autoHighlight
              options={[...employees]}
              value={selectedEmployee ?? null}
              getOptionLabel={(item) => `${(item as EmployeeResponse).name} - ${(item as EmployeeResponse).username}`}
              renderOption={(props, option) => (
                <Box component="li" {...props} key={(option as EmployeeResponse).id}>
                  {(option as EmployeeResponse).name} ({(option as EmployeeResponse).username})
                </Box>
              )}
              sx={{ width: 260, background: "white" }}
              renderInput={(params) => <TextField {...params} label="Find employee" placeholder="Search..." />}
            />
          </div>
        )}

        {(showModalForCompany === "Intern" || reservingType === "Other") && (
          <>
            <Dropdown
              style={{ width: "100%" }}
              error="Company name is required"
              label="Company"
              value={selectedCompany}
              onChangeSelection={(selected) => setSelectedCompany(selected)}
              placeholder={"Select company..."}
              options={companyList}
            />
            <br />
            <p style={{ fontSize: "12px", marginBottom: "5px" }}>Additional info</p>
            <input
              type="text"
              className="form__input"
              style={{ height: "40px" }}
              placeholder="Enter additional info if needed"
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
            />
          </>
        )}

        <hr />

        <button
          type="button"
          onClick={handleTableReservation}
          className="form__btn btn btn--green"
          style={{ width: "100%", margin: "5px 0 5px 0" }}
          disabled={
            showModalForCompany === "Reserved" && reservingType === "Employee" ? !selectedEmployee : !selectedCompany
          }>
          SUBMIT
        </button>
      </Modal>

      <Modal show={showModalForConfName} handleClose={() => setShowModalForConfName(false)}>
        <input
          type="text"
          value={conf?.name}
          onChange={(e) => setConf({ ...conf, name: e.target.value })}
          style={{ fontSize: "25px" }}
        />
        <button
          type="button"
          onClick={handleConfName}
          className="form__btn btn btn--green"
          style={{ width: "100%", margin: "5px 0 5px 0" }}>
          SUBMIT
        </button>
      </Modal>

      <div id="my_custom_menu" style={{ display: "none" }}>
        <div className="context-menu">
          <div className="context-menu__header">
            <Close onClick={closeMenu} />
          </div>
          <div className="context-menu__content">
            <span
              className="context-menu__cta"
              onClick={() => {
                setShowModal(true);
              }}>
              Add user +
            </span>
            <span
              className="context-menu__cta"
              id="delete_button"
              style={{ display: "none" }}
              onClick={handleDeleteUser}>
              Delete user -
            </span>
            <span
              className="context-menu__cta"
              id="delete_button"
              style={{ display: "block" }}
              onClick={() => setShowModalForName(true)}>
              Set office name +
            </span>
            <span
              className="context-menu__cta"
              onClick={() => {
                setSelectedCompany("");
                setShowModalForCompany("Intern");
              }}>
              Add intern +
            </span>
            <span
              className="context-menu__cta"
              id="delete_intern_button"
              style={{ display: "none" }}
              onClick={handleDeleteUser}>
              Delete intern -
            </span>
            <span
              className="context-menu__cta"
              onClick={() => {
                setSelectedCompany("");
                setShowModalForCompany("Reserved");
              }}>
              Add reservation +
            </span>
            <span
              className="context-menu__cta"
              style={{ display: "none" }}
              id="delete_reservation_button"
              onClick={handleDeleteUser}>
              Remove reservation -
            </span>
            <span className="context-menu__cta" onClick={() => handleAddDevice("Computer")}>
              Add computer +
            </span>
            <span className="context-menu__cta" onClick={() => handleAddDevice("Laptop")}>
              Add laptop +
            </span>
            <span className="context-menu__cta" onClick={() => handleAddDevice("Monitor")}>
              Add monitor +
            </span>
            <span className="context-menu__cta" onClick={() => handleAddDevice("DualMonitor")}>
              Add dual monitor +
            </span>
            <span className="context-menu__cta" id="delete_device_button" onClick={() => handleAddDevice()}>
              Delete device -
            </span>
            <div className="context-menu__group" id="font_display">
              <span className="context-menu__font-text">
                <strong>Font size:</strong> <span id="current_font">-</span>
              </span>
              <div id="font_actions" className="context-menu__font-actions">
                <button type="button" className="context-menu__font-btn" onClick={handleIncreaseFont}>
                  +
                </button>
                <button type="button" className="context-menu__font-btn" onClick={handleDecreaseFont}>
                  -
                </button>
              </div>
            </div>
            <div className="context-menu__group context-menu__group--size">
              <div id="width_display" className="context-menu__size-item">
                <span className="context-menu__font-text">
                  <strong>Width:</strong> <span id="current_width">-</span>
                </span>
                <div id="width_actions" className="context-menu__font-actions">
                  <button
                    type="button"
                    className="context-menu__font-btn"
                    onClick={() => handleWidthChange(SizeChangeEnum.INCREASE)}>
                    +
                  </button>
                  <button
                    type="button"
                    className="context-menu__font-btn"
                    onClick={() => handleWidthChange(SizeChangeEnum.DECREASE)}>
                    -
                  </button>
                </div>
              </div>
              <div id="heigth_display" className="context-menu__size-item">
                <span className="context-menu__font-text">
                  <strong>Heigth:</strong> <span id="current_heigth">-</span>
                </span>
                <div id="heigth_actions" className="context-menu__font-actions">
                  <button
                    type="button"
                    className="context-menu__font-btn"
                    onClick={() => handleHeigthChange(SizeChangeEnum.INCREASE)}>
                    +
                  </button>
                  <button
                    type="button"
                    className="context-menu__font-btn"
                    onClick={() => handleHeigthChange(SizeChangeEnum.DECREASE)}>
                    -
                  </button>
                </div>
              </div>
            </div>
            <div className="context-menu__group context-menu__group--size">
              <div id="x_display" className="context-menu__size-item">
                <span className="context-menu__font-text">
                  <strong>X:</strong> <span id="current_x">-</span>
                </span>
                <div id="x_actions" className="context-menu__font-actions">
                  <button
                    type="button"
                    className="context-menu__font-btn"
                    onClick={() => handlePositionChange(PositionChangeEnum.X, 0.1)}>
                    +
                  </button>
                  <button
                    type="button"
                    className="context-menu__font-btn"
                    onClick={() => handlePositionChange(PositionChangeEnum.X, -0.1)}>
                    -
                  </button>
                </div>
              </div>
              <div id="y_display" className="context-menu__size-item">
                <span className="context-menu__font-text">
                  <strong>Y:</strong> <span id="current_y">-</span>
                </span>
                <div id="y_actions" className="context-menu__font-actions">
                  <button
                    type="button"
                    className="context-menu__font-btn"
                    onClick={() => handlePositionChange(PositionChangeEnum.Y, 0.1)}>
                    +
                  </button>
                  <button
                    type="button"
                    className="context-menu__font-btn"
                    onClick={() => handlePositionChange(PositionChangeEnum.Y, -0.1)}>
                    -
                  </button>
                </div>
              </div>
            </div>
            <div className="context-menu__group context-menu__group--angle">
              <input
                className="context-menu__angle-input"
                type="number"
                min={0}
                max={360}
                value={angle}
                onChange={(e: any) => setAngle(Number(e.target.value))}
              />
              <button type="button" onClick={changeAngle} className="context-menu__angle-btn btn btn--green btn--small">
                Set angle
              </button>
            </div>
            <div className="context-menu__group">
              <button type="button" onClick={handleDeleteTable} className="btn btn--red">
                DELETE TABLE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="svg_container" className="svg__holder"></div>
    </div>
  );
};

const AutoComplete = styled(Autocomplete)`
  & .MuiInputBase-input {
    height: 1rem;
    margin: 5;
  }
`;
