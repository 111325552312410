import * as React from "react";

import {
  StatisticByBuildingsHeader,
  StatisticByBuildingsSidebar,
} from "./index";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getBuildingStatistics } from "../../redux/actions/statisticsActions";
import { Loader } from "../../components/loader/Loader";

export const StatisticByBuildings: React.FC = () => {
  const dispatch = useAppDispatch();
  const { buildingStatistics, isLoading } = useAppSelector(
    (state) => state.statistics
  );

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getBuildingStatistics());
  }, [dispatch]);

  return (
    <main className="main">
      {handleLoader()}
      <StatisticByBuildingsHeader />
      <div className="stats">
        <div className="wrap stats__wrap">
          <StatisticByBuildingsSidebar statistic={buildingStatistics} />
        </div>
      </div>
    </main>
  );
};
