import * as React from "react";

import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Modal } from "../../components/modal/Modal";
import {
  getBuildings,
  deleteBuilding,
} from "../../redux/actions/buildingActions";
import { useAppDispatch } from "../../redux/hooks";
import { BuildingResponse } from "../../api/generated/index";
import { SUCCESS_DELETE } from "../../constants/messageConstants";
import { handleErrors, handleToast } from "../../utils/helpers";

interface BuildingTableProps {
  buildings: BuildingResponse[];
}

export const BuildingTable: React.FC<BuildingTableProps> = ({ buildings }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentItem = React.useRef<BuildingResponse | null>(null);
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);

  const handleOpen = (item: BuildingResponse) => {
    setShowDeletePopup(true);
    currentItem.current = item;
  };
  const handleClose = () => {
    setShowDeletePopup(false);
  };

  const onDelete = (id: number) => {
    dispatch(deleteBuilding(id))
      .unwrap()
      .then(() => {
        dispatch(getBuildings());
        handleToast(SUCCESS_DELETE);
      })
      .catch((res) => {
        handleErrors(res);
      });
    handleClose();
  };

  const onCancel = () => {
    handleClose();
  };

  const onAdd = (id: number) => {
    navigate(`new/${id}`);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={true} />
      <h2 className="manager__title">List of added buildings</h2>
      <div className="manager__content">
        <table className="table">
          <thead>
            <tr className="table__row">
              <th className="table__head">Name</th>
              <th className="table__head">City</th>
              <th className="table__head">Address</th>
              <th className="table__head table__head--action">Edit</th>
              <th className="table__head table__head--action">Delete</th>
            </tr>
          </thead>
          <tbody>
            {buildings.map((item) => {
              return (
                <tr className="table__row" key={item.id}>
                  <td className="table__cell">{item.name}</td>
                  <td className="table__cell">{item.city?.name}</td>
                  <td className="table__cell">{item.address}</td>
                  <td className="table__cell">
                    <button
                      onClick={() => onAdd(item.id!)}
                      className="btn btn--green"
                    >
                      Edit
                    </button>
                  </td>
                  <td className="table__cell">
                    <button
                      onClick={() => handleOpen(item)}
                      className="btn btn--red"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal show={showDeletePopup} handleClose={onCancel}>
        <h2 className="popup__heading-plain">
          Are you sure you want to delete this building?
        </h2>
        <div className="popup__actions">
          <button
            onClick={() => onDelete(currentItem.current?.id!)}
            type="button"
            className="btn btn--green popup__btn"
          >
            Delete
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="btn btn--red popup__btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
