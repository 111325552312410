import * as React from "react";

import {
  Controller,
  SubmitHandler,
  useForm,
  ControllerRenderProps,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { TextInput } from "ui-components";
import { ManageWrapper } from "layouts/manageLayout";
import { MainWrapper } from "layouts/mainWrapper/MainWrapper";
import { Dropdown } from "ui-components";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { getCity, postCity, putCity } from "../../redux/actions/cityActions";
import { getCountries } from "../../redux/actions/countryActions";
import { SUCCESS_POST, SUCCESS_PUT } from "../../constants/messageConstants";
import { handleErrors, handleToast } from "../../utils/helpers";
import { getCountryId } from "../../utils/helpers";

type Inputs = {
  name: string;
  country: string;
};

export const NewCity: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const { countries } = useAppSelector((state) => state.country);
  const { cities } = useAppSelector((state) => state.city);
  const options: string[] = countries.map((item) => item.name) as string[];
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (id !== undefined) {
      dispatch(
        putCity({
          cityId: Number(id),
          name: data.name,
          countryId: getCountryId(data.country, countries),
        })
      )
        .unwrap()
        .then(() => {
          handleToast(SUCCESS_PUT);
          navigate(-1);
        })
        .catch((res) => {
          handleErrors(res);
        });
      return;
    }
    dispatch(
      postCity({
        name: data.name,
        countryId: getCountryId(data.country, countries),
      })
    )
      .unwrap()
      .then(() => {
        handleToast(SUCCESS_POST);
        navigate(-1);
      })
      .catch((res) => {
        handleErrors(res);
      });
  };

  const onCancel = () => {
    navigate(-1);
  };

  const errorName =
    errors.name?.type === "required" ? "City name is required" : null;

  const errorCountry =
    errors.country?.type === "required" ? "Country is required" : null;

  const rulesName = {
    required: true,
  };

  const rulesCountry = {
    required: true,
  };

  const renderName = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "name">;
  }) => {
    return (
      <TextInput
        error={errorName}
        label="City name"
        placeholder="Enter a city name..."
        {...field}
      />
    );
  };

  const renderCountries = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "country">;
  }) => {
    return (
      <Dropdown
        error={errorCountry!}
        onChangeSelection={field.onChange}
        label="Select a country"
        placeholder={field.value !== "" ? field.value : "Select a country..."}
        options={options}
        {...field}
      />
    );
  };

  React.useEffect(() => {
    dispatch(getCountries());

    if (id !== undefined) {
      dispatch(getCity(Number(id)))
        .unwrap()
        .then((res) => {
          setValue("name", res?.name!);
          setValue("country", res?.country?.name!);
        })
        .catch((res) => {
          handleErrors(res);
        });
    }
  }, [cities, dispatch, id, setValue]);

  return (
    <ManageWrapper
      buttonText="Add a new city"
      manageText="a city"
      navigateTo=""
      isButtonVisible={false}
    >
      <MainWrapper title="Add a new city">
        <Toaster position="top-center" reverseOrder={true} />
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="form__row ">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={rulesName}
              render={renderName}
            />
            <Controller
              name="country"
              control={control}
              defaultValue=""
              rules={rulesCountry}
              render={renderCountries}
            />
          </div>
          <div className="form__actions">
            <button type="submit" className="form__btn btn btn--green">
              Save
            </button>
            <button
              onClick={onCancel}
              type="button"
              className="form__btn btn btn--red"
            >
              Cancel
            </button>
          </div>
        </form>
      </MainWrapper>
    </ManageWrapper>
  );
};
