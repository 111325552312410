import * as React from "react";

import { Link } from "react-router-dom";

interface Props {
  manageText: string;
  isButtonVisible: boolean;
  navigateTo?: string;
  buttonText?: string;
}

export const ManageWrapper: React.FC<Props> = ({
  buttonText,
  manageText,
  isButtonVisible,
  children,
  navigateTo,
}) => {
  const Button = () =>
    isButtonVisible ? (
      <Link className="btn btn--black" to={navigateTo || "/"}>
        {buttonText}
      </Link>
    ) : null;

  return (
    <main className="main">
      <div className="wrap">
        <div className="intro">
          <h1 className="intro__title">
            Manage <strong>{manageText}</strong>
          </h1>
          <Button />
        </div>
        <div className="manager">{children}</div>
      </div>
    </main>
  );
};
