import { createAsyncThunk } from "@reduxjs/toolkit";

import { CityResponse, CityRequest } from "../../api/generated/index";
import { Api } from "../../api/index";
import { PostCityType, PutCityType } from "../../types/CityTypes";

export const getCities = createAsyncThunk(
  "getCities",
  async (): Promise<CityResponse[]> => {
    return Api.City.getAllCities().then(({ data }) => {
      return data;
    });
  }
);

export const getCity = createAsyncThunk(
  "getCity",
  async (id: number): Promise<CityResponse> => {
    return Api.City.getCityById(id).then(({ data }) => {
      return data;
    });
  }
);

export const postCity = createAsyncThunk(
  "postCity",
  async (postCityType: PostCityType): Promise<CityResponse> => {
    const cityRequest: CityRequest = {
      name: postCityType.name,
      countryId: postCityType.countryId,
    };
    return Api.City.saveCity(cityRequest).then(({ data }) => {
      return data;
    });
  }
);

export const deleteCity = createAsyncThunk(
  "deleteCity",
  async (id: number): Promise<CityResponse> => {
    return Api.City.deleteCity(id).then(({ data }) => {
      return data;
    });
  }
);

export const putCity = createAsyncThunk(
  "putCity",
  async (putCityType: PutCityType): Promise<CityResponse> => {
    const cityRequest: CityRequest = {
      name: putCityType.name,
      countryId: putCityType.countryId,
    };
    return Api.City.updateCity(putCityType.cityId, cityRequest).then(
      ({ data }) => {
        return data;
      }
    );
  }
);
