import * as React from "react";

import {
  BuildingStatistic,
  BuildingStatisticBuilding,
} from "../../api/generated";

interface StatisticByBuildingsSidebarItemProps {
  item: City;
  handleToggle: (city: number) => void;
  handleForm: (item: BuildingStatisticBuilding) => void;
}

interface City {
  item: BuildingStatistic;
  toggle: boolean;
}

export const StatisticByBuildingsSidebarItem: React.FC<
  StatisticByBuildingsSidebarItemProps
> = ({ item, handleToggle, handleForm }) => {
  const [selected, setSelected] = React.useState<number>(0);
  const handleItemClassName = item.toggle
    ? "stats-sidebar__btn stats-sidebar__btn--children js-stats-expand-city stats-sidebar__btn--active"
    : "stats-sidebar__btn stats-sidebar__btn--children js-stats-expand-city";

  const handleSubItemClassName = item.toggle
    ? "stats-sidebar__content js-stats-content stats-sidebar__content--active"
    : "stats-sidebar__content js-stats-content";

  const handleClick = (building: BuildingStatisticBuilding) => {
    handleForm(building);
    setSelected(building.id!);
  };

  return (
    <div className="stats-sidebar__item">
      <button
        onClick={() => handleToggle(item.item.id!)}
        type="button"
        className={handleItemClassName}
      >
        {item.item.name}
      </button>
      <div className={handleSubItemClassName}>
        {item.item.buildingStatisticBuildings?.map((building) => (
          <button
            key={building.id}
            type="button"
            className={`stats-sidebar__subitem js-stats-subitem ${
              building.id === selected ? "stats-sidebar__subitem--active" : ""
            }`}
            onClick={() => handleClick(building)}
          >
            {building.name}
          </button>
        ))}
      </div>
    </div>
  );
};
