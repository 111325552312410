import React, { useCallback, useEffect, useState } from "react";
import { ReplacementTokenModal } from "./ReplacementTokenModal";
import { useAppDispatch } from "redux/hooks";
import { deleteReplacementToken, getAllReplacementTokens } from "redux/actions/accessControlCardActions";
import { ReplacementTokenResponse } from "api/generated";
import { EmptyListPlaceholder } from "components/emptyListPlaceholder";
import { ReplacementTokenRow } from "./ReplacementTokenRow";

type Props = {
  employeeId: number;
};

export const ReplacementTokensSection = ({ employeeId }: Props) => {
  const dispatch = useAppDispatch();

  const [allReplacementTokens, setAllReplacementTokens] = useState<ReplacementTokenResponse[]>([]);
  const [showModal, setShowModal] = useState(false);

  const [replacementToEdit, setReplacementToEdit] = useState<ReplacementTokenResponse | undefined>();
  const handleEditReplacementToken = (replacement: ReplacementTokenResponse) => {
    setReplacementToEdit(replacement);
    setShowModal(true);
  };
  const handleAddNewReplacementToken = () => {
    setReplacementToEdit(undefined);
    setShowModal(true);
  };
  const handleDeleteReplacementToken = async (item: ReplacementTokenResponse) => {
    await dispatch(deleteReplacementToken({ employeeId, id: item.id ?? 0 }));
    loadAllReplacementTokens();
  };

  const loadAllReplacementTokens = useCallback(() => {
    dispatch(getAllReplacementTokens(employeeId))
      .unwrap()
      .then((res) => setAllReplacementTokens(res));
  }, [dispatch, employeeId]);

  useEffect(() => {
    loadAllReplacementTokens();
  }, [loadAllReplacementTokens]);

  return (
    <>
      <div className="form__row" style={{ marginTop: 40 }}>
        <div style={{ width: "100%" }}>
          <div>
            <label className="form__label" htmlFor="acs_table">
              Replacements tokens
            </label>

            <button onClick={handleAddNewReplacementToken} type="button" className="form__btn btn btn--green">
              Add new
            </button>
          </div>

          <table className="table" id="acs_table">
            <thead>
              <tr className="table__row">
                <th className="table__head">Date</th>
                <th className="table__head">Token</th>
                <th className="table__head">Performed by</th>
                <th className="table__head">Actions</th>
              </tr>
            </thead>
            <tbody>
              {allReplacementTokens.length ? (
                allReplacementTokens.map((item) => (
                  <ReplacementTokenRow
                    key={item.id}
                    item={item}
                    onDelete={handleDeleteReplacementToken}
                    onEdit={handleEditReplacementToken}
                  />
                ))
              ) : (
                <tr className="table__row">
                  <td colSpan={3}>
                    <EmptyListPlaceholder />
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot></tfoot>
          </table>
        </div>
      </div>
      <ReplacementTokenModal
        show={showModal}
        existing={replacementToEdit}
        employeeId={employeeId}
        handleClose={() => {
          setReplacementToEdit(undefined);
          setShowModal(false);
        }}
        handleConfirm={() => {
          loadAllReplacementTokens();
          setShowModal(false);
        }}
      />
    </>
  );
};
