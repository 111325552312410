import * as React from "react";

import { ManageWrapper } from "layouts/manageLayout";
import { EmptyListPlaceholder } from "components/emptyListPlaceholder";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { CountryTable } from "./index";
import { getCountries } from "../../redux/actions/countryActions";
import { Loader } from "../../components/loader/Loader";

export const Country: React.FC = () => {
  const dispatch = useAppDispatch();
  const { countries, isLoading } = useAppSelector((state) => state.country);

  const handleView = () => {
    if (countries.length !== 0) {
      return <CountryTable countries={countries} />;
    }

    return <EmptyListPlaceholder />;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  return (
    <ManageWrapper
      buttonText="Add a new country"
      manageText="a country"
      navigateTo="/country/new"
      isButtonVisible
    >
      {handleLoader()}
      {handleView()}
    </ManageWrapper>
  );
};
