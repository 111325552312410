import { createAsyncThunk } from "@reduxjs/toolkit";

import { CompanyRequest, CompanyResponse } from "../../api/generated/index";
import { PostCompanyType, PutCompanyType } from "../../types/CompanyTypes";
import { Api } from "../../api/index";

export const getCompanies = createAsyncThunk(
  "getCompanies",
  async (): Promise<CompanyResponse[]> => {
    return Api.Company.getAllCompanies().then(({ data }) => {
      return data;
    });
  }
);

export const getCompany = createAsyncThunk(
  "getCompany",
  async (id: number): Promise<CompanyResponse> => {
    return Api.Company.getCompanyById(id).then(({ data }) => {
      return data;
    });
  }
);

export const postCompany = createAsyncThunk(
  "postCompany",
  async (postCompanyType: PostCompanyType): Promise<CompanyResponse> => {
    const companyRequest: CompanyRequest = { name: postCompanyType.name };
    return Api.Company.saveCompany(companyRequest).then(({ data }) => {
      return data;
    });
  }
);

export const deleteCompany = createAsyncThunk(
  "deleteCompany",
  async (id: number): Promise<CompanyResponse> => {
    return Api.Company.deleteCompany(id).then(({ data }) => {
      return data;
    });
  }
);

export const putCompany = createAsyncThunk(
  "putCompany",
  async (putCompanyType: PutCompanyType): Promise<CompanyResponse> => {
    const companyRequest: CompanyRequest = { name: putCompanyType.name };
    return Api.Company.updateCompany(
      putCompanyType.companyId,
      companyRequest
    ).then(({ data }) => {
      return data;
    });
  }
);
