import * as React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { Paper } from "@mui/material";
import { Toaster } from "react-hot-toast";
import {
  Controller,
  SubmitHandler,
  useForm,
  ControllerRenderProps,
} from "react-hook-form";

import { TextInput } from "ui-components";
import { ManageWrapper } from "layouts/manageLayout";
import { MainWrapper } from "layouts/mainWrapper/MainWrapper";
import { Dropdown } from "ui-components";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import {
  getBuilding,
  postBuilding,
  putBuilding,
  stopEditing,
} from "../../redux/actions/buildingActions";
import { getCities } from "../../redux/actions/cityActions";
import { FloorList } from "../../components/floors/FloorList";
import {
  BUILDING_WARNING,
  SUCCESS_POST,
  SUCCESS_PUT,
} from "../../constants/messageConstants";
import { handleErrors, handleToast } from "../../utils/helpers";
import { getCityId } from "../../utils/helpers";

type Inputs = {
  name: string;
  address: string;
  city: string;
  latitude: string;
  longitude: string;
};

export const NewBuilding: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { cities } = useAppSelector((state) => state.city);
  const { floors, isEditing } = useAppSelector((state) => state.building);

  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const checkIsThereAnyCoordinates = (
    latitude: string,
    longitude: string
  ): boolean => {
    if (longitude === "" || latitude === "") {
      handleToast("Plsease fill in both fields", "error");
      return false;
    }

    if (isNaN(Number(latitude)) || isNaN(Number(longitude))) {
      handleToast("Longitude and Latitude must be a numbers", "error");
      return false;
    }

    return true;
  };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (id !== undefined) {
      dispatch(
        putBuilding({
          buildingId: Number(id),
          name: data.name.trim(),
          address: data.address.trim(),
          cityId: getCityId(data.city, cities)!,
          floorList: floors!,
          latitude: Number(data.latitude),
          longitude: Number(data.longitude),
        })
      )
        .unwrap()
        .then(() => {
          handleToast(SUCCESS_PUT);
          navigate("/building", { replace: true });
        })
        .catch((res) => {
          handleErrors(res);
        });

      return;
    }
    let longitude: number | null = null;
    let latitude: number | null = null;
    if (data.longitude !== "" || data.latitude !== "") {
      const retVal = checkIsThereAnyCoordinates(data.latitude, data.longitude);
      if (!retVal) return;
      longitude = Number(data.longitude);
      latitude = Number(data.latitude);
    }

    dispatch(
      postBuilding({
        name: data.name.trim(),
        address: data.address.trim(),
        cityId: getCityId(data.city, cities)!,
        latitude: latitude!,
        longitude: longitude!,
      })
    )
      .unwrap()
      .then(() => {
        handleToast(SUCCESS_POST);
        navigate("/building", { replace: true });
      })
      .catch((res) => {
        handleErrors(res);
      });
  };

  const onCancel = () => {
    dispatch(stopEditing());
    navigate("/building", { replace: true });
  };

  const errorName =
    errors.name?.type === "required" ? "Building name is required" : null;

  const errorAddress =
    errors.address?.type === "required" ? "Adress is required" : null;

  const errorCity =
    errors.city?.type === "required" ? "City is required" : null;

  const rulesName = { required: true };
  const rulesAddress = { required: true };
  const rulesCity = { required: true };
  const rulesLongitude = { required: false };
  const rulesLatitude = { required: false };

  const renderName = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "name">;
  }) => {
    return (
      <TextInput
        error={errorName}
        label="Building name"
        placeholder="Enter a building name..."
        {...field}
      />
    );
  };

  const renderAddress = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "address">;
  }) => {
    return (
      <TextInput
        error={errorAddress}
        label="Address"
        placeholder="Enter a building address..."
        {...field}
      />
    );
  };

  const renderLatitude = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "latitude">;
  }) => {
    return (
      <TextInput
        error={null}
        label="Latitude"
        placeholder="Enter a building latitude..."
        {...field}
      />
    );
  };

  const renderLongitude = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "longitude">;
  }) => {
    return (
      <TextInput
        error={null}
        label="Longitude"
        placeholder="Enter a building longitude..."
        {...field}
      />
    );
  };

  const renderCities = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "city">;
  }) => {
    return (
      <Dropdown
        error={errorCity!}
        onChangeSelection={field.onChange}
        label="Select a city"
        placeholder={field.value !== "" ? field.value : "Select a city..."}
        options={cities.map((item) => item.name)}
        {...field}
      />
    );
  };

  const handleFloors = () => {
    if (id !== undefined && floors && floors.length !== 0) {
      return (
        <div className="form__row">
          <Paper className="floors-order">
            <h3 className="floors-order__heading">Floors</h3>
            <ul className="floors-order__list">
              <FloorList
                items={[...floors].reverse()}
                buildingId={Number(id)}
              />
            </ul>
          </Paper>
        </div>
      );
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getCities());
    if (id !== undefined) {
      dispatch(getBuilding(Number(id)))
        .unwrap()
        .then((res) => {
          setValue("name", res?.name!);
          setValue("address", res?.address!);
          setValue("city", res?.city?.name!);
          setValue("longitude", res?.longitude?.toString()!);
          setValue("latitude", res?.latitude?.toString()!);
        })
        .catch((res) => {
          handleErrors(res);
        });
      if (isEditing) handleToast(BUILDING_WARNING, "warning");
    }
  }, [id, setValue, dispatch, isEditing]);

  return (
    <ManageWrapper
      buttonText="Add a new building"
      manageText="a building"
      navigateTo=""
      isButtonVisible={false}
    >
      <Toaster position="top-center" reverseOrder={false} />
      <MainWrapper title="Add a new building">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="form__row">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={rulesName}
              render={renderName}
            />
            <Controller
              name="address"
              control={control}
              defaultValue=""
              rules={rulesAddress}
              render={renderAddress}
            />
            <Controller
              name="city"
              control={control}
              defaultValue=""
              rules={rulesCity}
              render={renderCities}
            />
          </div>
          <div className="form__row">
            <Controller
              name="latitude"
              control={control}
              defaultValue=""
              rules={rulesLatitude}
              render={renderLatitude}
            />
            <Controller
              name="longitude"
              control={control}
              defaultValue=""
              rules={rulesLongitude}
              render={renderLongitude}
            />
          </div>
          {handleFloors()}
          <div className="form__actions">
            <button type="submit" className="form__btn btn btn--green">
              Save
            </button>
            <button
              onClick={onCancel}
              type="button"
              className="form__btn btn btn--red"
            >
              Cancel
            </button>
          </div>
        </form>
      </MainWrapper>
    </ManageWrapper>
  );
};
