import * as React from "react";

import { useNavigate, useParams } from "react-router-dom";
import {
  useForm,
  SubmitHandler,
  Controller,
  ControllerRenderProps,
} from "react-hook-form";
import { Toaster } from "react-hot-toast";

import { ManageWrapper } from "layouts/manageLayout";
import { MainWrapper } from "layouts/mainWrapper/MainWrapper";
import {
  getCountry,
  postCountry,
  putCountry,
} from "../../redux/actions/countryActions";
import { useAppDispatch } from "../../redux/hooks";
import { allCountries } from "../../utils/countries";
import { Dropdown } from "ui-components";
import { SUCCESS_POST, SUCCESS_PUT } from "../../constants/messageConstants";
import { handleErrors, handleToast } from "../../utils/helpers";

type Inputs = {
  name: string;
};

export const NewCountry: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (id !== undefined) {
      dispatch(putCountry({ countryId: Number(id), name: data.name }))
        .unwrap()
        .then(() => {
          handleToast(SUCCESS_PUT);
          navigate(-1);
        })
        .catch((res) => {
          handleErrors(res);
        });
      return;
    }
    dispatch(postCountry({ name: data.name }))
      .unwrap()
      .then(() => {
        handleToast(SUCCESS_POST);
        navigate(-1);
      })
      .catch((res) => {
        handleErrors(res);
      });
  };

  const onCancel = () => {
    navigate(-1);
  };

  const errorName =
    errors.name?.type === "required" ? "Country name is required" : null;

  const rulesName = { required: true };

  const renderName = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "name">;
  }) => {
    return (
      <Dropdown
        error={errorName}
        label="Country"
        onChangeSelection={field.onChange}
        placeholder={
          field.value === undefined ? "Select country..." : field.value
        }
        options={allCountries}
        {...field}
      />
    );
  };

  React.useEffect(() => {
    if (id !== undefined) {
      dispatch(getCountry(Number(id)))
        .unwrap()
        .then((res) => {
          setValue("name", res?.name!);
        });
    }
  }, [dispatch, id, setValue]);

  return (
    <ManageWrapper
      buttonText="Add a new country"
      manageText="a country"
      navigateTo=""
      isButtonVisible={false}
    >
      <MainWrapper title="Add a new country">
        <Toaster position="top-center" reverseOrder={false} />
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form__row">
            <Controller
              name="name"
              control={control}
              rules={rulesName}
              render={renderName}
            />
          </div>
          <div className="form__actions">
            <button type="submit" className="form__btn btn btn--green">
              Save
            </button>
            <button
              onClick={onCancel}
              type="button"
              className="form__btn btn btn--red"
            >
              Cancel
            </button>
          </div>
        </form>
      </MainWrapper>
    </ManageWrapper>
  );
};
