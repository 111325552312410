import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  RoomResponse,
  OfficeRoomRequest,
  ConferenceRoomRequest,
  OfficeRoomRequestTypeEnum,
} from "../../api/generated";
import { Api } from "../../api";
import { PostRoomType, PutRoomType } from "../../types/RoomTypes";

export const getRooms = createAsyncThunk(
  "getRooms",
  async (): Promise<RoomResponse[]> => {
    return Api.Room.getAllRooms().then(({ data }) => {
      return data;
    });
  }
);
export const getRoom = createAsyncThunk(
  "getRoom",
  async (id: number): Promise<RoomResponse> => {
    return Api.Room.getRoomById(id).then(({ data }) => {
      return data;
    });
  }
);
export const postRoom = createAsyncThunk(
  "postRoom",
  async (postRoomType: PostRoomType): Promise<RoomResponse> => {
    if (postRoomType.type === "OfficeRoom") {
      const roomRequest: OfficeRoomRequest = {
        id: 0,
        name: postRoomType.name,
        floorId: postRoomType.floorId,
        type: OfficeRoomRequestTypeEnum.Office,
      };

      return Api.Room.save(roomRequest).then(({ data }) => {
        return data;
      });
    }
    const confRequest: ConferenceRoomRequest = {
      id: 0,
      name: postRoomType.name,
      floorId: postRoomType.floorId,
      type: OfficeRoomRequestTypeEnum.Conference,
    };
    return Api.Room.save1(confRequest).then(({ data }) => {
      return data;
    });
  }
);
export const putRoom = createAsyncThunk(
  "putRoom",
  async (putRoomType: PutRoomType): Promise<RoomResponse> => {
    if (putRoomType.type === "OfficeRoom") {
      const roomRequest: OfficeRoomRequest = {
        id: putRoomType.roomId,
        name: putRoomType.name,
        floorId: putRoomType.floorId,
        type: OfficeRoomRequestTypeEnum.Office,
      };

      return Api.Room.update(putRoomType.roomId, roomRequest).then(
        ({ data }) => {
          return data;
        }
      );
    }

    const confRequest: ConferenceRoomRequest = {
      id: putRoomType.roomId,
      name: putRoomType.name,
      floorId: putRoomType.floorId,
      type: OfficeRoomRequestTypeEnum.Conference,
    };
    return Api.Room.update1(putRoomType.roomId, confRequest).then(
      ({ data }) => {
        return data;
      }
    );
  }
);
export const deleteRoom = createAsyncThunk(
  "deleteRoom",
  async (id: number): Promise<RoomResponse> => {
    return Api.Room.deleteRoom(id).then(({ data }) => {
      return data;
    });
  }
);
