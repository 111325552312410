import { createSlice } from "@reduxjs/toolkit";

import { FloorState } from "../states/FloorState";
import {
  getFloors,
  getFloor,
  getSvg,
  postFloor,
  deleteFloor,
  putFloor,
} from "../actions/floorActions";

const initialState: FloorState = {
  floors: [],
  isLoading: false,
  success: false,
};

const FloorSlice = createSlice({
  name: "Floor",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFloors.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getFloors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.floors = action.payload;
      })
      .addCase(getFloors.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getFloor.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getFloor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(getFloor.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getSvg.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getSvg.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(getSvg.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(postFloor.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(postFloor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(postFloor.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(deleteFloor.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(deleteFloor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteFloor.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(putFloor.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(putFloor.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(putFloor.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      });
  },
});

export default FloorSlice.reducer;
