import { MenuItem } from "components/menuItem";
import {
  // Building,
  City,
  Company,
  Country,
  Employee,
  Floor,
  Room,
  Card,
} from "components/icons";
import { ReactComponent as Building } from "./building.svg";

export const Dashboard = () => {
  const dashboardItemConfigs = [
    {
      lightText: "Manage",
      strongText: "a company",
      icon: <Company />,
      to: "/company",
    },
    {
      lightText: "Manage",
      strongText: "an employee",
      icon: <Employee />,
      to: "/employee",
    },
    {
      lightText: "Manage",
      strongText: "a floor",
      icon: <Floor />,
      to: "/floor",
    },
    {
      lightText: "Manage",
      strongText: "a building",
      icon: <Building />,
      to: "/building",
    },
    {
      lightText: "Manage",
      strongText: "a city",
      icon: <City />,
      to: "/city",
    },
    {
      lightText: "Manage",
      strongText: "a country",
      icon: <Country />,
      to: "/country",
    },
    {
      lightText: "Manage",
      strongText: "a room",
      icon: <Room />,
      to: "/room",
    },
    {
      lightText: "Manage",
      strongText: "a token",
      icon: <Card />,
      to: "/token",
    },
  ];

  const DashboardItems = () => {
    const menuList = dashboardItemConfigs.map(
      ({ lightText, strongText, icon, to }, i) => (
        <MenuItem
          key={i}
          lightText={lightText}
          strongText={strongText}
          icon={icon}
          to={to}
        />
      )
    );
    return <>{menuList}</>;
  };

  return (
    <main className="main">
      <div className="dashboard">
        <h1 className="dashboard__title">What do you want to do?</h1>
        <ul className="dashboard__list">
          <DashboardItems />
        </ul>
      </div>
    </main>
  );
};
