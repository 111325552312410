import { createAsyncThunk } from "@reduxjs/toolkit";

import { CountryRequest, CountryResponse } from "../../api/generated/index";
import { Api } from "../../api/index";
import { PostCountryType } from "../../types/CountryType";
import { PutCountryType } from "../../types/CountryType";

export const getCountries = createAsyncThunk(
  "getCountries",
  async (): Promise<CountryResponse[]> => {
    return Api.Country.getAllCountries().then(({ data }) => {
      return data;
    });
  }
);

export const getCountry = createAsyncThunk(
  "getCountry",
  async (id: number): Promise<CountryResponse> => {
    return Api.Country.getCountryById(id).then(({ data }) => {
      return data;
    });
  }
);

export const postCountry = createAsyncThunk(
  "postCountry",
  async (postCountryType: PostCountryType): Promise<CountryResponse> => {
    const countryRequest: CountryRequest = {
      name: postCountryType.name,
    };
    return Api.Country.saveCountry(countryRequest).then(({ data }) => {
      return data;
    });
  }
);

export const deleteCountry = createAsyncThunk(
  "deleteCountry",
  async (id: number): Promise<CountryResponse> => {
    return Api.Country.deleteCountry(id).then(({ data }) => {
      return data;
    });
  }
);

export const putCountry = createAsyncThunk(
  "putCountry",
  async (putCountryType: PutCountryType): Promise<CountryResponse> => {
    const countryRequest: CountryRequest = {
      name: putCountryType.name,
    };
    return Api.Country.updateCountry(
      putCountryType.countryId,
      countryRequest
    ).then(({ data }) => {
      return data;
    });
  }
);
