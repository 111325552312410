import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  EmployeeResponse,
  EmployeeRequest,
  EmployeeUpdateRequest,
  EmployeeUpdateRequestRoleEnum,
  EmployeeDetailsWithReservations,
  WorkspaceUpdatesResponse,
} from "../../api/generated";
import { Api } from "../../api";
import {
  PostEmployeeType,
  PutEmployeeType,
  AssignWorkstationType,
} from "../../types/EmployeeTypes";

export const getEmployees = createAsyncThunk(
  "getEmployees",
  async (): Promise<EmployeeResponse[]> => {
    return Api.Employee.getAllEmployees().then(({ data }) => {
      return data;
    });
  }
);

export const getEmployeesDetails = createAsyncThunk(
  "getEmployeesDetails",
  async (): Promise<EmployeeDetailsWithReservations[]> => {
    return Api.Employee.getAllEmployeesDetails().then(({ data }) => {
      return data;
    });
  }
);

export const getEmployee = createAsyncThunk(
  "getEmployee",
  async (id: number): Promise<EmployeeResponse> => {
    return Api.Employee.getEmployeeById(id).then(({ data }) => {
      return data;
    });
  }
);

export const getWorkstationUpdatesForEmployee = createAsyncThunk(
  "getWorkstationUpdatesForEmployee",
  async (id: number): Promise<WorkspaceUpdatesResponse[]> => {
    return Api.Employee.getWorkstationUpdatesForEmployeeId(id).then(({ data }) => {
      return data;
    });
  }
);

export const getRoles = createAsyncThunk("getRoles", async (): Promise<string[]> => {
  return Api.Employee.getRoles().then(({ data }) => {
    return data;
  });
});

export const syncEmployees = createAsyncThunk("syncEmployees", async () => {
  Api.Employee.saveEmployee1().then(({ data }) => {
    return data;
  });
});

export const postEmployee = createAsyncThunk(
  "postEmployee",
  async (postEmployeeType: PostEmployeeType): Promise<EmployeeResponse> => {
    const employeeRequest: EmployeeRequest = {
      name: postEmployeeType.employeeName,
      username: postEmployeeType.username,
      email: postEmployeeType.email,
      additionalNote: postEmployeeType.additionalNote,
      companyId: postEmployeeType.companyId,
      status: postEmployeeType.employeeStatus,
      reasonForDeactivation: postEmployeeType.reasonForDeactivation,
      accessControlNumber: postEmployeeType.accessControlNumber,
    };

    return Api.Employee.saveEmployee(employeeRequest).then(({ data }) => {
      return data;
    });
  }
);

export const putEmployee = createAsyncThunk(
  "putEmployee",
  async (putEmployeeType: PutEmployeeType): Promise<EmployeeResponse> => {
    const role: EmployeeUpdateRequestRoleEnum =
      putEmployeeType.role as EmployeeUpdateRequestRoleEnum;
    const employeeRequest: EmployeeUpdateRequest = {
      name: putEmployeeType.employeeName,
      username: putEmployeeType.username,
      email: putEmployeeType.email,
      additionalNote: putEmployeeType.additionalNote,
      companyId: putEmployeeType.companyId,
      status: putEmployeeType.employeeStatus,
      reasonForDeactivation: putEmployeeType.reasonForDeactivation,
      role: role,
      accessControlNumber: putEmployeeType.accessControlNumber,
    };

    return Api.Employee.updateEmployee(putEmployeeType.employeeId, employeeRequest).then(
      ({ data }) => {
        return data;
      }
    );
  }
);

export const syncLdapEmployee = createAsyncThunk(
  "syncLdapEmployee",
  async (id: number): Promise<EmployeeResponse> => {
    return Api.Employee.syncLdapUser(id).then(({ data }) => {
      return data;
    });
  }
);

export const deleteEmployee = createAsyncThunk(
  "deleteEmployee",
  async (id: number): Promise<EmployeeResponse> => {
    return Api.Employee.deleteEmployee(id).then(({ data }) => {
      return data;
    });
  }
);

export const assignWorkstationToEmployee = createAsyncThunk(
  "assignWorkstationToEmployee",
  async (assignWorkstationType: AssignWorkstationType): Promise<EmployeeResponse> => {
    return Api.Employee.assignEmployeeToWorkstation(
      assignWorkstationType.workstationId,
      assignWorkstationType.employeeId
    ).then(({ data }) => {
      return data;
    });
  }
);

export const unAssignWorkstationEmployee = createAsyncThunk(
  "unAssignWorkstationEmployee",
  async (workstationId: string): Promise<boolean> => {
    return Api.Employee.unassignEmployeeToWorkstation(workstationId).then(({ data }) => {
      return data;
    });
  }
);

export const enableEmployee = createAsyncThunk(
  "enableEmployee",
  async (username: string): Promise<boolean> => {
    return Api.Employee.enableEmployee(username).then(({ data }) => {
      return data;
    });
  }
);

export const sendSetPasswordEmails = createAsyncThunk("sendSetPasswordEmails", async () =>
  Api.Employee.sendSetPasswordEmails()
);
