export const Building = () => {
  return (
    <svg
      className="dashboard__image"
      width="60"
      height="60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M58.044 56.088H46.956V13.044h1.956a1.956 1.956 0 0 0 0-3.912h-5.868v-5.22H45A1.956 1.956 0 0 0 45 0H13.692a1.956 1.956 0 0 0 0 3.912h1.956v5.22H9.78a1.956 1.956 0 0 0 0 3.912h1.956v43.044h-9.78a1.956 1.956 0 0 0 0 3.912h56.088a1.956 1.956 0 0 0 0-3.912ZM19.56 3.912h19.56v5.22H19.56v-5.22Zm23.484 52.176H15.648V13.044h27.396v43.044Z"
        fill="#F05A28"
      />
      <path
        d="M23.472 24.78H19.56v3.912h3.912V24.78ZM23.472 32.604H19.56v3.912h3.912v-3.912ZM23.472 40.44H19.56v3.912h3.912V40.44ZM23.472 48.264H19.56v3.912h3.912v-3.912ZM31.308 24.78h-3.912v3.912h3.912V24.78ZM31.308 32.604h-3.912v3.912h3.912v-3.912ZM31.308 40.44h-3.912v3.912h3.912V40.44ZM31.308 48.264h-3.912v3.912h3.912v-3.912ZM39.132 24.78H35.22v3.912h3.912V24.78ZM23.472 16.956H19.56v3.912h3.912v-3.912ZM31.308 16.956h-3.912v3.912h3.912v-3.912ZM39.132 16.956H35.22v3.912h3.912v-3.912ZM39.132 32.604H35.22v3.912h3.912v-3.912ZM39.132 40.44H35.22v3.912h3.912V40.44ZM39.132 48.264H35.22v3.912h3.912v-3.912Z"
        fill="#F05A28"
      />
    </svg>
  );
};
