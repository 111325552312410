import * as React from "react";

import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Modal } from "../../components/modal/Modal";
import { getFloors, deleteFloor } from "../../redux/actions/floorActions";
import { useAppDispatch } from "../../redux/hooks";
import { FloorWithBuildingInfoResponse } from "../../api/generated/index";
import { SUCCESS_DELETE } from "../../constants/messageConstants";
import { handleToast } from "../../utils/helpers";

interface FloorTableProps {
  floors: FloorWithBuildingInfoResponse[];
}

export const FloorTable: React.FC<FloorTableProps> = ({ floors }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentItem = React.useRef<FloorWithBuildingInfoResponse | null>(null);
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [searchList, setSearchList] =
    React.useState<FloorWithBuildingInfoResponse[]>(floors);

  const handleOpen = (item: FloorWithBuildingInfoResponse) => {
    setShowDeletePopup(true);
    currentItem.current = item;
  };
  const handleClose = () => {
    setShowDeletePopup(false);
  };

  const onDelete = (id: number) => {
    dispatch(deleteFloor(id))
      .unwrap()
      .then(() => {
        dispatch(getFloors());
        handleToast(SUCCESS_DELETE);
      });
    handleClose();
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    if (value === "") {
      setSearchList(floors);
      return;
    }
    setSearchList(
      floors.filter((item) =>
        item.nameForAdmins
          ?.toLowerCase()
          .includes(value.toLocaleLowerCase().trim())
      )
    );
  };

  const onCancel = () => {
    handleClose();
  };

  const onAdd = (id: number) => {
    navigate(`new/${id}`);
  };

  React.useEffect(() => {
    setSearchList(floors);
  }, [floors]);

  return (
    <>
      <Toaster position="top-center" reverseOrder={true} />
      <h2 className="manager__title manager__title--compact">
        <span className="manager__title-text">List of added floors</span>

        <input
          className="manager__title-input"
          type="text"
          placeholder="Search by admin name..."
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </h2>
      <div className="manager__content">
        <table className="table">
          <thead>
            <tr className="table__row">
              <th className="table__head">Name for admins</th>
              <th className="table__head">Name for users</th>
              <th className="table__head">Building</th>
              <th className="table__head">Level</th>
              <th className="table__head table__head--action">Edit</th>
              <th className="table__head table__head--action">Delete</th>
            </tr>
          </thead>
          <tbody>
            {searchList.map((item) => {
              return (
                <tr className="table__row" key={item.id}>
                  <td className="table__cell">{item.nameForAdmins}</td>
                  <td className="table__cell">{item.nameForUsers}</td>
                  <td className="table__cell">{item.building?.name}</td>
                  <td className="table__cell">{item.level}</td>

                  <td className="table__cell">
                    <button
                      onClick={() => onAdd(item.id!)}
                      className="btn btn--green"
                    >
                      Edit
                    </button>
                  </td>
                  <td className="table__cell">
                    <button
                      onClick={() => handleOpen(item)}
                      className="btn btn--red"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal show={showDeletePopup} handleClose={handleClose}>
        <h2 className="popup__heading-plain">
          Are you sure you want to delete this floor?
        </h2>
        <div className="popup__actions">
          <button
            onClick={() => onDelete(currentItem.current?.id!)}
            type="button"
            className="btn btn--green popup__btn"
          >
            Delete
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="btn btn--red popup__btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
