import * as React from "react";

import { ManageWrapper } from "layouts/manageLayout";
import { EmptyListPlaceholder } from "components/emptyListPlaceholder";
import { AdminTable } from "./index";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { Loader } from "../../components/loader/Loader";
import { getAdmins } from "../../redux/actions/adminActions";

export const Admin: React.FC = () => {
  const dispatch = useAppDispatch();
  const { admins, isLoading } = useAppSelector((state) => state.admin);

  const handleView = () => {
    if (admins.length !== 0) {
      return <AdminTable admins={admins} />;
    }

    return <EmptyListPlaceholder />;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getAdmins());
  }, [dispatch]);

  return (
    <ManageWrapper
      buttonText="Add a new admin"
      manageText="admins"
      navigateTo="/admin/new"
      isButtonVisible={false}
    >
      {handleLoader()}
      {handleView()}
    </ManageWrapper>
  );
};
