import { combineReducers, configureStore } from "@reduxjs/toolkit";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import CompanyReducer from "./slices/companySlice";
import AuthReducer from "./slices/authSlice";
import CountryReducer from "./slices/contrySlice";
import CityReducer from "./slices/citySlice";
import BuildingReducer from "./slices/buildingSlice";
import FloorReducer from "./slices/floorSlice";
import EmployeeReducer from "./slices/employeeSlice";
import EmployeeDetailsReducer from "./slices/employeeDetailsSlice";
import AdminReducer from "./slices/adminSlice";
import RoomReducer from "./slices/roomSlice";
import WorkstationReducer from "./slices/workstationSlice";
import StatisticsReducer from "./slices/statisticsSlice";
import AccessControlCard from "./slices/accessControlCardSlice";
import WorkstationStatisticsReducer from './slices/workstationStatisticsSlice';

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const rootReducer = combineReducers({
  company: CompanyReducer,
  country: CountryReducer,
  city: CityReducer,
  building: BuildingReducer,
  floor: FloorReducer,
  employee: EmployeeReducer,
  employeeDetails: EmployeeDetailsReducer,
  admin: AdminReducer,
  room: RoomReducer,
  workstation: WorkstationReducer,
  auth: AuthReducer,
  statistics: StatisticsReducer,
  workstationStatistics: WorkstationStatisticsReducer,
  accessControlCard: AccessControlCard,
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk as ThunkMiddleware],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
