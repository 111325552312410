import * as React from "react";

import { Room, Floor } from "./index";

interface StatisticByBuildingFormRoomsProps {
  offices: Room[];
  confs: Room[];
  floor: Floor;
  handleOfficeChange: (floorId: number, officeId: number) => void;
  handleConfChange: (floorId: number, officeId: number) => void;
  handleSelectAll: (floorId: number) => void;
  handleSelectNone: (floorId: number) => void;
}

export const StatisticByBuildingFormRooms: React.FC<
  StatisticByBuildingFormRoomsProps
> = ({
  offices,
  confs,
  floor,
  handleOfficeChange,
  handleConfChange,
  handleSelectAll,
  handleSelectNone,
}) => {
  return (
    <>
      <div className="stats-panel__floor-actions">
        <div
          className="stats-panel__radio"
          onClick={() => handleSelectAll(floor.id)}
        >
          <input
            type="radio"
            checked={floor.selectAll}
            onChange={() => {}}
            id="radio3"
            name="selectAllFloor2"
            className="stats-panel__radio-input"
          />
          <label className="stats-panel__radio-label">Select all</label>
        </div>
        <div
          className="stats-panel__radio"
          onClick={() => handleSelectNone(floor.id)}
        >
          <input
            type="radio"
            checked={floor.selectNone}
            onChange={() => {}}
            id="radio4"
            name="selectAllFloor2"
            className="stats-panel__radio-input"
          />
          <label className="stats-panel__radio-label">Select none</label>
        </div>
      </div>
      <div className="stats-panel__office-holder">
        <h3 className="stats-panel__office-title">Offices:</h3>
        {offices?.map((office) => (
          <div
            className="stats-panel__office"
            key={office.item.id}
            onClick={() => handleOfficeChange(floor.id, office.item.id!)}
          >
            <input
              type="checkbox"
              checked={office.checked}
              onChange={() => {}}
              id="triton"
              className="stats-panel__office-input"
            />
            <label className="stats-panel__office-label">
              {office.item.name}
            </label>
          </div>
        ))}
      </div>
    </>
  );
};
