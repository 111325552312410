import * as React from "react";

import {
  Controller,
  SubmitHandler,
  useForm,
  ControllerRenderProps,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import { TextInput } from "ui-components";
import { ManageWrapper } from "layouts/manageLayout";
import { MainWrapper } from "layouts/mainWrapper/MainWrapper";
import {
  postCompany,
  putCompany,
  getCompany,
} from "../../redux/actions/companyActions";
import { useAppDispatch } from "../../redux/hooks";
import { SUCCESS_POST, SUCCESS_PUT } from "../../constants/messageConstants";
import { handleToast, handleErrors } from "../../utils/helpers";

type Input = {
  name: string;
};

export const NewCompany: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    setValue,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<Input>();

  const onSubmit: SubmitHandler<Input> = (data) => {
    if (id !== undefined) {
      dispatch(putCompany({ name: data.name.trim(), companyId: Number(id) }))
        .unwrap()
        .then(() => {
          handleToast(SUCCESS_PUT);
          navigate(-1);
        })
        .catch((res) => {
          handleErrors(res);
        });
      return;
    }
    dispatch(postCompany({ name: data.name.trim() }))
      .unwrap()
      .then(() => {
        handleToast(SUCCESS_POST);
        navigate(-1);
      })
      .catch((res) => {
        handleErrors(res);
      });
  };

  const onCancel = () => {
    navigate(-1);
  };

  const errorName =
    errors.name?.type === "required" ? "Company name is required" : null;

  const rulesName = { required: true };

  const renderName = ({
    field,
  }: {
    field: ControllerRenderProps<Input, "name">;
  }) => {
    return (
      <TextInput
        error={errorName}
        label="Company name"
        placeholder="Enter a company name..."
        {...field}
      />
    );
  };

  React.useEffect(() => {
    if (id !== undefined) {
      dispatch(getCompany(Number(id)))
        .unwrap()
        .then((res) => {
          setValue("name", res?.name!);
        })
        .catch((res) => {
          handleErrors(res);
        });
    }
  }, [id, dispatch, setValue]);

  return (
    <ManageWrapper
      buttonText="Add a new company"
      manageText="a company"
      navigateTo=""
      isButtonVisible={false}
    >
      <MainWrapper title="Add a new company">
        <form onSubmit={handleSubmit(onSubmit)} className="form">
          <div className="form__row">
            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={rulesName}
              render={renderName}
            />
          </div>
          <div className="form__actions">
            <button type="submit" className="form__btn btn btn--green">
              Save
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="form__btn btn btn--red"
            >
              Cancel
            </button>
          </div>
        </form>
      </MainWrapper>
    </ManageWrapper>
  );
};
