import { ReplacementTokenResponse } from "api/generated";
import ConfirmationModal from "components/modal/ConfirmationModal";
import React, { useState } from "react";

type Props = {
  item: ReplacementTokenResponse;
  onEdit: (item: ReplacementTokenResponse) => void;
  onDelete: (item: ReplacementTokenResponse) => Promise<void>;
};

export const ReplacementTokenRow = ({ item, onDelete, onEdit }: Props) => {
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const openDeleteCofirmationModal = () => setShowDeleteConfirmationModal(true);
  const closeDeleteCofirmationModal = () => setShowDeleteConfirmationModal(false);

  const handleDeleteCofirmation = () => {
    onDelete(item);
    closeDeleteCofirmationModal();
  };

  const handleEdit = () => {
    onEdit(item);
  };

  return (
    <tr className="table__row" key={item.id} style={{ marginBottom: 20 }}>
      <td className="table__cell">{new Date(item.date ?? "").toLocaleDateString("sr-RS")}</td>
      <td className="table__cell">{item.accessControlNumber}</td>
      <td className="table__cell">{item.actionPerformedByEmployeeName}</td>
      <td className="table__cell">
        <button onClick={handleEdit} type="button" className="form__btn btn btn--green">
          Edit
        </button>
        <button
          onClick={openDeleteCofirmationModal}
          type="button"
          className="form__btn btn btn--red"
        >
          Delete
        </button>

        <ConfirmationModal
          show={showDeleteConfirmationModal}
          handleClose={closeDeleteCofirmationModal}
          handleConfirm={handleDeleteCofirmation}
        >
          Are you sure you want to delete token replacement?
        </ConfirmationModal>
      </td>
    </tr>
  );
};
