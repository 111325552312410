import * as React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { AuthenticatedRoute } from "pages/authenticatedRoute";
import { Dashboard } from "pages/dashboard";
import { Statistics } from "pages/statistics";
import { NotFound } from "pages/notFound";
import { Floor, NewFloor } from "pages/floor/index";
import { Admin, NewAdmin } from "pages/admin/index";
import { Company, NewCompany } from "pages/company/index";
import { Building, NewBuilding } from "pages/building/index";
import { City, NewCity } from "pages/city/index";
import { Country, NewCountry } from "pages/country";
import { Employee, NewEmployee } from "pages/employee";
import { Room, NewRoom } from "pages/room";
import { Login } from "pages/login";
import { NavLayout } from "layouts/nav";
import { General } from "pages/general";
import { StatisticByCompanies } from "pages/statisticByCompanies";
import { StatisticByBuildings } from "pages/statisticsByBuildings";
import { StatisticsByEmployees } from "pages/statisticsByEmployees";
import { AccessControlCard, NewAccessControlCard } from "pages/accessControlCard";
import "./config/interceptors";
import "./App.css";
import { StatisticsByAccessControl } from "pages/statisticsByAccessControl";

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Toaster position="top-center" reverseOrder={false} />
      <Routes>
        <Route path="/" element={<NavLayout />}>
          <Route
            path="/"
            element={
              <AuthenticatedRoute>
                <Dashboard />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/floor"
            element={
              <AuthenticatedRoute>
                <Floor />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/floor/new"
            element={
              <AuthenticatedRoute>
                <NewFloor />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/floor/new/:id"
            element={
              <AuthenticatedRoute>
                <NewFloor />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/building/:buildingId/floor/:id"
            element={
              <AuthenticatedRoute>
                <NewFloor />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/admin"
            element={
              <AuthenticatedRoute>
                <Admin />
              </AuthenticatedRoute>
            }
          />
          {/*<Route*/}
          {/*  path="/admin/new"*/}
          {/*  element={*/}
          {/*    <AuthenticatedRoute>*/}
          {/*      <NewAdmin />*/}
          {/*    </AuthenticatedRoute>*/}
          {/*  }*/}
          {/*/>*/}
          {/*<Route*/}
          {/*  path="/admin/new/:id"*/}
          {/*  element={*/}
          {/*    <AuthenticatedRoute>*/}
          {/*      <NewAdmin />*/}
          {/*    </AuthenticatedRoute>*/}
          {/*  }*/}
          {/*/>*/}
          <Route
            path="/company"
            element={
              <AuthenticatedRoute>
                <Company />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/company/new"
            element={
              <AuthenticatedRoute>
                <NewCompany />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/company/new/:id"
            element={
              <AuthenticatedRoute>
                <NewCompany />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/building"
            element={
              <AuthenticatedRoute>
                <Building />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/building/new"
            element={
              <AuthenticatedRoute>
                <NewBuilding />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/building/new/:id"
            element={
              <AuthenticatedRoute>
                <NewBuilding />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/city"
            element={
              <AuthenticatedRoute>
                <City />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/city/new"
            element={
              <AuthenticatedRoute>
                <NewCity />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/city/new/:id"
            element={
              <AuthenticatedRoute>
                <NewCity />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/country"
            element={
              <AuthenticatedRoute>
                <Country />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/country/new"
            element={
              <AuthenticatedRoute>
                <NewCountry />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/country/new/:id"
            element={
              <AuthenticatedRoute>
                <NewCountry />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/employee"
            element={
              <AuthenticatedRoute>
                <Employee />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/employee/new"
            element={
              <AuthenticatedRoute>
                <NewEmployee />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/employee/new/:id"
            element={
              <AuthenticatedRoute>
                <NewEmployee />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="room"
            element={
              <AuthenticatedRoute>
                <Room />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="room/new"
            element={
              <AuthenticatedRoute>
                <NewRoom />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="room/new/:id"
            element={
              <AuthenticatedRoute>
                <NewRoom />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/statistics"
            element={
              <AuthenticatedRoute>
                <Statistics />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/statistics/companies"
            element={
              <AuthenticatedRoute>
                <StatisticByCompanies />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/statistics/buildings"
            element={
              <AuthenticatedRoute>
                <StatisticByBuildings />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/statistics/employees"
            element={
              <AuthenticatedRoute>
                <StatisticsByEmployees />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/statistics/acs"
            element={
              <AuthenticatedRoute>
                <StatisticsByAccessControl />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/token"
            element={
              <AuthenticatedRoute>
                <AccessControlCard />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/token/new"
            element={
              <AuthenticatedRoute>
                <NewAccessControlCard />
              </AuthenticatedRoute>
            }
          />
          <Route
            path="/token/new/:id"
            element={
              <AuthenticatedRoute>
                <NewAccessControlCard />
              </AuthenticatedRoute>
            }
          />
        </Route>
        <Route path="login" element={<Login />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
