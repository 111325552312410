import { AxiosError } from "axios";

export const withAxiosErrorHandling = (error: AxiosError) => {
  if (error.response) {
    // Extract the error message from the response
    throw new Error(error.response.data);
  } else if (error.request) {
    throw new Error("No response received from server.");
  } else {
    throw new Error(error.message);
  }
};
