import * as React from "react";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getCompaniesStatistics } from "../../redux/actions/statisticsActions";
import {
  StatisticByCompaniesHeader,
  StatisticByCompaniesSidebar,
} from "./index";
import { Loader } from "../../components/loader/Loader";

export const StatisticByCompanies: React.FC = () => {
  const dispatch = useAppDispatch();
  const { companyStatistics, isLoading } = useAppSelector(
    (state) => state.statistics
  );

  const handleoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getCompaniesStatistics());
  }, [dispatch]);

  return (
    <main className="main">
      {handleoader()}
      <StatisticByCompaniesHeader />
      <div className="stats">
        <div className="wrap stats__wrap">
          <StatisticByCompaniesSidebar companies={companyStatistics} />
        </div>
      </div>
    </main>
  );
};
