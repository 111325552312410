import { createAsyncThunk } from "@reduxjs/toolkit";

import { Api } from "../../api/index";
import { FloorWithBuildingInfoResponse } from "../../api/generated/index";
import { PostFloorType, PutFloorType } from "../../types/FloorTypes";

export const getFloors = createAsyncThunk(
  "getFloors",
  async (): Promise<FloorWithBuildingInfoResponse[]> => {
    return Api.Floor.getAllFloors().then(({ data }) => {
      return data;
    });
  }
);

export const getFloor = createAsyncThunk(
  "getFloor",
  async (id: number): Promise<FloorWithBuildingInfoResponse> => {
    return Api.Floor.getFloorById(id).then(({ data }) => {
      return data;
    });
  }
);

export const getSvg = createAsyncThunk("getSvg", async (name: string) => {
  return Api.Floor.getSVGById(name).then(({ data }) => {
    return data;
  });
});

export const postFloor = createAsyncThunk(
  "postFloor",
  async (
    postFloorType: PostFloorType
  ): Promise<FloorWithBuildingInfoResponse> => {
    return Api.Floor.saveFloor(
      0,
      postFloorType.adminName,
      postFloorType.displayName,
      postFloorType.level,
      postFloorType.svg,
      postFloorType.buildingId,
      { headers: { "Content-Type": "multipart/form-data" } }
    ).then(({ data }) => {
      return data;
    });
  }
);

export const deleteFloor = createAsyncThunk(
  "deleteFloor",
  async (id: number): Promise<FloorWithBuildingInfoResponse> => {
    return Api.Floor.deleteFloor(id).then(({ data }) => {
      return data;
    });
  }
);

export const putFloor = createAsyncThunk(
  "putFloor",
  async (
    putFloorType: PutFloorType
  ): Promise<FloorWithBuildingInfoResponse> => {
    return Api.Floor.updateFloor(
      putFloorType.floorId,
      0,
      putFloorType.adminName,
      putFloorType.displayName,
      putFloorType.level,
      putFloorType.svg,
      putFloorType.buildingId,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    ).then(({ data }) => {
      return data;
    });
  }
);

export const putWorkstationsInFloor = createAsyncThunk(
  "putWorkstationsInFloor",
  async (putFloorType: PutFloorType) => {
    Api.Floor.updateWorkstationsInFloor(
      putFloorType.floorId,
      putFloorType.workstations
    );
  }
);
