export const Room = () => {
  return (
    <svg
      className="dashboard__image"
      width="60"
      height="60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fill="#F05A28"
          d="M8.6,48h42.6c0.6,0,1.3-0.5,1.3-1.3v-13c0-0.6-0.5-1.3-1.3-1.3H45c0.6-2.9,3.3-5.1,6.3-5.1
		c0.6,0,1.3-0.5,1.3-1.3V14.9c0-0.8-0.7-1.3-1.3-1.3H8.6c-0.6,0-1.3,0.5-1.3,1.3v31.7C7.3,47.3,7.8,48,8.6,48z M43.7,34.9H50v10.5
		H38.7v-2.7c0-0.6-0.5-1.3-1.3-1.3h-6.3c0.6-2.9,3.3-5.1,6.3-5.1c0.6,0,1.3-0.5,1.3-1.3V16.2H50v8.6c-4.4,0.7-7.7,4.4-7.7,8.9
		C42.3,34.2,42.9,34.9,43.7,34.9z M36.1,29H22.5c-0.7-4.4-4.4-7.7-8.9-7.7c-0.6,0-1.3,0.5-1.3,1.3V29H9.9V16.2h26.2V29z M14.8,30.3
		v-6.2c2.9,0.6,5.1,3.3,5.1,6.3c0,0.6,0.5,1.3,1.3,1.3h14.8v1.9c-4.4,0.7-7.7,4.4-7.7,8.9c0,0.6,0.5,1.3,1.3,1.3h6.4v1.4H9.9V31.6
		h3.6C14.1,31.6,14.8,31.1,14.8,30.3z"
        />
        <path
          fill="#F05A28"
          d="M59.6,8.1c-0.1-0.6-0.6-1.3-1.4-1.3H1.7c-0.6,0-1.3,0.5-1.3,1.3v43.6c0,0.6,0.5,1.3,1.3,1.3h56.6
		c0.6,0,1.3-0.5,1.3-1.3L59.6,8.1z M56.9,9.4v41.1H3V9.4H56.9z"
        />
      </g>
    </svg>
  );
};
