import { createSlice } from "@reduxjs/toolkit";

import { WorkstationState } from "../states/WorkStationState";
import {
  postWorkstation,
  deleteWorkstation,
} from "../actions/workstationActions";

const initialState: WorkstationState = {
  workstations: [],
  isLoading: false,
  success: false,
};

const WorkstationSlice = createSlice({
  name: "Workstation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postWorkstation.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(postWorkstation.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(postWorkstation.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(deleteWorkstation.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(deleteWorkstation.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteWorkstation.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      });
  },
});

export default WorkstationSlice.reducer;
