import { createAsyncThunk } from "@reduxjs/toolkit";

import { WorkstationRequest, WorkstationResponse } from "../../api/generated";
import { Api } from "../../api/index";
import { PostWorkstationType } from "../../types/WorkstationTypes";

export const postWorkstation = createAsyncThunk(
  "postWorkstation",
  async (
    postWorkstationType: PostWorkstationType
  ): Promise<WorkstationResponse> => {
    const workstationRequest: WorkstationRequest = {
      id: postWorkstationType.workstationId,
      roomId: postWorkstationType.roomId,
    };
    return Api.WorkStation.saveWorkstation(workstationRequest).then(
      ({ data }) => {
        return data;
      }
    );
  }
);

export const deleteWorkstation = createAsyncThunk(
  "deleteWorkstation",
  async (id: string): Promise<WorkstationResponse> => {
    return Api.WorkStation.deleteWorkstation(id).then(({ data }) => {
      return data;
    });
  }
);
