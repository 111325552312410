import { createSlice } from "@reduxjs/toolkit";

import { RoomState } from "../states/RoomState";
import {
  getRooms,
  postRoom,
  putRoom,
  deleteRoom,
  getRoom,
} from "../actions/roomActions";

const initialState: RoomState = {
  rooms: [],
  isLoading: false,
  success: false,
};

const RoomSlice = createSlice({
  name: "Room",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRooms.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getRooms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.rooms = action.payload;
      })
      .addCase(getRooms.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getRoom.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getRoom.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(getRoom.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(postRoom.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(postRoom.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(postRoom.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(putRoom.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(putRoom.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(putRoom.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(deleteRoom.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(deleteRoom.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteRoom.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      });
  },
});

export default RoomSlice.reducer;
