import * as React from "react";

import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Modal } from "../../components/modal/Modal";
import {
  getEmployees,
  deleteEmployee,
  enableEmployee,
} from "../../redux/actions/employeeActions";
import { useAppDispatch } from "../../redux/hooks";
import { EmployeeResponse } from "../../api/generated/index";
import {
  SUCCESS_DELETE,
  SUCCESS_ENABLED,
} from "../../constants/messageConstants";
import { handleErrors, handleToast } from "../../utils/helpers";

interface EmployeeTableProps {
  employees: EmployeeResponse[];
}

export const EmployeeTable: React.FC<EmployeeTableProps> = ({ employees }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentItem = React.useRef<EmployeeResponse | null>(null);
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [search, setSearch] = React.useState<string>("");
  const [searchList, setSearchList] =
    React.useState<EmployeeResponse[]>(employees);

  const handleOpen = (item: EmployeeResponse) => {
    setShowDeletePopup(true);
    currentItem.current = item;
  };
  const handleClose = () => {
    setShowDeletePopup(false);
  };

  const onDelete = (id: number) => {
    dispatch(deleteEmployee(id))
      .unwrap()
      .then(() => {
        dispatch(getEmployees());
        handleToast(SUCCESS_DELETE);
      })
      .catch((res) => {
        handleErrors(res);
      });
    handleClose();
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    if (value === "") {
      setSearchList(employees);
      return;
    }

    setSearchList(
      employees.filter((item) =>
        item.name?.toLowerCase().includes(value.toLowerCase().trim())
      )
    );
  };

  const onCancel = () => {
    handleClose();
  };

  const onAdd = (id: number) => {
    navigate(`new/${id}`);
  };

  const onEnable = (username: string) => {
    dispatch(enableEmployee(username))
      .unwrap()
      .then((res) => {
        if (res) {
          dispatch(getEmployees());
          handleToast(SUCCESS_ENABLED);
        }
      });
  };

  React.useEffect(() => {
    setSearchList(employees);
  }, [employees]);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <h2 className="manager__title manager__title--compact">
        <span className="manager__title-text">List of added employees</span>

        <input
          className="manager__title-input"
          type="text"
          placeholder="Search by name..."
          value={search}
          onChange={(e) => handleSearch(e.target.value)}
        />
      </h2>
      <div className="manager__content">
        <table className="table">
          <thead>
            <tr className="table__row">
              <th className="table__head">Name</th>
              <th className="table__head">Company</th>
              <th className="table__head">Status</th>
              <th className="table__head">Email</th>
              <td className="table__head">Username</td>
              <th className="table__head">Enabled</th>
              <th className="table__head table__head--action">Actions</th>
            </tr>
          </thead>
          <tbody>
            {searchList.map((item) => {
              return (
                <tr className="table__row" key={item.id}>
                  <td className="table__cell">{item.name}</td>
                  <td className="table__cell">
                    {item.company?.name ? item.company.name : "/"}
                  </td>
                  <td className="table__cell">
                    {item.status ? item.status : "/"}
                  </td>
                  <td className="table__cell">{item.email}</td>
                  <td className="table__cell">{item.username}</td>
                  <td className="table__cell">{item.enabled ? "Yes" : "No"}</td>
                  <td className="table__cell">
                    {!item.enabled ? (
                      <button
                        onClick={() => onEnable(item.username!)}
                        className="btn btn--orange"
                        style={{ width: "100%", margin: "1px" }}
                      >
                        Enable
                      </button>
                    ) : null}
                    <button
                      onClick={() => onAdd(item.id!)}
                      className="btn btn--green"
                      style={{ width: "100%", margin: "1px" }}
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleOpen(item)}
                      className="btn btn--red"
                      style={{ width: "100%", margin: "1px" }}
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal show={showDeletePopup} handleClose={onCancel}>
        <h2 className="popup__heading-plain">
          Are you sure you want to delete this employee?
        </h2>
        <div className="popup__actions">
          <button
            onClick={() => onDelete(currentItem.current?.id!)}
            type="button"
            className="btn btn--green popup__btn"
          >
            Delete
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="btn btn--red popup__btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
