import { createAsyncThunk } from "@reduxjs/toolkit";

import { Api } from "../../api/index";
import {
  GeneralStatistic,
  CompanyStatistic,
  BuildingStatistic,
  WorkstationStatistic,
  EmployeeWithoutEventStatisticsResponse,
} from "../../api/generated";

export const getWorkstationStatistics = createAsyncThunk(
  "getWorkstationStatistics",
  async (): Promise<WorkstationStatistic[]> => {
    return Api.Statistics.getWorkstationStatistics().then(({ data }) => {
      return data;
    });
  }
);

export const getStatistics = createAsyncThunk(
  "getStatistics",
  async (): Promise<GeneralStatistic> => {
    return Api.Statistics.getGeneralStatistic().then(({ data }) => {
      return data;
    });
  }
);

export const getCompaniesStatistics = createAsyncThunk(
  "getCompaniesStatistics",
  async (): Promise<CompanyStatistic[]> => {
    return Api.Statistics.getCompaniesStatistic().then(({ data }) => {
      return data;
    });
  }
);

export const getBuildingStatistics = createAsyncThunk(
  "getBuildingStatistics",
  async (): Promise<BuildingStatistic[]> => {
    return Api.Statistics.getBuildingsStatistic().then(({ data }) => {
      return data;
    });
  }
);

export const getAccessControlWithoutEventsStatistics = createAsyncThunk(
  "getAccessControlWithoutEventsStatistics",
  async (): Promise<EmployeeWithoutEventStatisticsResponse[]> => {
    return Api.Statistics.getEmployeesWithoutTodaysEventsUntilNow().then(({ data }) => {
      return data;
    });
  }
);
