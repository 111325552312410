import { Loader } from 'components/loader/Loader';
import { StatisticsByAccessControlTable } from './StatisticsByAccessControlTable';
import { useAppSelector } from 'redux/hooks';

export const StatisticsByAccessControl = () => {
    const loadingState = useAppSelector(state => state.statistics.tokensWithoutEventsLoadingState);
    const isLoading = loadingState === 'NotLoaded' || loadingState === 'Loading';

    return (
        <main className="main">
            <div className="wrap">
                <div className="intro">
                    <h1 className="intro__title">Statistics by Access control system</h1>
                </div>
                <div className="manager">
                    {isLoading && <Loader />}
                    <StatisticsByAccessControlTable />
                    {null}
                </div>
            </div>
        </main>
    )
};
