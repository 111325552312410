import * as React from "react";

import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import { Modal } from "../../components/modal/Modal";
import { getRooms, deleteRoom } from "../../redux/actions/roomActions";
import { getFloors } from "../../redux/actions/floorActions";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  FloorWithBuildingInfoResponse,
  RoomResponse,
} from "../../api/generated/index";
import { SUCCESS_DELETE } from "../../constants/messageConstants";
import { handleErrors, handleToast } from "../../utils/helpers";

interface RoomTableProps {
  rooms: RoomResponse[];
}

export const RoomTable: React.FC<RoomTableProps> = ({ rooms }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { floors } = useAppSelector((state) => state.floor);
  const currentItem = React.useRef<RoomResponse | null>(null);
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [searchByBuilding, setSearchByBuilding] = React.useState<string>("");
  const [searchByFloor, setSearchByFloor] = React.useState<string>("");

  const [searchList, setSearchList] = React.useState<RoomResponse[]>(rooms);
  const [searchListFloors, setSearchListFloors] = React.useState<
    FloorWithBuildingInfoResponse[]
  >([]);

  const handleSearchByBuilding = (value: string) => {
    setSearchByBuilding(value);
    if (value === "") {
      setSearchList(rooms);
      setSearchListFloors(floors);
      return;
    }
    setSearchList(
      rooms.filter((item) =>
        item.floor?.building?.name
          ?.toLowerCase()
          .includes(value.toLowerCase().trim())
      )
    );
    setSearchListFloors(
      floors.filter((item) =>
        item.building?.name?.toLowerCase().includes(value.toLowerCase().trim())
      )
    );
  };
  const handleSearchByFloor = (value: string) => {
    setSearchByFloor(value);
    if (value === "") {
      setSearchList(rooms);
      setSearchListFloors(floors);
      setSearchByBuilding("");
      return;
    }
    setSearchList(
      rooms.filter((item) =>
        item.floor?.nameForAdmins
          ?.toLowerCase()
          .includes(value.toLowerCase().trim())
      )
    );
  };

  const handleOpen = (item: RoomResponse) => {
    setShowDeletePopup(true);
    currentItem.current = item;
  };
  const handleClose = () => {
    setShowDeletePopup(false);
  };

  const onDelete = (id: number) => {
    dispatch(deleteRoom(id))
      .unwrap()
      .then(() => {
        dispatch(getRooms());
        handleToast(SUCCESS_DELETE);
      })
      .catch((res) => {
        handleErrors(res);
      });
    handleClose();
  };

  const onCancel = () => {
    handleClose();
  };

  const onAdd = (id: number) => {
    navigate(`new/${id}`);
  };

  React.useEffect(() => {
    setSearchList(rooms);
  }, [rooms]);

  React.useEffect(() => {
    dispatch(getFloors())
      .unwrap()
      .then((res) => {
        setSearchListFloors(res);
      });
  }, [dispatch]);

  return (
    <>
      <Toaster position="top-center" reverseOrder={true} />
      <h2 className="manager__title manager__title--compact">
        <span className="manager__title-text">List of added rooms</span>
        <input
          className="manager__title-input"
          type="text"
          placeholder="Search by building name..."
          value={searchByBuilding}
          onChange={(e) => handleSearchByBuilding(e.target.value)}
        />
        <select
          className="manager__title-select"
          value={searchByFloor}
          onChange={(e) => handleSearchByFloor(e.target.value)}
        >
          <option value="">All floors</option>
          {searchListFloors.map((item) => (
            <option key={item.nameForAdmins} value={item.nameForAdmins}>
              {item.nameForAdmins}
            </option>
          ))}
        </select>
      </h2>
      <div className="manager__content">
        <table className="table">
          <thead>
            <tr className="table__row">
              <th className="table__head">ID</th>
              <th className="table__head">Room name</th>
              <th className="table__head">Floor</th>
              <th className="table__head">Type</th>
              <th className="table__head table__head--action">Edit</th>
              <th className="table__head table__head--action">Delete</th>
            </tr>
          </thead>
          <tbody>
            {searchList.map((item) => {
              return (
                <tr className="table__row" key={item.id}>
                  <td className="table__cell">{item.id}</td>
                  <td className="table__cell">{item.name}</td>
                  <td className="table__cell">{item.floor?.nameForAdmins}</td>
                  <td className="table__cell">{item.type}</td>
                  <td className="table__cell">
                    <button
                      onClick={() => onAdd(item.id!)}
                      className="btn btn--green"
                    >
                      Edit
                    </button>
                  </td>
                  <td className="table__cell">
                    <button
                      onClick={() => handleOpen(item)}
                      className="btn btn--red"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal show={showDeletePopup} handleClose={onCancel}>
        <h2 className="popup__heading-plain">
          Are you sure you want to delete this room?
        </h2>
        <div className="popup__actions">
          <button
            onClick={() => onDelete(currentItem.current?.id!)}
            type="button"
            className="btn btn--green popup__btn"
          >
            Delete
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="btn btn--red popup__btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
