import * as React from "react";

import { useNavigate } from "react-router-dom";

import { Modal } from "../../components/modal/Modal";
import {
  deleteCompany,
  getCompanies,
} from "../../redux/actions/companyActions";
import { useAppDispatch } from "../../redux/hooks";
import { CompanyResponse } from "../../api/generated/index";
import { SUCCESS_DELETE } from "../../constants/messageConstants";
import { handleToast, handleErrors } from "../../utils/helpers";

interface CompanyTableProps {
  companies: CompanyResponse[];
}

export const CompanyTable: React.FC<CompanyTableProps> = ({ companies }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentItem = React.useRef<CompanyResponse | null>(null);
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);

  const handleOpen = (item: CompanyResponse) => {
    setShowDeletePopup(true);
    currentItem.current = item;
  };
  const handleClose = () => {
    setShowDeletePopup(false);
  };

  const onDelete = (id: number) => {
    dispatch(deleteCompany(id))
      .unwrap()
      .then(() => {
        dispatch(getCompanies());
        handleToast(SUCCESS_DELETE);
      })
      .catch((res) => {
        handleErrors(res);
      });
    handleClose();
  };

  const onCancel = () => {
    handleClose();
  };

  const onAdd = (id: number) => {
    navigate(`new/${id}`);
  };

  return (
    <>
      <h2 className="manager__title">List of added companies</h2>
      <div className="manager__content">
        <table className="table">
          <thead>
            <tr className="table__row">
              <th className="table__head">Company name</th>
              <th className="table__head table__head--action">Edit</th>
              <th className="table__head table__head--action">Delete</th>
            </tr>
          </thead>
          <tbody>
            {companies.map((item) => {
              return (
                <tr className="table__row" key={item.id}>
                  <td className="table__cell">{item.name}</td>
                  <td className="table__cell">
                    <button
                      onClick={() => onAdd(item.id!)}
                      className="btn btn--green"
                    >
                      Edit
                    </button>
                  </td>
                  <td className="table__cell">
                    <button
                      onClick={() => handleOpen(item)}
                      className="btn btn--red"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <Modal show={showDeletePopup} handleClose={handleClose}>
        <h2 className="popup__heading-plain">
          Are you sure you want to delete this company?
        </h2>
        <div className="popup__actions">
          <button
            onClick={() => onDelete(currentItem.current?.id!)}
            type="button"
            className="btn btn--green popup__btn"
          >
            Delete
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="btn btn--red popup__btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
