import React, { useEffect, useMemo, useState } from 'react'
import { useDownloadExcel } from 'react-export-table-to-excel';
import { getAccessControlWithoutEventsStatistics } from 'redux/actions/statisticsActions';
import { useAppDispatch, useAppSelector } from 'redux/hooks'

const mouseEnter = () => {
    document.getElementById("btn-export")?.setAttribute('style', 'background: white; border-color: #f05a28; height: 100%;')
}

const mouseLeave = () => {
    document.getElementById("btn-export")?.setAttribute('style', 'background: #f05a28; height: 100%;')
}

export const StatisticsByAccessControlTable = () => {
    const dispatch = useAppDispatch();
    const [date, setDate] = useState(new Date());
    const tableRef = React.useRef(null);
    const { tokensWithoutEvents: data } = useAppSelector(state => state.statistics);

    useEffect(() => {
        dispatch(getAccessControlWithoutEventsStatistics());
        setDate(new Date());
    }, [dispatch]);

    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: `${date.getFullYear()}-${date.getMonth()}-${date.getDate()} - ${date.getHours()}h ${date.getMinutes()}m - Employees without events`,
        sheet: 'Employees'
    })


    const [companySearch, setCompanySearch] = useState<string>("");
    const [buildingSearch, setBuildingSearch] = useState<string>("");
    const [devLeadSearch, setDevLeadSearch] = useState<string>("");

    const companies = useMemo(
        () => Array.from(new Set(data.map(item => item.company))).filter(c => !!c?.length)
        , [data]);

    const buildings = useMemo(
        () => Array.from(new Set(data.map(item => item.building))).filter(c => !!c?.length)
        , [data]);

    const devLeads = useMemo(
        () => Array.from(new Set(data.map(item => item.devLeadName))).filter(c => !!c?.length)
        , [data]);

    const filteredData = useMemo(() =>
        data.filter(item =>
            (companySearch === '' || item.company === companySearch)
            && (buildingSearch === '' || item.building === buildingSearch)
            && (devLeadSearch === '' || item.devLeadName === devLeadSearch))
        , [buildingSearch, companySearch, data, devLeadSearch]);


    return (
        <>
            <div className="employee-statistics__bar">
                <h2 className="manager__title manager__title--compact">
                    <span className="manager__title-text">List of employees</span>
                    <select
                        className="manager__title-select"
                        value={companySearch}
                        onChange={(e) => setCompanySearch(e.target.value)}
                    >
                        <option value="">All companies</option>
                        {companies.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                    <select
                        className="manager__title-select"
                        value={buildingSearch}
                        onChange={(e) => setBuildingSearch(e.target.value)}
                    >
                        <option value="">All buildings</option>
                        {buildings.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                    <select
                        className="manager__title-select"
                        value={buildingSearch}
                        onChange={(e) => setDevLeadSearch(e.target.value)}
                    >
                        <option value="">All dev leads</option>
                        {devLeads.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </h2>

                <button
                    onClick={onDownload}
                    style={{ "height": "100%" }}
                    id="btn-export"
                    type="button"
                    onMouseEnter={mouseEnter}
                    onMouseLeave={mouseLeave}
                    className="btn btn--orange">
                    Export
                </button>

            </div>

            <div>
                <div className="manager__content">
                    <table className="table" ref={tableRef}>
                        <thead>
                            <tr className="table__row">
                                <th className="table__head">Employee</th>
                                <th className="table__head">Company</th>
                                <th className="table__head">Country</th>
                                <th className="table__head">City</th>
                                <th className="table__head">Building</th>
                                <th className="table__head">Floor</th>
                                <th className="table__head">Office</th>
                                <th className="table__head">Dev lead</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredData.map((item) => {
                                return (
                                    <tr className="table__row" key={item.employee ?? '-'}>
                                        <td className="table__cell">{item.employee ?? '-'}</td>
                                        <td className="table__cell">{item.company ?? '-'}</td>
                                        <td className="table__cell">{item.country ?? '-'}</td>
                                        <td className="table__cell">{item.city ?? '-'}</td>
                                        <td className="table__cell">{item.building ?? '-'}</td>
                                        <td className="table__cell">{item.floor ?? '-'}</td>
                                        <td className="table__cell">{item.office ?? '-'}</td>
                                        <td className="table__cell">{item.devLeadName ?? '-'}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div></>
    )
}
