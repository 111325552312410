export const Floor = () => {
  return (
    <svg
      className="dashboard__image"
      width="60"
      height="60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M57.504 20.004h-8.748V2.496h8.748c.684 0 1.248-.564 1.248-1.248S58.188 0 57.504 0h-9.996c-.036 0-.072.012-.108.024-.072.012-.156.024-.228.048-.12.036-.252.096-.36.18H46.8c-.06.036-.12.084-.192.132l-9.864 7.404c-.048.024-.084.06-.132.096l-9.9 7.428c-.024.024-.06.036-.084.072l-9.948 7.464c-.012.012-.036.024-.048.036l-9.996 7.488-4.86 3.636c-.552.408-.66 1.2-.252 1.752.408.552 1.2.66 1.752.252l3-2.256v16.236h-3.78c-.684 0-1.248.564-1.248 1.248v7.5c0 .684.564 1.248 1.248 1.248h54.996c.684 0 1.248-.564 1.248-1.248V21.252a1.237 1.237 0 0 0-1.236-1.248ZM38.748 9.396l7.5-5.628v16.224H45c-.684 0-1.248.564-1.248 1.248v6.252h-5.004V9.396Zm-9.996 7.5 7.5-5.616v16.224h-1.248c-.684 0-1.248.564-1.248 1.248v5.004h-5.004v-16.86Zm-9.996 7.488 7.5-5.628v14.988H23.76c-.684 0-1.248.564-1.248 1.248v7.5h-3.756V24.384Zm-10.008 7.5 7.5-5.628v16.236h-2.496c-.684 0-1.248.564-1.248 1.248v6.252H8.748V31.884Zm47.508 25.62h-52.5V52.5h9.996c.684 0 1.248-.564 1.248-1.248V45h8.748c.684 0 1.248-.564 1.248-1.248v-7.5h9.996c.684 0 1.248-.564 1.248-1.248V30H45c.684 0 1.248-.564 1.248-1.248V22.5h9.996v35.004h.012Z"
        fill="#F05A28"
      />
    </svg>
  );
};
