import React from "react";

interface Props {
  title: string;
}

export const MainWrapper: React.FC<Props> = ({ title, children }) => {
  return (
    <>
      <h2 className="manager__title">{title}</h2>
      <div className="manager__content">{children}</div>
    </>
  );
};
