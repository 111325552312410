import * as React from "react";

import { CompanyStatistic } from "../../api/generated";

interface StatisticByCompaniesResultProps {
  result: CompanyStatistic;
}

export const StatisticByCompaniesResult: React.FC<
  StatisticByCompaniesResultProps
> = ({ result }) => {
  return (
    <div className="stats-results stats-results--company js-stats-results stats-results--open">
      <div className="stats-results__general">
        <span className="stats-results__general-content">
          <strong>Total number of employees:</strong> {result.totalEmployee}
        </span>
        <span className="stats-results__general-content">
          <strong>Working from office:</strong> {result.office}
        </span>
        <span className="stats-results__general-content">
          <strong>Working from home:</strong> {result.home}
        </span>
        <span className="stats-results__general-content">
          <strong>Inactive:</strong> {result.inactive}
        </span>
      </div>
      <h2 className="stats-results__heading">Number of employees:</h2>
      {result.companyStatisticBuildings?.map((building, index) => {
        return (
          <div className="stats-results__item" key={index}>
            <h3 className="stats-results__title">{building.buildingName}</h3>
            {building.floors?.map((floor, index) => {
              return (
                <div className="stats-results__floor" key={index}>
                  <h4 className="stats-results__name">{floor.name}</h4>
                  {floor.offices?.map((office, index) => {
                    return (
                      <span className="stats-results__content" key={index}>
                        <strong>{office.name}:</strong>
                        <ul>
                          <li>Employees: {office.totalEmployees}</li>
                          <li>Reserved: {office.reservedTables}</li>
                          <li>Interns: {office.occupiedByInternTables}</li>
                        </ul>
                      </span>
                    );
                  })}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
