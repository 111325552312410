import * as React from "react";

import { CompanyStatistic } from "../../api/generated";

import { StatisticByCompaniesResult } from "./index";

interface StatisticByCompaniesSidebarProps {
  companies: CompanyStatistic[];
}

export const StatisticByCompaniesSidebar: React.FC<
  StatisticByCompaniesSidebarProps
> = ({ companies }) => {
  const [result, setResult] = React.useState<CompanyStatistic | null>(null);

  const handleClick = (item: CompanyStatistic) => {
    setResult(item);
  };

  const handleResultView = () => {
    if (result != null) {
      return <StatisticByCompaniesResult result={result} />;
    }

    return null;
  };

  return (
    <>
      <aside className="stats-sidebar">
        <div className="stats-sidebar__list">
          {companies.map((item, index) => (
            <div
              key={index}
              className="stats-sidebar__item"
              onClick={() => handleClick(item)}
            >
              <button
                type="button"
                className="stats-sidebar__btn js-stats-expand-company"
              >
                {item.company}
              </button>
            </div>
          ))}
        </div>
      </aside>
      {handleResultView()}
    </>
  );
};
