import { createSlice } from "@reduxjs/toolkit";

import { CountryState } from "../states/CountryState";
import {
  getCountries,
  getCountry,
  postCountry,
  deleteCountry,
  putCountry,
} from "../actions/countryActions";

const initialState: CountryState = {
  countries: [],
  isLoading: false,
  success: false,
};

const CountySlice = createSlice({
  name: "Country",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCountries.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.countries = action.payload;
      })
      .addCase(getCountries.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getCountry.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getCountry.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(getCountry.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(postCountry.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(postCountry.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(postCountry.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(deleteCountry.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(deleteCountry.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteCountry.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(putCountry.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(putCountry.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(putCountry.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      });
  },
});

export default CountySlice.reducer;
