export const EmptyListPlaceholder = () => {
  return (
    <div className="manager__empty">
      <p className="manager__empty-text">
        The list is empty. Hit the "add new" button and start managing.
      </p>
      <img
        src="images/content/vega-logo-grey-light.svg"
        alt=""
        className="manager__empty-image"
      />
    </div>
  );
};
