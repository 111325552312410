import * as React from "react";

import { useNavigate } from "react-router-dom";

export const StatisticByCompaniesHeader: React.FC = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/statistics");
  };
  return (
    <div className="wrap">
      <div className="panel-head">
        <ul className="panel-head__list">
          <li className="panel-head__item">
            <button type="button" onClick={handleNavigate} className="btn-back">
              Back
            </button>
          </li>
          <li className="panel-head__item">
            <h1 className="panel-head__title">
              Statistics <strong>by companies</strong>
            </h1>
          </li>
        </ul>
      </div>
    </div>
  );
};
