import { createAsyncThunk } from "@reduxjs/toolkit";

import { AdminResponse, AdminRequest } from "../../api/generated/index";
import { Api } from "../../api/index";
import { PostAdminType, PutAdminType } from "../../types/AdminTypes";

export const getAdmins = createAsyncThunk(
  "getAdmins",
  async (): Promise<AdminResponse[]> => {
    return Api.Admin.getAllAdmins().then(({ data }) => {
      return data;
    });
  }
);

export const postAdmin = createAsyncThunk(
  "postAdmin",
  async (postAdminType: PostAdminType): Promise<AdminResponse> => {
    const adminRequest: AdminRequest = {
      username: postAdminType.username,
      email: postAdminType.email,
      role: postAdminType.userRole,
    };
    return Api.Admin.saveAdmin(adminRequest).then(({ data }) => {
      return data;
    });
  }
);

export const putAdmin = createAsyncThunk(
  "putAdmin",
  async (putAdminType: PutAdminType): Promise<AdminResponse> => {
    const adminRequest: AdminRequest = {
      username: putAdminType.username,
      email: putAdminType.email,
      role: putAdminType.userRole,
    };
    return Api.Admin.updateAdmin(putAdminType.adminId, adminRequest).then(
      ({ data }) => {
        return data;
      }
    );
  }
);

export const getAdmin = createAsyncThunk(
  "getAdmin",
  async (id: number): Promise<AdminResponse> => {
    return Api.Admin.getAdminById(id).then(({ data }) => {
      return data;
    });
  }
);

export const deleteAdmin = createAsyncThunk(
  "deleteAdmin",
  async (id: number) => {
    return Api.Admin.deleteAdmin(id).then(({ data }) => {
      return data;
    });
  }
);
