export const Card = () => {
  return (
    <svg
      className="dashboard__image"
      width="60"
      height="60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          className="st0"
          fill="#FF590B"
          d="M29.6,13.5c3.5,0,6.1-1.8,6.1-4.2S33.1,5,29.6,5s-6.1,1.8-6.1,4.2S26.1,13.5,29.6,13.5z M29.6,8.1
		c2,0,3.1,0.9,3.1,1.2s-1.1,1.2-3.1,1.2s-3.1-0.9-3.1-1.2S27.6,8.1,29.6,8.1z"
        />
        <path
          className="st0"
          fill="#FF590B"
          d="M47.1,13.9C44.1,8.7,37.8,0,30,0l0,0c-3.6,0-7.1,1.5-10.4,4.4c-2.7,2.4-5.3,5.7-7.5,9.8
		c-4.2,7.6-6.4,16-6.4,20.4c0,6.5,1.9,12.5,5.3,17C15.2,57,21.7,60,29.8,60c4.3,0,8.2-0.8,11.5-2.3c3-1.4,5.6-3.5,7.6-6.1
		c3.4-4.4,5.3-10.5,5.3-17.1C54.3,29.6,51.3,21,47.1,13.9z M29.8,57c-15.5,0-21-11.6-21-22.4c0-3.9,2.1-11.8,6-18.9
		C17.3,10.9,22.7,3,30,3l0,0c4.6,0,9.9,4.5,14.5,12.5c4.4,7.4,6.7,15.3,6.7,19C51.2,45.4,45.6,57,29.8,57z"
        />
        <path
          className="st0"
          fill="#FF590B"
          d="M30,17.5c-10.3,0-18.6,8.3-18.6,18.6S19.7,54.7,30,54.7s18.6-8.4,18.6-18.6S40.2,17.5,30,17.5z M30,51.6
		c-8.6,0-15.6-7-15.6-15.6s7-15.6,15.6-15.6s15.6,7,15.6,15.5C45.5,44.6,38.6,51.6,30,51.6z"
        />
      </g>
    </svg>
  );
};
