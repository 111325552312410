import * as React from "react";

import { useNavigate } from "react-router-dom";

import { Button } from "ui-components";
import { NavItem } from "./NavItem";
import { Loader } from "../loader/Loader";
import { syncEmployees } from "../../redux/actions/employeeActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

export const Nav: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { isLoading } = useAppSelector((state) => state.employee);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const hamburgerClass = isOpen
    ? "header__hamburger js-header-hamburger header__hamburger--open"
    : "header__hamburger js-header-hamburger";

  const contentClass = isOpen
    ? "header__actions js-header-actions header__actions--open"
    : "header__actions js-header-actions";

  const handleToogle = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigate = () => {
    navigate("/");
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  const handleSync = () => {
    dispatch(syncEmployees())
      .unwrap()
      .then(() => {
        navigate("/employee");
      });
  };

  const onLogout = () => {
    localStorage.removeItem("persist:root");
    navigate("/login");
  };

  return (
    <header className="header">
      {handleLoader()}
      <button onClick={handleNavigate} type="button" className="header__logo">
        <img
          src="images/vegait-logo-black.svg"
          alt=""
          className="header__logo-img"
        />
      </button>
      <button onClick={handleToogle} className={hamburgerClass}>
        <span className="header__hamburger__bar"></span>
        <span className="sr-only">Menu</span>
      </button>
      <div className={contentClass}>
        <nav className="header__nav">
          <NavItem to="/" text={"Control panel"} />
          <NavItem to="/admin" text={"Manage admins"} />
          <NavItem to={"/statistics"} text={"Statistics"} />
        </nav>
        <div className="header__buttons">
          <Button onClick={onLogout} text={"Log out"} />
        </div>
      </div>
      <div className="header__buttons">
        <Button onClick={onLogout} text={"Log out"} />
      </div>
    </header>
  );
};
