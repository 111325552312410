import { createSlice } from "@reduxjs/toolkit";

import { AdminState } from "../states/AdminState";
import { getAdmins, postAdmin, getAdmin } from "../actions/adminActions";

const initialState: AdminState = {
  admins: [],
  isLoading: false,
  success: false,
};

const AdminSlice = createSlice({
  name: "Admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAdmins.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.admins = action.payload;
      })
      .addCase(getAdmins.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getAdmin.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(getAdmin.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(postAdmin.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(postAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(postAdmin.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      });
  },
});

export default AdminSlice.reducer;
