import { useCallback, useEffect } from "react";
import { getCards } from "redux/actions/accessControlCardActions";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { handleToast } from "utils";

export const useToastForInvalidCard = () => {
  const dispatch = useAppDispatch();
  const { cards, allCardsLoadingState } = useAppSelector((state) => state.accessControlCard);

  const showToastForInvalidCardIfNeeded = useCallback(
    (accessControlNumber?: string) => {
      if (!accessControlNumber?.length) return;

      const card = cards.find((card) => card.cardNo === accessControlNumber);
      if (!!card) return;

      handleToast(`Assigned ACS token '${accessControlNumber}' does not exist, please create it under 'Manage a token'`, "warning", 10000);
    },
    [cards]
  );

  useEffect(() => {
    if (allCardsLoadingState === "NotLoaded") {
      dispatch(getCards());
    }
  }, [allCardsLoadingState, dispatch]);

  return showToastForInvalidCardIfNeeded;
};
