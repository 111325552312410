import * as React from "react";

interface ModalProps {
  show: boolean;
  size?: string;
  disableOverflow?: boolean;
  handleClose: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  show,
  handleClose,
  size,
  children,
  disableOverflow
}) => {
  const handleClass =
    size !== undefined
      ? `popup__content popup__content--${size}`
      : `popup__content popup__content--small`;

  if (!show) return null;

  return (
    <div className="popup" style={{ display: "block" }}>
      <span className="popup__background"></span>
      <div className={handleClass} style={{overflow: disableOverflow ? 'visible' : 'auto'}}>
        <button type="button" className="popup__close" onClick={handleClose}>
          <span className="sr-only">Close popup</span>
        </button>
        {children}
      </div>
    </div>
  );
};
