import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ACTIVE_MENU_ITEM_COLOR } from "utils";

interface Props {
  text: string;
  to: string;
}

export const NavItem: React.FC<Props> = ({ to, text }) => {
  const location = useLocation();
  const style =
    location.pathname === to ? { color: ACTIVE_MENU_ITEM_COLOR } : {};

  return (
    <Link to={to} className="header__nav-link" style={style}>
      {text}
    </Link>
  );
};
