import * as React from "react";

import {
  Controller,
  ControllerRenderProps,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";

import {
  SVGEditor,
  getWorkstations,
  createSvgFile,
  injectSVGInEditor,
} from "components/svgEditor";
import { ManageWrapper } from "layouts/manageLayout";
import { MainWrapper } from "layouts/mainWrapper/MainWrapper";
import { TextInput, Dropdown } from "ui-components";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getFloor,
  getSvg,
  postFloor,
  putFloor,
  putWorkstationsInFloor,
} from "../../redux/actions/floorActions";
import {
  getBuildings,
  updateBuildingFloor,
} from "../../redux/actions/buildingActions";
import { SUCCESS_POST, SUCCESS_PUT } from "../../constants/messageConstants";
import { handleErrors, handleToast } from "../../utils/helpers";
import { getBuildingId } from "../../utils/helpers";

type Inputs = {
  adminName: string;
  displayName: string;
  building: string;
  level: number;
  file: File;
};

export const NewFloor: React.FC = () => {
  const [floorChanged, setFloorChanged] = React.useState(true);
  const [isSVGVisible, setIsSVGVisible] = React.useState<boolean>(false);
  const { buildings, floors } = useAppSelector((state) => state.building);
  const { id, buildingId } = useParams();
  const isEditable = id !== undefined ? true : false;
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const errorBuilding =
    errors.building?.type === "required" ? "Building name is required" : null;

  const errorAdminName =
    errors.adminName?.type === "required"
      ? "Name for administrators is required"
      : null;

  const errorDisplayName =
    errors.displayName?.type === "required" ? "Display name is required" : null;

  const errorLevel =
    errors.level?.type === "required" ? "Level is required" : null;

  const rulesBuilding = { required: true };
  const rulesAdminName = { required: true };
  const rulesDisplayName = { required: true };
  const rulesLevel = { required: true };

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (buildingId !== undefined) {
      dispatch(
        updateBuildingFloor({
          id: Number(id),
          nameForAdmins: data.adminName,
          nameForUsers: data.displayName,
          level: data.level,
        })
      );
      navigate(`/building/new/${buildingId}`);
      return;
    }

    if (id !== undefined) {
      const putFloorType = {
        floorId: Number(id),
        adminName: data.adminName,
        displayName: data.displayName,
        buildingId: getBuildingId(data.building, buildings)!,
        level: data.level,
        svg: createSvgFile(data.file.name),
        workstations: getWorkstations(),
      };
      dispatch(putFloor(putFloorType))
        .unwrap()
        .then(() => {
          dispatch(putWorkstationsInFloor(putFloorType));
          handleToast(SUCCESS_PUT);
          navigate(-1);
        })
        .catch((res) => {
          handleErrors(res);
        });

      return;
    }

    dispatch(
      postFloor({
        adminName: data.adminName,
        displayName: data.displayName,
        buildingId: getBuildingId(data.building, buildings)!,
        level: data.level,
        svg: data.file,
      })
    )
      .unwrap()
      .then(() => {
        handleToast(SUCCESS_POST);
        navigate(-1);
      })
      .catch((res) => {
        handleErrors(res);
      });
  };

  const onCancel = () => {
    if (buildingId !== undefined) {
      navigate(`/building/new/${buildingId}`, { replace: true });
      return;
    }
    navigate(-1);
  };

  const handleChange = (e: any) => {
    var file: File = e.target.files[0];
    setValue("file", file);
    injectSVGInEditor(file).then((res) => {
      if (!res) return;
      setIsSVGVisible(true);
      setFloorChanged(!floorChanged);
    });
  };

  const setFile = React.useCallback((file: File) => {
    injectSVGInEditor(file).then((res) => {
      if (!res) return;
      setIsSVGVisible(true);
      setFloorChanged((prev) => !prev);
    });
  }, []);

  const renderAdminName = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "adminName">;
  }) => {
    return (
      <TextInput
        error={errorAdminName}
        label={"Display name for admins"}
        placeholder="Enter a floor name for admins..."
        {...field}
      />
    );
  };

  const renderDisplayName = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "displayName">;
  }) => {
    return (
      <TextInput
        error={errorDisplayName}
        label={"Display name for users"}
        placeholder="Enter a floor name for users..."
        {...field}
      />
    );
  };

  const renderBuildings = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "building">;
  }) => {
    return (
      <Dropdown
        error={errorBuilding!}
        onChangeSelection={field.onChange}
        label="Select a building"
        placeholder={field.value !== "" ? field.value : "Select a building..."}
        options={buildings.map((item) => item.name)}
        {...field}
      />
    );
  };

  const renderLevel = ({
    field,
  }: {
    field: ControllerRenderProps<Inputs, "level">;
  }) => {
    return (
      <TextInput
        error={errorLevel}
        label={"Level"}
        placeholder="Enter level..."
        type="number"
        {...field}
      />
    );
  };

  const getBuildingName = React.useCallback(
    (id: number) => {
      let building = buildings.find((item) => item.id === id);
      return building?.name;
    },
    [buildings]
  );

  const handleSvgEditor = () => {
    if (buildingId === undefined) {
      return (
        <SVGEditor
          hasData={isSVGVisible}
          floorChanged={floorChanged}
          isEditable={isEditable}
          floorId={id ? Number(id) : undefined}
        />
      );
    }
    return null;
  };
  const handleSvgUpload = () => {
    if (buildingId === undefined) {
      return (
        <div className="form__item">
          <label className="form__label">File upload</label>
          <input
            type="file"
            id="file"
            onChangeCapture={handleChange}
            className="form__upload"
            data-placeholder="Upload file"
          />
        </div>
      );
    }
    return null;
  };

  React.useEffect(() => {
    dispatch(getBuildings());
    if (id !== undefined && buildingId === undefined) {
      dispatch(getFloor(Number(id)))
        .unwrap()
        .then((res) => {
          setValue("adminName", res?.nameForAdmins!);
          setValue("displayName", res?.nameForUsers!);
          setValue("building", res?.building?.name!);
          setValue("level", res?.level!);
          const svgNameArray = res.svgUrl?.split("/")!;
          const svgName = svgNameArray[svgNameArray?.length - 1]!;

          dispatch(getSvg(svgName))
            .unwrap()
            .then((res) => {
              setFile(new File([res], svgName));
              setValue("file", new File([res], svgName));
            })
            .catch((res) => {
              handleErrors(res);
            });
        })
        .catch((res) => {
          handleErrors(res);
        });
    }
  }, [id, setValue, dispatch, buildingId, setFile]);

  React.useEffect(() => {
    if (id !== undefined && buildingId !== undefined) {
      const floor = floors?.find((item) => item.id === Number(id));
      setValue("adminName", floor?.nameForAdmins!);
      setValue("displayName", floor?.nameForUsers!);
      setValue("building", getBuildingName(Number(buildingId))!);
      setValue("level", floor?.level!);
      return;
    }
  }, [buildingId, floors, getBuildingName, id, setValue]);

  return (
    <ManageWrapper
      buttonText="Add a new floor"
      manageText="a floor"
      navigateTo=""
      isButtonVisible={false}
    >
      <MainWrapper title="Add a new floor">
        <form className="form">
          <div className="form__row">
            <Controller
              name="adminName"
              control={control}
              defaultValue=""
              rules={rulesAdminName}
              render={renderAdminName}
            />

            <Controller
              name="displayName"
              control={control}
              defaultValue=""
              rules={rulesDisplayName}
              render={renderDisplayName}
            />

            <Controller
              name="building"
              control={control}
              defaultValue=""
              rules={rulesBuilding}
              render={renderBuildings}
            />
          </div>
          <div className="form__row">
            <Controller
              name="level"
              control={control}
              defaultValue={0}
              rules={rulesLevel}
              render={renderLevel}
            />
            {handleSvgUpload()}
          </div>

          <div className="form__actions">
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              className="form__btn btn btn--green"
            >
              Save
            </button>
            <button
              type="button"
              onClick={onCancel}
              className="form__btn btn btn--red"
            >
              Cancel
            </button>
          </div>
          {handleSvgEditor()}
        </form>
      </MainWrapper>
    </ManageWrapper>
  );
};
