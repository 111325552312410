import * as React from "react";

import { FloorListItem } from "./FloorListItem";
import { FloorUpdateInBuildingRequest } from "../../api/generated";

export type FloorListProps = {
  items: FloorUpdateInBuildingRequest[];
  buildingId: number;
};

export const FloorList: React.FC<FloorListProps> = ({ items, buildingId }) => {
  return (
    <>
      {items.map((item) => (
        <FloorListItem item={item} buildingId={buildingId} key={item.id} />
      ))}
    </>
  );
};
