import { useState, useEffect } from "react";

import { EmptyListPlaceholder } from "components/emptyListPlaceholder";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { EmployeeTable } from "./index";
import { Loader } from "../../components/loader/Loader";
import { getEmployees, sendSetPasswordEmails } from "../../redux/actions/employeeActions";
import { Link } from "react-router-dom";
import { SUCCESS_SENT } from "../../constants/messageConstants";
import { handleErrors, handleToast } from "../../utils/helpers";
import ConfirmationModal from "components/modal/ConfirmationModal";

export const Employee: React.FC = () => {
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { employees, isLoading } = useAppSelector((state) => state.employee);

  const handleView = () => {
    if (employees.length !== 0) {
      return <EmployeeTable employees={employees} />;
    }

    return <EmptyListPlaceholder />;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleShowConfirmationModal = () => {
    setShowConfirmationModal(true);
  };

  const handleBulkSetPassword = () => {
    handleCloseConfirmationModal();
    dispatch(sendSetPasswordEmails())
      .unwrap()
      .then(() => {
        handleToast(SUCCESS_SENT);
      })
      .catch((res) => {
        handleErrors(res);
      });
  };

  useEffect(() => {
    dispatch(getEmployees());
  }, [dispatch]);

  const Buttons = () => (
    <div className="intro__buttons">
      {/*<button className="btn btn--green-light" onClick={handleShowConfirmationModal}>*/}
      {/*  Send setup email to disabled accounts*/}
      {/*</button>*/}
      <Link className="btn btn--black" to="/employee/new">
        Add a new employee
      </Link>
    </div>
  );

  return (
    <main className="main">
      <div className="wrap">
        <div className="intro">
          <h1 className="intro__title">
            Manage <strong>an employee</strong>
          </h1>
          <Buttons />
        </div>
        <div className="manager">
          {handleLoader()}
          {handleView()}
        </div>
      </div>
      <ConfirmationModal show={showConfirmationModal} handleClose={handleCloseConfirmationModal} handleConfirm={handleBulkSetPassword}>
        Are you sure you want to send setup emails to <strong>ALL</strong> disabled account?
      </ConfirmationModal>
    </main>
  );
};
