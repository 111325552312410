import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  AccessControlTokenResponse,
  AccessControlTokenRequest,
  AccessControlTokenUpdateRequest,
  ReplacementTokenResponse,
  ReplacementTokenRequest,
} from "../../api/generated";
import { Api } from "../../api";
import { PostCardType, PutCardType } from "../../types/AccessControlCardTypes";
import { withAxiosErrorHandling } from "redux/withAxiosErrorHandling";

export const getCards = createAsyncThunk("getCards", async (): Promise<AccessControlTokenResponse[]> => {
  return Api.Tokens.getAllCards().then(({ data }) => {
    return data;
  });
});

export const getCard = createAsyncThunk("getCard", async (id: number): Promise<AccessControlTokenResponse> => {
  return Api.Tokens.getCard(id).then(({ data }) => {
    return data;
  });
});

export const postCard = createAsyncThunk("postCard", async (postCardType: PostCardType): Promise<AccessControlTokenResponse> => {
  const cardRequest: AccessControlTokenRequest = {
    fullName: postCardType.fullName,
    cardNo: postCardType.cardNo,
  };

  return Api.Tokens.createCard(cardRequest)
    .then(({ data }) => {
      return data;
    })
    .catch(withAxiosErrorHandling);
});

export const putCard = createAsyncThunk("putCard", async (putCardType: PutCardType): Promise<AccessControlTokenResponse | any> => {
  const cardRequest: AccessControlTokenUpdateRequest = {
    fullName: putCardType.fullName,
  };
  return Api.Tokens.updateCard(putCardType.id, cardRequest).then(({ data }) => {
    return data;
  });
});

export const deleteCard = createAsyncThunk("deleteCard", async (id: number): Promise<void> => {
  return Api.Tokens.deleteCard(id).then(({ data }) => {
    return data;
  });
});

export const getAllReplacementTokens = createAsyncThunk(
  "getAllReplacementTokens",
  async (employeeId: number): Promise<ReplacementTokenResponse[]> => {
    return Api.ReplacementTokensControllerApi.getAllReplacementTokens(employeeId).then(({ data }) => {
      return data;
    });
  }
);

export const postReplacementToken = createAsyncThunk(
  "postReplacementToken",
  async ({
    employeeId,
    accessControlNumber,
    date,
  }: {
    employeeId: number;
  } & ReplacementTokenRequest): Promise<ReplacementTokenResponse> => {
    return Api.ReplacementTokensControllerApi.createReplacementToken(employeeId, {
      accessControlNumber,
      date,
    }).then(({ data }) => {
      return data;
    });
  }
);

export const putReplacementToken = createAsyncThunk(
  "putReplacementToken",
  async ({
    employeeId,
    id,
    accessControlNumber,
    date,
  }: {
    id: number;
    employeeId: number;
  } & ReplacementTokenRequest): Promise<ReplacementTokenResponse> => {
    return Api.ReplacementTokensControllerApi.updateReplacementToken(employeeId, id, {
      accessControlNumber,
      date,
    }).then(({ data }) => {
      return data;
    });
  }
);

export const deleteReplacementToken = createAsyncThunk(
  "deleteReplacementToken",
  async ({ employeeId, id }: { id: number; employeeId: number }): Promise<void> => {
    return Api.ReplacementTokensControllerApi.deleteReplacementToken(employeeId, id).then(({ data }) => {
      return data;
    });
  }
);
