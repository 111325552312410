import { createSlice } from "@reduxjs/toolkit";

import {
    getEmployeesDetails
} from "../actions/employeeActions";
import {EmployeeDetailsState} from '../states/EmployeeDetailsState';

const initialState: EmployeeDetailsState = {
    employeeDetails: [],
    isLoading: false,
    success: false
};

const EmployeeDetailsSlice = createSlice({
    name: "EmployeeDetails",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getEmployeesDetails.pending, (state) => {
                state.isLoading = true;
                state.success = false;
            })
            .addCase(getEmployeesDetails.fulfilled, (state, action) => {
                state.isLoading = false;
                state.success = true;
                state.employeeDetails = action.payload;
            })
            .addCase(getEmployeesDetails.rejected, (state) => {
                state.isLoading = false;
                state.success = false;
            })
    },
});

export default EmployeeDetailsSlice.reducer;
