import { createSlice } from "@reduxjs/toolkit";

import { AccessControlCardState } from "../states/AccessControlCardState";
import { getCards, getCard, postCard, putCard, deleteCard } from "../actions/accessControlCardActions";

const initialState: AccessControlCardState = {
  cards: [],
  allCardsLoadingState: "NotLoaded",
  isCardLoading: false,
};

const AccessControlCard = createSlice({
  name: "AccessControlCard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCards.pending, (state) => {
        state.allCardsLoadingState = "Loading";
      })
      .addCase(getCards.fulfilled, (state, action) => {
        state.allCardsLoadingState = "Loaded";
        state.cards = action.payload;
      })
      .addCase(getCards.rejected, (state) => {
        state.allCardsLoadingState = "Failed";
      })
      .addCase(getCard.pending, (state) => {
        state.isCardLoading = true;
      })
      .addCase(getCard.fulfilled, (state) => {
        state.isCardLoading = false;
      })
      .addCase(getCard.rejected, (state) => {
        state.isCardLoading = false;
      })

      .addCase(postCard.pending, (state) => {
        state.isCardLoading = true;
      })
      .addCase(postCard.fulfilled, (state) => {
        state.isCardLoading = false;
      })
      .addCase(postCard.rejected, (state) => {
        state.isCardLoading = false;
      })
      .addCase(putCard.pending, (state) => {
        state.isCardLoading = true;
      })
      .addCase(putCard.fulfilled, (state) => {
        state.isCardLoading = false;
      })
      .addCase(putCard.rejected, (state) => {
        state.isCardLoading = false;
      })
      .addCase(deleteCard.pending, (state) => {
        state.isCardLoading = true;
      })
      .addCase(deleteCard.fulfilled, (state) => {
        state.isCardLoading = false;
      })
      .addCase(deleteCard.rejected, (state) => {
        state.isCardLoading = false;
      });
  },
});

export default AccessControlCard.reducer;
