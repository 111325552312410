import * as React from "react";

import { Floor } from "./index";

interface StatisticByBuildingsResultProps {
  resultList: Floor[];
}

export const StatisticByBuildingsResult: React.FC<
  StatisticByBuildingsResultProps
> = ({ resultList }) => {
  return (
    <div className="stats-results js-stats-results stats-results--open">
      {resultList.map(
        (item) =>
          item.offices.length !== 0 && (
            <div key={item.id} className="stats-results__item">
              <h3 className="stats-results__title">{item.name}</h3>
              {item.offices.map(
                (room) =>
                  room.checked && (
                    <div key={room.item.id} className="stats-results__floor">
                      <h4 className="stats-results__name">{room.item.name}</h4>
                      <span className="stats-results__content">
                        <strong>Total tables:</strong> {room.item.totalTables}
                      </span>
                      <span className="stats-results__content">
                        <strong>Available tables:</strong>{" "}
                        {room.item.availableTables}
                      </span>
                      <span className="stats-results__content">
                        <strong>Occupied tables:</strong> {(room.item.occupiedTables ?? 0) + (room.item.reservedTables ?? 0) + (room.item.occupiedByInternTables ?? 0)}
                        <ul>
                          <li>Employees: {room.item.occupiedTables}</li>
                          <li>Reserved: {room.item.reservedTables}</li>
                          <li>Interns: {room.item.occupiedByInternTables}</li>
                        </ul>
                      </span>
                    </div>
                  )
              )}
              {item.confs.map(
                (room) =>
                  room.checked && (
                    <div key={room.item.id} className="stats-results__floor">
                      <h4 className="stats-results__name">{room.item.name}</h4>
                      <span className="stats-results__content">
                        <strong>Total tables:</strong> {room.item.totalTables}
                      </span>
                      <span className="stats-results__content">
                        <strong>Available tables:</strong>{" "}
                        {room.item.availableTables}
                      </span>
                    </div>
                  )
              )}
            </div>
          )
      )}
    </div>
  );
};
