import { createSlice } from "@reduxjs/toolkit";

import { CompanyState } from "../states/CompanyState";
import {
  getCompanies,
  getCompany,
  postCompany,
  deleteCompany,
  putCompany,
} from "../actions/companyActions";

const initialState: CompanyState = {
  companies: [],
  isLoading: false,
  success: false,
};

const CompanySlice = createSlice({
  name: "Company",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCompanies.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.companies = action.payload;
      })
      .addCase(getCompanies.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getCompany.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getCompany.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(getCompany.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(postCompany.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(postCompany.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(postCompany.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(deleteCompany.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(deleteCompany.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteCompany.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(putCompany.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(putCompany.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(putCompany.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      });
  },
});

export default CompanySlice.reducer;
