import * as React from "react";

import { ManageWrapper } from "layouts/manageLayout";
import { EmptyListPlaceholder } from "components/emptyListPlaceholder";
import { useAppSelector, useAppDispatch } from "../../redux/hooks";
import { FloorTable } from "./index";
import { getFloors } from "../../redux/actions/floorActions";
import { Loader } from "../../components/loader/Loader";

export const Floor: React.FC = () => {
  const dispatch = useAppDispatch();
  const { floors, isLoading } = useAppSelector((state) => state.floor);

  const handleView = () => {
    if (floors.length !== 0) {
      return <FloorTable floors={floors} />;
    }

    return <EmptyListPlaceholder />;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getFloors());
  }, [dispatch]);

  return (
    <ManageWrapper
      buttonText="Add a new floor"
      manageText="a floor"
      navigateTo="/floor/new"
      isButtonVisible
    >
      {handleLoader()}
      {handleView()}
    </ManageWrapper>
  );
};
