import { MenuItem } from "components/menuItem";
import {
  Building,
  Company,
  Employee,
  Card,
  Statistics as Stats,
} from "components/icons";

export const Statistics = () => {
  const statisticsItemConfig = [
    {
      lightText: "Statistics",
      strongText: "by buildings",
      icon: <Building />,
      to: "/statistics/buildings",
    },
    {
      lightText: "Statistics",
      strongText: "by companies",
      icon: <Company />,
      to: "/statistics/companies",
    },
    {
      lightText: "Statistics",
      strongText: "by employees",
      icon: <Employee />,
      to: "/statistics/employees",
    },
    {
      lightText: "Not shown",
      strongText: "by ACS",
      icon: <Card />,
      to: "/statistics/acs",
    }
  ];

  const StatisticsItems = () => {
    const menuList = statisticsItemConfig.map(
      ({ lightText, strongText, icon, to }, i) => (
        <MenuItem
          key={i}
          lightText={lightText}
          strongText={strongText}
          icon={icon}
          to={to}
        />
      )
    );
    return <>{menuList}</>;
  };

  return (
    <main className="main">
      <div className="dashboard">
        <h1 className="dashboard__title">What do you want to do?</h1>
        <ul className="dashboard__list">
          <StatisticsItems />
        </ul>
      </div>
    </main>
  );
};
