import toast from "react-hot-toast";

import { CompanyResponse, FloorResponse, CityResponse, CountryResponse, BuildingResponse, EmployeeResponse } from "../api/generated/index";
import { EXPIRED_TOKEN } from "../constants/messageConstants";

export const isDevelopmentEnvironment = () => {
  return process.env.REACT_APP_DEV_ENV !== "true";
};

export const handleToast = (message: string, type?: "success" | "warning" | "error", time?: number) => {
  if (type === "warning") {
    toast(message, {
      duration: time ?? 700,
      icon: "⚠",
      style: {
        fontWeight: "bold",
        background: "#ffeb3b",
      },
    });
    return;
  }

  if (type === "error") {
    toast.error(message, {
      duration: time ?? 1600,
      style: {
        color: "red",
        background: "white",
        fontWeight: "bold",
      },
    });
    return;
  }

  toast.success(message, {
    duration: time ?? 1000,
    style: {
      color: "green",
      background: "white",
      fontWeight: "bold",
    },
  });
};

export const getCompanyId = (name: string, array: CompanyResponse[]): number => {
  return array.find((item) => item.name === name)?.id!;
};

export const getFloorId = (name: string, array: FloorResponse[]): number => {
  return array.find((item) => item.nameForAdmins === name)?.id!;
};

export const getCityId = (name: string, array: CityResponse[]): number => {
  return array.find((item) => item.name === name)?.id!;
};

export const getCountryId = (name: string, array: CountryResponse[]): number => {
  return array.find((item) => item.name === name)?.id!;
};

export const getBuildingId = (name: string, array: BuildingResponse[]): number => {
  return array.find((item) => item.name === name)?.id!;
};

export const getEmployeeMail = (username: string, array: EmployeeResponse[]) => {
  return array.find((item) => item.username === username)?.email;
};

export const handleErrors = (response: any, message: string = "") => {
  if (!response) return;

  if (response.message.includes("status code 401")) {
    localStorage.removeItem("persist:root");
    handleToast(EXPIRED_TOKEN, "error");
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    return;
  }
  if (response.message.includes("status code 409")) {
    handleToast(`Entity ${message} already exists`, "error");
    return;
  }
  handleToast(response.message, "error");
};
