import { createSlice } from "@reduxjs/toolkit";

import { BuildingState } from "../states/BuildingState";
import {
  getBuildings,
  getBuilding,
  postBuilding,
  putBuilding,
  deleteBuilding,
  stopEditing,
  updateBuildingFloor,
  deleteBuildingFloor,
} from "../actions/buildingActions";

const initialState: BuildingState = {
  success: false,
  isLoading: false,
  buildings: [],
  isEditing: false,
  floors: [],
};

const BuildingSlice = createSlice({
  name: "Building",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBuildings.pending, (state) => {
        state.isLoading = true;
        state.success = true;
      })
      .addCase(getBuildings.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.buildings = action.payload;
      })
      .addCase(getBuildings.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getBuilding.pending, (state) => {
        state.isLoading = true;
        state.success = true;
      })
      .addCase(getBuilding.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        if (!state.isEditing) {
          state.floors = [];
          for (let item of action.payload.floorList!) {
            state.floors = [
              ...state.floors!,
              {
                nameForAdmins: item.nameForAdmins,
                nameForUsers: item.nameForUsers,
                level: item.level,
                id: item.id,
              },
            ];
          }
        }
      })
      .addCase(getBuilding.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(postBuilding.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(postBuilding.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(postBuilding.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(deleteBuilding.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(deleteBuilding.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteBuilding.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(putBuilding.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(putBuilding.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
        state.floors = [];
        state.isEditing = false;
      })
      .addCase(putBuilding.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(stopEditing.fulfilled, (state) => {
        state.isEditing = false;
        state.floors = [];
      })
      .addCase(updateBuildingFloor.fulfilled, (state, action) => {
        state.floors = state.floors?.filter((item) => {
          if (item.id === action.payload.id) {
            item.level = action.payload.level;
            item.nameForAdmins = action.payload.nameForAdmins;
            item.nameForUsers = action.payload.nameForUsers;
          }
          return item;
        });
        state.isEditing = true;
      })
      .addCase(deleteBuildingFloor.fulfilled, (state, action) => {
        state.floors = state.floors?.filter(
          (item) => item.id !== action.payload
        );
        state.isEditing = true;
      });
  },
});

export default BuildingSlice.reducer;
