import * as React from "react";

import { ManageWrapper } from "../../layouts/manageLayout";
import { EmptyListPlaceholder } from "../../components/emptyListPlaceholder";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getRooms } from "../../redux/actions/roomActions";
import { Loader } from "../../components/loader/Loader";
import { RoomTable } from "../../pages/room";

export const Room: React.FC = () => {
  const dispatch = useAppDispatch();
  const { rooms, isLoading } = useAppSelector((state) => state.room);

  const handleView = () => {
    if (rooms.length !== 0) {
      return <RoomTable rooms={rooms} />;
    }

    return <EmptyListPlaceholder />;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getRooms());
  }, [dispatch]);

  return (
    <ManageWrapper
      buttonText="Add a new room"
      manageText="a room"
      navigateTo="/room/new"
      isButtonVisible
    >
      {handleLoader()}
      {handleView()}
    </ManageWrapper>
  );
};
