import * as React from "react";

import { useNavigate, useParams } from "react-router-dom";
import { useForm, SubmitHandler, Controller, ControllerRenderProps } from "react-hook-form";
import { Toaster } from "react-hot-toast";

import { ManageWrapper } from "layouts/manageLayout";
import { MainWrapper } from "layouts/mainWrapper/MainWrapper";
import { getCard, getCards, postCard, putCard } from "../../redux/actions/accessControlCardActions";
import { useAppDispatch } from "../../redux/hooks";
import { TextInput } from "ui-components";
import { SUCCESS_POST, SUCCESS_PUT } from "../../constants/messageConstants";
import { handleToast } from "../../utils/helpers";

type Inputs = {
  name: string;
  cardNo: string;
};

export const NewAccessControlCard: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (id !== undefined) {
      dispatch(putCard({ id: Number(id), fullName: data.name }))
        .unwrap()
        .then(() => {
          dispatch(getCards());
          handleToast(SUCCESS_PUT);
          navigate(-1);
        })
        .catch((res) => {
          handleToast(res.message, "error");
        });
      return;
    }
    dispatch(postCard({ fullName: data.name, cardNo: data.cardNo }))
      .unwrap()
      .then(() => {
        dispatch(getCards());
        handleToast(SUCCESS_POST);
        navigate(-1);
      })
      .catch((res) => {
        handleToast(res.message, "error");
      });
  };

  const onCancel = () => {
    navigate(-1);
  };

  const handleChangingCardNo = id !== undefined ? true : false;

  const errorName = errors.name?.type === "required" ? "Name is required" : null;

  const rulesName = { required: false };

  const errorCardNo = errors.cardNo?.type === "required" ? "Token number is required" : null;

  const rulesCardNo = { required: true };

  const renderName = ({ field }: { field: ControllerRenderProps<Inputs, "name"> }) => {
    return <TextInput error={errorName} label="Token name" placeholder="Enter token name..." {...field} />;
  };

  const renderCardNo = ({ field }: { field: ControllerRenderProps<Inputs, "cardNo"> }) => {
    return (
      <TextInput error={errorCardNo} label="Token number" placeholder="Enter token number..." disabled={handleChangingCardNo} {...field} />
    );
  };

  React.useEffect(() => {
    if (id !== undefined) {
      dispatch(getCard(Number(id)))
        .unwrap()
        .then((res) => {
          setValue("name", res?.name!);
          setValue("cardNo", res?.cardNo!);
        });
    }
  }, [dispatch, id, setValue]);

  return (
    <ManageWrapper buttonText="Add new token" manageText="a token" navigateTo="" isButtonVisible={false}>
      <MainWrapper title="Add new token">
        <Toaster position="top-center" reverseOrder={false} />
        <form className="form" onSubmit={handleSubmit(onSubmit)}>
          <div className="form__column">
            <h4>General info</h4>
            <Controller name="name" control={control} rules={rulesName} render={renderName} defaultValue="" />
            <Controller name="cardNo" control={control} rules={rulesCardNo} render={renderCardNo} defaultValue="" />
          </div>
          <div className="form__actions">
            <button type="submit" className="form__btn btn btn--green">
              Save
            </button>
            <button onClick={onCancel} type="button" className="form__btn btn btn--red">
              Cancel
            </button>
          </div>
        </form>
      </MainWrapper>
    </ManageWrapper>
  );
};
