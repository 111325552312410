import * as React from "react";

import { ManageWrapper } from "layouts/manageLayout";
import { EmptyListPlaceholder } from "components/emptyListPlaceholder";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { BuildingTable } from "./index";
import { Loader } from "../../components/loader/Loader";
import { getBuildings } from "../../redux/actions/buildingActions";

export const Building: React.FC = () => {
  const dispatch = useAppDispatch();
  const { buildings, isLoading } = useAppSelector((state) => state.building);
  const handleView = () => {
    if (buildings.length !== 0) {
      return <BuildingTable buildings={buildings} />;
    }

    return <EmptyListPlaceholder />;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getBuildings());
  }, [dispatch]);

  return (
    <ManageWrapper
      buttonText="Add a new building"
      manageText="a building"
      navigateTo="/building/new"
      isButtonVisible
    >
      {handleLoader()}
      {handleView()}
    </ManageWrapper>
  );
};
