import * as React from "react";

import { Toaster } from "react-hot-toast";
import { DownloadTableExcel } from "react-export-table-to-excel";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { EmployeeDetailsWithReservations, WorkstationReservationResponse } from "../../api/generated";
import { getCompanies } from "../../redux/actions/companyActions";
import { getBuildings } from "../../redux/actions/buildingActions";

interface Props {
  employeeDetails: EmployeeDetailsWithReservations[];
}

export const StatisticByEmployeesTable: React.FC<Props> = ({ employeeDetails }) => {
  const dispatch = useAppDispatch();
  const { companies } = useAppSelector((state) => state.company);
  const { buildings } = useAppSelector((state) => state.building);
  const [companySearch, setCompanySearch] = React.useState<string>("");
  const [buildingSearch, setBuildingSearch] = React.useState<string>("");
  const tableRef = React.useRef(null);

  const [searchList, setSearchList] = React.useState<EmployeeDetailsWithReservations[]>([]);

  const handleSearch = (company: string, building: string) => {
    setCompanySearch(company);
    setBuildingSearch(building);

    setSearchList(
      employeeDetails.filter((item) => {
        const matchCompany: Boolean = (item.company ?? "").toLowerCase().includes(company.toLowerCase().trim());
        const matchBuilding: Boolean =
          (item.building ?? "").toLowerCase().includes(building.toLowerCase().trim()) ?? false;
        const matchReservedSpotBuilding = !!item.reservations?.some(
          (r) => r.building?.toLowerCase() === building.toLowerCase().trim(),
        );

        return matchCompany && (matchBuilding || matchReservedSpotBuilding);
      }),
    );
  };

  const mouseEnter = () => {
    document
      .getElementById("btn-export")
      ?.setAttribute("style", "background: white; border-color: #f05a28; height: 100%;");
  };

  const mouseLeave = () => {
    document.getElementById("btn-export")?.setAttribute("style", "background: #f05a28; height: 100%;");
  };

  React.useEffect(() => {
    dispatch(getCompanies());
    dispatch(getBuildings());
    setSearchList(employeeDetails);
  }, [employeeDetails, dispatch]);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="employee-statistics__bar">
        <h2 className="manager__title manager__title--compact">
          <span className="manager__title-text">List of added employees</span>
          <select
            className="manager__title-select"
            value={companySearch}
            onChange={(e) => handleSearch(e.target.value, buildingSearch)}>
            <option value="">All companies</option>
            {companies.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
          <select
            className="manager__title-select"
            value={buildingSearch}
            onChange={(e) => handleSearch(companySearch, e.target.value)}>
            <option value="">All buildings</option>
            {buildings.map((item) => (
              <option key={item.id} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </h2>
        <DownloadTableExcel filename="Employees" sheet="employees" currentTableRef={tableRef.current}>
          <button
            style={{ height: "100%" }}
            id="btn-export"
            type="button"
            onMouseEnter={mouseEnter}
            onMouseLeave={mouseLeave}
            className="btn btn--orange">
            Export
          </button>
        </DownloadTableExcel>
      </div>
      <div>
        <div className="manager__content">
          <table className="table" ref={tableRef}>
            <thead>
              <tr className="table__row">
                <th className="table__head">Employee</th>
                <th className="table__head">Email</th>
                <th className="table__head">Company</th>
                <th className="table__head">Status</th>
                <th className="table__head">Country</th>
                <th className="table__head">City</th>
                <th className="table__head">Building</th>
                <th className="table__head">Floor</th>
                <th className="table__head">Office</th>
              </tr>
            </thead>
            <tbody>
              {searchList.map((item) => {
                return (
                  <tr className="table__row alternate-table-row" key={item.email ?? "-"}>
                    <td className="table__cell">{item.employee ?? "-"}</td>
                    <td className="table__cell">{item.email ?? "-"}</td>
                    <td className="table__cell">{item.company ?? "-"}</td>
                    <td className="table__cell">{item.status ?? "-"}</td>
                    <td className="table__cell">
                      <span>{item.country ?? "-"}</span>
                      {getInfoFromReservation(item.reservations ?? [], "country")}
                    </td>
                    <td className="table__cell">
                      <span>{item.city ?? "-"}</span>
                      {getInfoFromReservation(item.reservations ?? [], "city")}
                    </td>
                    <td className="table__cell">
                      <span>{item.building ?? "-"}</span>
                      {getInfoFromReservation(item.reservations ?? [], "building")}
                    </td>
                    <td className="table__cell">
                      <span>{item.floor ?? "-"}</span>
                      {getInfoFromReservation(item.reservations ?? [], "floor")}
                    </td>
                    <td className="table__cell">
                      <span>{item.office ?? "-"}</span>
                      {getInfoFromReservation(item.reservations ?? [], "office")}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const getInfoFromReservation = (
  reservations: WorkstationReservationResponse[],
  key: keyof WorkstationReservationResponse,
) => {
  return reservations.map((r) => (
    <span>
      <br />
      <br />
      {r[key]}
    </span>
  ));
};
