import React from "react";
import { Link } from "react-router-dom";

interface Props {
  icon: React.SVGProps<SVGSVGElement>;
  lightText: string;
  strongText: string;
  to: string;
}

export const MenuItem: React.FC<Props> = ({
  icon,
  to,
  lightText,
  strongText,
}) => {
  return (
    <li className="dashboard__item">
      <Link to={to} className="dashboard__link">
        {icon}
        <span className="dashboard__text">
          {lightText} <strong>{strongText}</strong>
        </span>
      </Link>
    </li>
  );
};
