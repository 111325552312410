import * as React from "react";

import { useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import moment from "moment";

import { Modal } from "../../components/modal/Modal";
import {
  getCards,
  deleteCard,
} from "../../redux/actions/accessControlCardActions";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { AccessControlTokenResponse } from "../../api/generated";
import { SUCCESS_DELETE } from "../../constants/messageConstants";
import { handleToast } from "../../utils/helpers";
import { getEmployees } from "../../redux/actions/employeeActions";
import { useEffect } from "react";
import { getCompanies } from "../../redux/actions/companyActions";

interface AccessControlCardTableProps {
  cards: AccessControlTokenResponse[];
}

export const AccessControlCardTable: React.FC<AccessControlCardTableProps> = ({
  cards,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentItem = React.useRef<AccessControlTokenResponse | null>(null);
  const [showDeletePopup, setShowDeletePopup] = React.useState<boolean>(false);
  const [searchName, setSearchName] = React.useState<string>("");
  const [searchCardNumber, setSearchCardNumber] = React.useState<string>("");
  const { employees, isLoading } = useAppSelector((state) => state.employee);

  const [searchList, setSearchList] =
    React.useState<AccessControlTokenResponse[]>(cards);

  const handleSearch = (value: string) => {
    setSearchName(value);
    if (value === "") {
      setSearchList(cards);
      return;
    }

    setSearchList(
      cards.filter((item) =>
        item.name?.toLowerCase().includes(value.toLowerCase().trim())
      )
    );
  };

  const handleSearchCardNumber = (value: string) => {
    setSearchCardNumber(value);
    if (value === "") {
      setSearchList(cards);
      return;
    }
    setSearchList(cards.filter((item) => item.cardNo === value));
  };

  const handleOpen = (item: AccessControlTokenResponse) => {
    setShowDeletePopup(true);
    currentItem.current = item;
  };
  const handleClose = () => {
    setShowDeletePopup(false);
  };

  const onDelete = (id: number) => {
    dispatch(deleteCard(id))
      .unwrap()
      .then(() => {
        dispatch(getCards());
        handleToast(SUCCESS_DELETE);
      })
      .catch((res) => {
        handleToast(res.message, "error");
      });
    handleClose();
  };

  const onCancel = () => {
    handleClose();
  };

  const onAdd = (id: number) => {
    navigate(`/token/new/${id}`);
  };

  React.useEffect(() => {
    setSearchList(cards);
  }, [cards]);

  useEffect(() => {
    dispatch(getEmployees());
    dispatch(getCompanies());
  }, [dispatch]);

  const renderTableItems = (items: AccessControlTokenResponse[]) => {
    return items.map((item) => {
      return (
        <tr className="table__row" key={item.id}>
          <td className="table__cell">{item.name}</td>
          <td className="table__cell">{item.code}</td>
          <td className="table__cell">{item.cardNo}</td>
          <td className="table__cell">
            {moment(item.regDate).format("DD.MM.YYYY")}
          </td>
          <td className="table__cell">
            <button onClick={() => onAdd(item.id!)} className="btn btn--green">
              Edit
            </button>
          </td>
          <td className="table__cell">
            <button onClick={() => handleOpen(item)} className="btn btn--red">
              Delete
            </button>
          </td>
        </tr>
      );
    });
  };

  const renderTable = (title: string, items: AccessControlTokenResponse[]) => {
    return (
      <>
        <h2>{title}</h2>
        <table className="table">
          <thead>
            <tr className="table__row">
              <th className="table__head">Token name</th>
              <th className="table__head">Code</th>
              <th className="table__head">Token number</th>
              <th className="table__head">Reg. date</th>
              <th className="table__head table__head--action">Edit</th>
              <th className="table__head table__head--action">Delete</th>
            </tr>
          </thead>
          <tbody>{renderTableItems(items)}</tbody>
        </table>
      </>
    );
  };

  const SearchTable = () => {
    const accessControlNumbers = employees.map((e) => e.accessControlNumber);

    const employeeEntries: AccessControlTokenResponse[] = [];
    const notEmployeeEntries: AccessControlTokenResponse[] = [];

    searchList.forEach((s) => {
      if (accessControlNumbers.includes(s.cardNo)) {
        employeeEntries.push(s);
        return;
      }

      notEmployeeEntries.push(s);
    });

    return (
      <>
        {employeeEntries.length
          ? renderTable("Linked tokens", employeeEntries)
          : null}
        <br />
        {notEmployeeEntries.length
          ? renderTable("Unlinked tokens", notEmployeeEntries)
          : null}
      </>
    );
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <h2 className="manager__title manager__title--compact">
        <span className="manager__title-text">List of added tokens</span>

        <input
          className="manager__title-input"
          type="text"
          placeholder="Search by token name..."
          value={searchName}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <input
          className="manager__title-input"
          type="text"
          placeholder="Search by token number..."
          value={searchCardNumber}
          onChange={(e) => handleSearchCardNumber(e.target.value.trim())}
        />
      </h2>
      <div className="manager__content">
        <SearchTable />
      </div>
      <Modal show={showDeletePopup} handleClose={onCancel}>
        <h2 className="popup__heading-plain">
          Are you sure you want to delete this token?
        </h2>
        <div className="popup__actions">
          <button
            onClick={() => onDelete(currentItem.current?.id!)}
            type="button"
            className="btn btn--green popup__btn"
          >
            Delete
          </button>
          <button
            onClick={onCancel}
            type="button"
            className="btn btn--red popup__btn"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </>
  );
};
