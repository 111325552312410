import * as React from "react";

import { useNavigate } from "react-router-dom";

import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { FloorWithBuildingInfoResponse } from "../../api/generated/api";
import { useAppDispatch } from "../../redux/hooks";
import { deleteBuildingFloor } from "../../redux/actions/buildingActions";

export type FloorListItemProps = {
  item: FloorWithBuildingInfoResponse;
  buildingId: number;
};

export const FloorListItem: React.FC<FloorListItemProps> = ({
  item,
  buildingId,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(deleteBuildingFloor(item.id!));
  };

  const handleNavigate = () => {
    navigate(`/building/${buildingId}/floor/${item.id}`, {
      replace: true,
    });
  };

  return (
    <ListItem
      key={item.id}
      className="floors-order__item"
      onDoubleClick={handleNavigate}
    >
      <ListItemText>Name for users: {item.nameForUsers}</ListItemText>
      <ListItemText>Name for admins: {item.nameForAdmins}</ListItemText>
      <ListItemIcon>
        <button type="button" onClick={handleDelete} className="btn btn--white">
          Delete
        </button>
      </ListItemIcon>
    </ListItem>
  );
};
