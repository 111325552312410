import * as React from "react";

import { EmptyListPlaceholder } from "components/emptyListPlaceholder";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { StatisticByEmployeesTable } from "./index";
import { Loader } from "../../components/loader/Loader";
import { getEmployeesDetails } from "../../redux/actions/employeeActions";

export const StatisticsByEmployees = () => {
  const dispatch = useAppDispatch();
  const { employeeDetails, isLoading } = useAppSelector((state) => state.employeeDetails);

  const handleView = () => {
    if (employeeDetails.length !== 0) {
      return <StatisticByEmployeesTable employeeDetails={employeeDetails} />;
    }

    return <EmptyListPlaceholder />;
  };

  const handleLoader = () => {
    if (isLoading) {
      return <Loader />;
    }

    return null;
  };

  React.useEffect(() => {
    dispatch(getEmployeesDetails());
  }, [dispatch]);

  return (
    <main className="main">
      <div className="wrap">
        <div className="intro">
          <h1 className="intro__title">Statistics by employees</h1>
        </div>
        <div className="manager">
          {handleLoader()}
          {handleView()}
        </div>
      </div>
    </main>
  );
};
