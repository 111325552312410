import { ReplacementTokenResponse } from "api/generated";
import { Modal } from "components/modal/Modal";
import { SUCCESS_POST, SUCCESS_PUT } from "constants/messageConstants";
import React, { useEffect } from "react";
import { Controller, ControllerRenderProps, SubmitHandler, useForm } from "react-hook-form";
import { postReplacementToken, putReplacementToken } from "redux/actions/accessControlCardActions";
import { useAppDispatch } from "redux/hooks";
import { TextInput } from "ui-components";
import { handleErrors, handleToast } from "utils";
import { useToastForInvalidCard } from "./useToastForInvalidCard";

interface Props {
  show: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
  existing?: ReplacementTokenResponse;
  employeeId: number;
}

type Inputs = {
  date: string;
  accessControlNumber: string;
};

export const ReplacementTokenModal: React.FC<Props> = ({ show, handleConfirm, handleClose, existing, employeeId }: Props) => {
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<Inputs>();

  const showToastForInvalidCardIfNeeded = useToastForInvalidCard();

  const resetFields = () => {
    setValue("date", "");
    setValue("accessControlNumber", "");
  };

  const handleOnClose = () => {
    resetFields();
    handleClose();
  };

  useEffect(() => {
    if (!existing) return;

    setValue("date", existing.date ?? "");
    setValue("accessControlNumber", existing.accessControlNumber ?? "");
  }, [existing, setValue]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (existing?.id) {
      try {
        await dispatch(
          putReplacementToken({
            id: existing.id,
            employeeId,
            accessControlNumber: data.accessControlNumber,
            date: data.date,
          })
        ).unwrap();

        handleToast(SUCCESS_PUT, "success", 4000);
        showToastForInvalidCardIfNeeded(data.accessControlNumber);
        resetFields();
        handleConfirm();
      } catch (error) {
        handleErrors(error, "for given date");
      }

      return;
    }

    try {
      await dispatch(
        postReplacementToken({
          employeeId,
          accessControlNumber: data.accessControlNumber,
          date: data.date,
        })
      ).unwrap();

      handleToast(SUCCESS_POST, "success", 4000);
      showToastForInvalidCardIfNeeded(data.accessControlNumber);
      resetFields();
      handleConfirm();
    } catch (error) {
      handleErrors(error, "for given date");
    }
  };

  const errorAcsNumber = errors.accessControlNumber?.type === "required" ? "Access control number is required" : null;
  const errorDate = errors.date?.type === "required" ? "Date is required" : null;

  const renderName = ({ field }: { field: ControllerRenderProps<Inputs, "accessControlNumber"> }) => {
    return (
      <TextInput
        error={errorAcsNumber}
        label="Access control number"
        placeholder="Enter Access control number..."
        style={{ width: "100%" }}
        {...field}
      />
    );
  };

  const renderDate = ({ field }: { field: ControllerRenderProps<Inputs, "date"> }) => {
    return <TextInput error={errorDate} label="Date" placeholder="Enter date..." type="date" {...field} />;
  };

  return (
    <Modal show={show} handleClose={handleOnClose}>
      <h2 className="popup__heading-plain">Input token number and date when that token replacement is valid</h2>

      <div className="form" style={{ gap: 40 }}>
        <div className="form__row--fullwidth" style={{ marginBottom: 20 }}>
          <Controller name="accessControlNumber" control={control} defaultValue="" rules={{ required: true }} render={renderName} />
        </div>
        <div className="form__row--fullwidth" style={{ marginBottom: 40 }}>
          <Controller name="date" control={control} defaultValue={""} rules={{ required: true }} render={renderDate} />
        </div>

        <div className="popup__actions">
          <button onClick={handleSubmit(onSubmit)} type="button" className="btn btn--green popup__btn">
            Save
          </button>
          <button onClick={handleOnClose} type="button" className="btn btn--red popup__btn">
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};
