import { createSlice } from "@reduxjs/toolkit";

import {
    getWorkstationStatistics
} from "../actions/statisticsActions";
import {WorkstationStatisticState} from '../states/WorkstationStatisticState';

const initialState: WorkstationStatisticState = {
    workstationStatistics: [],
    isLoading: false,
    success: false
};

const WorkstationStatisticsSlice = createSlice({
    name: "WorkstationStatistics",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getWorkstationStatistics.pending, (state) => {
                state.isLoading = true;
                state.success = false;
            })
            .addCase(getWorkstationStatistics.fulfilled, (state, action) => {
                state.isLoading = false;
                state.success = true;
                state.workstationStatistics = action.payload;
            })
            .addCase(getWorkstationStatistics.rejected, (state) => {
                state.isLoading = false;
                state.success = false;
            })
    },
});

export default WorkstationStatisticsSlice.reducer;
