import * as React from "react";

import {
  BuildingStatisticBuilding,
  BuildingStatisticRoom,
} from "../../api/generated";
import {
  StatisticByBuildingFormRooms,
  StatisticByBuildingsResult,
} from "./index";

interface StatisticByBuildingFormProps {
  form: BuildingStatisticBuilding;
}
export interface Room {
  item: BuildingStatisticRoom;
  checked: boolean;
}

export interface Floor {
  id: number;
  name: string;
  offices: Room[];
  selectAll: boolean;
  selectNone: boolean;
  confs: Room[];
}

export const StatisticByBuildingForm: React.FC<
  StatisticByBuildingFormProps
> = ({ form }) => {
  const [resultList, setResultList] = React.useState<Floor[]>([]);

  const handleOfficeChange = (floorId: number, officeId: number) => {
    setResultList(
      resultList.filter((item) => {
        if (item.id === floorId) {
          item.offices = item.offices.filter((office) => {
            if (office.item.id === officeId) {
              office.checked = !office.checked;
            }
            return office;
          });
        }
        return item;
      })
    );
  };

  const handleConfChange = (floorId: number, officeId: number) => {
    setResultList(
      resultList.filter((item) => {
        if (item.id === floorId) {
          item.confs = item.confs.filter((conf) => {
            if (conf.item.id === officeId) {
              conf.checked = !conf.checked;
            }
            return conf;
          });
        }
        return item;
      })
    );
  };

  const handleSelectAll = (floorId: number) => {
    setResultList(
      resultList.filter((item) => {
        if (item.id === floorId) {
          item.selectAll = true;
          item.selectNone = false;
          item.offices = item.offices.filter((item) => {
            item.checked = true;
            return item;
          });
          item.confs = item.confs.filter((item) => {
            item.checked = true;
            return item;
          });
        }
        return item;
      })
    );
  };
  const handleSelectNone = (floorId: number) => {
    setResultList(
      resultList.filter((item) => {
        if (item.id === floorId) {
          item.selectAll = false;
          item.selectNone = true;
          item.offices = item.offices.filter((item) => {
            item.checked = false;
            return item;
          });
          item.confs = item.confs.filter((item) => {
            item.checked = false;
            return item;
          });
        }
        return item;
      })
    );
  };

  React.useEffect(() => {
    form.buildingStatisticFloors?.forEach((item) => {
      let offices: Room[] = [];
      item.buildingStatisticOffices?.forEach((item) => {
        offices.push({ item: item, checked: false });
      });
      let confs: Room[] = [];
      item.buildingStatisticConfs?.forEach((item) => {
        confs.push({ item: item, checked: false });
      });
      setResultList((prev) => [
        ...prev,
        {
          id: item.id!,
          name: item.name!,
          selectAll: false,
          selectNone: false,
          offices: offices,
          confs: confs,
        },
      ]);
    });
    return () => {
      setResultList([]);
    };
  }, [form.buildingStatisticFloors]);

  return (
    <>
      <form className="stats-panel js-stats-panel stats-panel--open">
        {resultList?.map((floor) => (
          <fieldset key={floor.id} className="stats-panel__floor">
            <h2 className="stats-panel__floor-title">
              <button
                type="button"
                className="stats-panel__floor-title-btn js-panel-toggle-floor"
              >
                {floor.name}
              </button>
            </h2>
            <div className="stats-panel__content js-panel-content">
              <StatisticByBuildingFormRooms
                offices={floor.offices!}
                confs={floor.confs!}
                floor={floor}
                handleOfficeChange={handleOfficeChange}
                handleConfChange={handleConfChange}
                handleSelectAll={handleSelectAll}
                handleSelectNone={handleSelectNone}
              />
            </div>
          </fieldset>
        ))}
      </form>
      <StatisticByBuildingsResult resultList={resultList} />
    </>
  );
};
