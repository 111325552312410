import { createSlice } from "@reduxjs/toolkit";

import { EmployeeState } from "../states/EmployeeState";
import {
  getEmployees,
  getEmployee,
  postEmployee,
  getRoles,
  putEmployee,
  deleteEmployee,
  assignWorkstationToEmployee,
  syncEmployees,
  enableEmployee,
} from "../actions/employeeActions";

const initialState: EmployeeState = {
  employees: [],
  isLoading: false,
  success: false,
  roles: [],
};

const EmployeeSlice = createSlice({
  name: "Employee",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.employees = action.payload;
      })
      .addCase(getEmployees.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(syncEmployees.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(syncEmployees.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(syncEmployees.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getEmployee.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(getEmployee.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getRoles.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.roles = action.payload;
      })
      .addCase(getRoles.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(postEmployee.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(postEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(postEmployee.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(putEmployee.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(putEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(putEmployee.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(deleteEmployee.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(deleteEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteEmployee.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(assignWorkstationToEmployee.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(assignWorkstationToEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(assignWorkstationToEmployee.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(enableEmployee.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(enableEmployee.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(enableEmployee.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      });
  },
});

export default EmployeeSlice.reducer;
