import React from "react";
import { Modal } from "./Modal";

interface ModalProps {
  show: boolean;
  handleConfirm: () => void;
  handleClose: () => void;
}

const ConfirmationModal: React.FC<ModalProps> = ({ show, handleConfirm, handleClose, children }) => {
  return (
    <Modal show={show} handleClose={handleClose}>
      <h2 className="popup__heading-plain">{children}</h2>
      <div className="popup__actions">
        <button
          onClick={handleConfirm}
          type="button"
          className="btn btn--green popup__btn"
        >
          Yes
        </button>
        <button onClick={handleClose} type="button" className="btn btn--red popup__btn">
          No
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
