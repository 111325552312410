import { createSlice } from "@reduxjs/toolkit";

import { CityState } from "../states/CityState";
import {
  getCities,
  getCity,
  postCity,
  deleteCity,
  putCity,
} from "../actions/cityActions";

const initialState: CityState = {
  success: false,
  isLoading: false,
  cities: [],
};

const CitySlice = createSlice({
  name: "City",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCities.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.cities = action.payload;
      })
      .addCase(getCities.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getCity.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getCity.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(getCity.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(postCity.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(postCity.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(postCity.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(deleteCity.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(deleteCity.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(deleteCity.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(putCity.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(putCity.fulfilled, (state) => {
        state.isLoading = false;
        state.success = true;
      })
      .addCase(putCity.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      });
  },
});

export default CitySlice.reducer;
