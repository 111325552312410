import { Nav } from "../../components/nav";
import { Outlet } from "react-router-dom";

export const NavLayout: React.FC = () => {
  return (
    <>
      <Nav />
      <Outlet />
    </>
  );
};
