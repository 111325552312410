import {
  CompanyControllerApi,
  AuthenticationControllerApi,
  CountryControllerApi,
  CityControllerApi,
  BuildingControllerApi,
  FloorControllerApi,
  EmployeeControllerApi,
  AdminControllerApi,
  RoomControllerApi,
  WorkstationControllerApi,
  StatisticsControllerApi,
  TokensControllerApi,
  ReplacementTokensControllerApi,
} from "./generated/index";
import { backendAxiosInstance } from "../services/services";
import { API_URL_WORKSPACE } from "../constants/appConstants";

export const Api = {
  Company: new CompanyControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Auth: new AuthenticationControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Country: new CountryControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  City: new CityControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Building: new BuildingControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Floor: new FloorControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Employee: new EmployeeControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Admin: new AdminControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Room: new RoomControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  WorkStation: new WorkstationControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Statistics: new StatisticsControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  Tokens: new TokensControllerApi(undefined, API_URL_WORKSPACE, backendAxiosInstance),
  ReplacementTokensControllerApi: new ReplacementTokensControllerApi(
    undefined,
    API_URL_WORKSPACE,
    backendAxiosInstance
  ),
};
