import { createAsyncThunk } from "@reduxjs/toolkit";

import jwtDecode from "jwt-decode";

import { LoginType } from "../../types/LoginType";
import { LoginRequest } from "../../api/generated/index";
import { Decoded } from "../states/AuthState";
import { Api } from "../../api/index";
import {
  AUTHENTIFICATION_FAILED,
  REQUEST_FAILED,
  PERMISSION_DENIED,
} from "../../constants/messageConstants";

export const login = createAsyncThunk(
  "login",
  async (loginType: LoginType): Promise<Decoded | any> => {
    const loginRequest: LoginRequest = {
      username: loginType.emailAddress,
      password: loginType.password,
    };
    return Api.Auth.login(loginRequest)
      .then(({ data }) => {
        let decoded: any = jwtDecode(data);
        const user = {
          username: decoded.username,
          token: data,
          authority: decoded.authorities[0].authority,
          exp: decoded.exp,
        };

        if (!(user.authority === "ADMIN" || user.authority === "SUPER_ADMIN")) {
          throw new Error(PERMISSION_DENIED);
        }
        return user;
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          throw new Error(AUTHENTIFICATION_FAILED);
        }
        if (error.response) {
          throw new Error(REQUEST_FAILED);
        }

        throw new Error(error);
      });
  }
);
