import { createSlice } from "@reduxjs/toolkit";

import { StatisticsState } from "../states/StatisticsState";
import {
  getStatistics,
  getCompaniesStatistics,
  getBuildingStatistics,
  getAccessControlWithoutEventsStatistics,
} from "../actions/statisticsActions";

const initialState: StatisticsState = {
  generalStatistics: {},
  companyStatistics: [],
  buildingStatistics: [],
  isLoading: false,
  success: false,
  tokensWithoutEventsLoadingState: 'NotLoaded',
  tokensWithoutEvents: [],
};

const StatisticsSlice = createSlice({
  name: "Room",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatistics.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getStatistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.generalStatistics = action.payload;
      })
      .addCase(getStatistics.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getCompaniesStatistics.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getCompaniesStatistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.companyStatistics = action.payload;
      })
      .addCase(getCompaniesStatistics.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getBuildingStatistics.pending, (state) => {
        state.isLoading = true;
        state.success = false;
      })
      .addCase(getBuildingStatistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.success = true;
        state.buildingStatistics = action.payload;
      })
      .addCase(getBuildingStatistics.rejected, (state) => {
        state.isLoading = false;
        state.success = false;
      })
      .addCase(getAccessControlWithoutEventsStatistics.pending, (state) => {
        state.tokensWithoutEventsLoadingState = 'Loading'
      })
      .addCase(getAccessControlWithoutEventsStatistics.fulfilled, (state, action) => {
        state.tokensWithoutEventsLoadingState = 'Loaded'
        state.tokensWithoutEvents = action.payload;
      })
      .addCase(getAccessControlWithoutEventsStatistics.rejected, (state) => {
        state.tokensWithoutEventsLoadingState = 'Failed'
      });
  },
});

export default StatisticsSlice.reducer;
